export const authConstant = {
	LOGIN_REQUSET: 'LOGIN_REQUSET',
	LOGIN_SUCCESS: 'LOGIN_SUCCESS',
	LOGIN_FAILED: 'LOGIN_FAILED',
};
export const userConstant = {
	USER_REQUSET: 'USER_REQUSET',
	USER_SUCCESS: 'USER_SUCCESS',
	USER_FAILED: 'USER_FAILED',
};

export const LogoutConstant = 'LOGOUT_SUCCESS';
