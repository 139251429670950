import { useCallback, useState } from 'react';
import { ApiError, OpenAPI } from '../services/utility-core-services';
import getToken from '../utils/token';
import { useDispatch } from 'react-redux';
import { LogoutAction } from 'redux/Action/auth.action';
import { useNavigate } from 'react-router-dom';

export function useAdminCoreUtilityApi() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [error, setError] = useState<ApiError | undefined>(undefined);
	const [isLoading, setIsloading] = useState<boolean>(false);
	OpenAPI.BASE = window.__RUNTIME_CONFIG__.REACT_APP_UTILITY_API_ENDPOINT;
	OpenAPI.TOKEN = getToken();

	const handleRequest = useCallback(async function <T>(request: Promise<T>) {
		setIsloading(true);
		try {
			const response = await request;
			setError(undefined);
			return response;
		} catch (exception: any) {
			setError(exception);
			if (exception?.status === 401) {
				dispatch(LogoutAction());
				navigate('/login');
			}
			return exception;
		} finally {
			setIsloading(false);
		}
	}, []);
	function dismissError() {
		setError(undefined);
	}

	return { dismissError, error, isLoading, handleRequest };
}

export default useAdminCoreUtilityApi;
