/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Amnil_UtilityServiceBackEnd_CmsMenuManagement_Dtos_UtilityServiceCmsMenuLinkTypeDto } from '../models/Amnil_UtilityServiceBackEnd_CmsMenuManagement_Dtos_UtilityServiceCmsMenuLinkTypeDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class CmsMenuLinkTypeService {

    /**
     * @returns Amnil_UtilityServiceBackEnd_CmsMenuManagement_Dtos_UtilityServiceCmsMenuLinkTypeDto Success
     * @throws ApiError
     */
    public static getCmsMenuLinkType(): CancelablePromise<Array<Amnil_UtilityServiceBackEnd_CmsMenuManagement_Dtos_UtilityServiceCmsMenuLinkTypeDto>> {
        return __request({
            method: 'GET',
            path: `/admin-api/api/app/cms-menu-link-type/cms-menu-link-type`,
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

}