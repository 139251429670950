import store from 'redux/Store';

const getToken = () => {
	const tok = store.getState().auth.token;
	if (tok) {
		return tok;
	} else {
		return '';
	}
};
export default getToken;
