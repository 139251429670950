import { saveAs } from 'file-saver';
import dayjs from 'dayjs';
import React from 'react';
import DataTable from 'react-data-table-component';
import { AiOutlineEye } from 'react-icons/ai';

import { Header } from 'components';
import Button from 'components/Button/Button';
import FilterBar from 'components/Table/FilterBar';
import FilterHeader from 'components/Table/FilterHeader';
import useAdminCoreUtilityApi from 'hooks/useAdminCoreUtilityApi';
import { UtilityServiceRequestService } from 'services/utility-core-services';
import { axiosConfig } from 'utils/interceptors';
import { textUpperCase } from 'utils/textUpperCase';
import VisaTransactionDetail from './VisaTransactionDetail';
import Spinner from 'components/Spinner';

const VisaDirectLog: React.FC = () => {
	var curr = new Date();
	curr.setDate(curr.getDate());
	var todayDate = curr.toISOString().substr(0, 10);
	const [visaDirectList, setVisaDirectList] = React.useState<any>([]);
	const [orderListArray, setOrderListArray] = React.useState<any>([]);
	const [filters, setFilters] = React.useState<any>({
		MobileNumber: '',
		FromDate: '',
		ToDate: '',
		OrderStatus: '',
	});
	const [totalRows, setTotalRows] = React.useState(0);
	const [pageSkip, setPageSkip] = React.useState<any>(0);
	const [pageLimit, setPageLimit] = React.useState(10);
	const [isExportLoading, setExportLoading] = React.useState(false);
	const [detailId, setDetailId] = React.useState();
	const [showDetail, setShowDetail] = React.useState(false);

	const { isLoading, handleRequest } = useAdminCoreUtilityApi();

	const columns: any = [
		{
			name: 'S.N.',
			maxWidth: '80px',
			minWidth: '80px',
			selector: (row: any) => row?.sn,
		},
		{
			name: 'Mobile Number',
			selector: (row: any) => row.mobileNumber,
		},
		{
			name: 'Amount',
			selector: (row: any) => row.amount,
		},
		{
			name: 'Order ID',
			selector: (row: any) => textUpperCase(row?.orderId),
		},
		{
			name: 'URL',
			selector: (row: any) => row.url,
		},
		{
			name: 'Session ID',
			selector: (row: any) => row.sessionId,
		},
		{
			name: 'Order Status',
			selector: (row: any) => row?.orderStatus,
		},

		{
			name: ' Request Date',
			selector: (row: any) => dayjs(row.requestNumber).format('MM/DD/YYYY hh:mm:ss'),
		},
		{
			name: ' Response Time ',
			selector: (row: any) => row.nabilResponseDateTime,
		},

		{
			name: 'Actions',
			maxWidth: '6%',
			minWidth: '6%',
			selector: (row: any) => (
				<Button
					type="button"
					title="Show Detail"
					onClick={() => {
						setShowDetail(true);
						setDetailId(row?.orderId);
					}}>
					<AiOutlineEye size={16} />
				</Button>
			),
		},
	];
	// Table data fetch
	const fetchVisaDirectlist = async () => {
		const { MobileNumber, FromDate, ToDate, OrderStatus } = filters;
		let take = pageLimit;
		let skip = pageSkip;
		const response: any = await handleRequest(
			UtilityServiceRequestService.getUtilityServiceRequest15(
				skip,
				take,
				MobileNumber,
				FromDate,
				ToDate,
				OrderStatus
			)
		);
		const calculatedData = response?.items?.map((row: any, index: any) => {
			return {
				...row,
				sn: index + 1 + pageSkip, // Calculate the serial number
			};
		});
		setVisaDirectList(calculatedData);
		setTotalRows(response?.totalCount);
	};

	//export
	const handleExportToExcel = async () => {
		setExportLoading(true);
		const { FromDate, MobileNumber, OrderStatus, ToDate } = filters;
		const { data } = await axiosConfig.get(
			`/admin-api/api/app/utility-service-request/send-visa-direct-to-excel?MobileNumber=${MobileNumber}&FromDate=${FromDate}&ToDate=${ToDate}&OrderStatus=${OrderStatus}`,
			{
				responseType: 'arraybuffer',
			}
		);
		let blob = new Blob([data], {
			type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
		});
		saveAs(blob, `VisaDirectList-${todayDate}-${new Date().toLocaleTimeString()}.xlsx`);
		setExportLoading(false);
	};

	//pagination fn
	const handleOnChangePage = (page: number) => {
		page -= 1;
		setPageSkip(page * pageLimit);
	};

	// Table Row Page change
	const handlePerRowsChange = (newPage: any) => setPageLimit(newPage);
	const paginationRowsPerPage = [10, 25, 50, 100];

	const fetchOrderStatusList = async () => {
		const res = await handleRequest(UtilityServiceRequestService.postUtilityServiceRequest());
		setOrderListArray(res);
	};

	React.useEffect(() => {
		fetchOrderStatusList();
	}, []);

	React.useEffect(() => {
		fetchVisaDirectlist();
	}, [pageLimit, pageSkip]);

	const OrderStatuslist = [
		{
			label: 'All',
			value: '',
		},
		{
			label: 'DECLINED',
			value: 'DECLINED',
		},
		{
			label: 'APPROVED',
			value: 'APPROVED',
		},
		{
			label: 'CANCELED',
			value: 'CANCELED',
		},
	];

	const filtersList = [
		{
			label: 'FromDate',
			defaultOption: filters.FromDate || '',
			inputType: 'date',
			selectedOption: filters.FromDate,
			max: filters.ToDate,
			onChange: (val: string) =>
				setFilters({
					...filters,
					FromDate: val,
				}),
		},

		{
			label: 'ToDate',
			defaultOption: filters.ToDate || '',
			inputType: 'date',
			selectedOption: filters.ToDate,
			min: filters.FromDate,
			onChange: (val: string) =>
				setFilters({
					...filters,
					ToDate: val,
				}),
		},
		{
			label: 'Order Status',
			defaultOption: filters.OrderStatus || 'All',
			inputType: 'select',
			selectedOption: filters.OrderStatus,
			onChange: (val: string) =>
				setFilters({
					...filters,
					OrderStatus: val,
				}),
			filterOptions: OrderStatuslist,
		},
		{
			label: 'Mobile Number',
			defaultOption: filters.MobileNumber || 'All',
			inputType: 'number',
			selectedOption: filters.MobileNumber,
			onChange: (val: string) =>
				setFilters({
					...filters,
					MobileNumber: val,
				}),
		},
		{
			label: '',
			defaultOption: 'All',
			inputType: 'button',
			onFetch: fetchVisaDirectlist,
		},
	];

	return (
		<>
			{!showDetail ? (
				<>
					<Header
						title="Visa Direct Log"
						// extraButton={
						// 	<FilterHeader
						// 		handleExportToExcel={handleExportToExcel}
						// 		isExportLoading={isExportLoading}
						// 	/>
						// }
					/>
					<div className="table__filter">
						<FilterBar
							hideSearchBox
							filters={filtersList}
							hideActionButton
							hideReportActionButton
						/>
					</div>
					<div className="table__container">
						<DataTable
							dense
							columns={columns}
							data={visaDirectList}
							striped
							pagination
							paginationServer
							paginationTotalRows={totalRows}
							onChangePage={handleOnChangePage}
							onChangeRowsPerPage={handlePerRowsChange}
							progressPending={isLoading}
							paginationRowsPerPageOptions={paginationRowsPerPage}
							progressComponent={<Spinner />}
						/>
						{/* <ExportCSV csvData={DataTable.name} fileName={DataTable.name} /> */}
					</div>
				</>
			) : (
				<VisaTransactionDetail id={detailId} close={setShowDetail} />
			)}
		</>
	);
};

export default React.memo(VisaDirectLog);
