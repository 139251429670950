import Button from 'components/Button/Button';
import { ErrorMessage } from 'formik';
import { useState } from 'react';
import { SketchPicker } from 'react-color';
import styled from 'styled-components';
import ColorPicker from 'react-best-gradient-color-picker';

interface Props {
	containerClassName?: string;
	label?: string;
	name: string;
	validate?: any;
	required?: boolean;
	onChange: any;
	className?: any;
	errors: any;
	touched: any;
	labelClassName?: string;
	disabled?: boolean;
	value: string;
	setFieldValue: any;
}

const ColorPickerWrapper = styled.div`
	input {
		border-radius: 5px 0 0 5px;
	}
	.pick-color {
		button {
			border-radius: 0 5px 5px 0;
			height: 38px;
		}
	}
	.close-picker {
		position: absolute;
		border-radius: 50%;
		background: white;
	}
	.picker-wrapper {
		position: absolute;
		z-index: 2;
		background: #fff;
		padding: 15px;
		right: 0;
		top: 20px;
		box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
		border-radius: 11px;
	}
`;

const FormColorPicker = (props: Props) => {
	const {
		containerClassName,
		label,
		name,
		className,
		errors,
		touched,
		labelClassName,
		required,
		value,
		setFieldValue,
	} = props;

	const [displayColorPicker, setDisplayColorPicker] = useState(false);

	const handleClick = () => {
		setDisplayColorPicker(true);
	};

	const handleClose = () => {
		setDisplayColorPicker(false);
	};

	return (
		<ColorPickerWrapper>
			<div className={label ? 'row mb-6' : 'col-lg-6 '}>
				{label && (
					<label htmlFor={name} style={{ width: '100%' }}>
						{label}
						<b className="text-danger position-absolute" style={{ marginLeft: '.2rem' }}>
							*
						</b>
					</label>
				)}
				<div className={containerClassName ?? 'col-lg-6 '}>
					<div className="d-flex align-items-center position-relative">
						<div className="input__wrapper position-relative" style={{ flex: 1 }}>
							<input
								id={name}
								className={`form-control`}
								value={value}
								onChange={(e: any) => setFieldValue(name, e.target.value)}
								disabled
							/>
						</div>
						<div className="pick-color">
							<Button type="button" onClick={handleClick}>
								Pick color
							</Button>
						</div>
						{displayColorPicker ? (
							<>
								<div
									style={{
										position: 'fixed',
										top: '0px',
										right: '0px',
										bottom: '0px',
										left: '0px',
										background: 'fff',
									}}
									onClick={handleClose}
								/>
								<div className="picker-wrapper">
									<div className="position-relative d-flex justify-content-end me-1 mb-1">
										<Button type="button" onClick={handleClose}>
											Close
										</Button>
									</div>
									<ColorPicker
										value={value ?? 'rgba(255,255,255,1)'}
										onChange={(e: any) => {
											setFieldValue(name, e);
										}}
										hideOpacity={true}
										hideAdvancedSliders={true}
										hideColorGuide={true}
										hideInputType={true}
										hidePresets={true}
										hideEyeDrop={true}
										hideColorTypeBtns={label === 'Primary button color' ? false : true}
										width={200}
										height={150}
									/>
									{/* <SketchPicker
									color={value}
									onChange={(color) => {
										setFieldValue(name, color?.hex);
									}}
									onChangeComplete={() => {
										setTimeout(() => {
											handleClose();
										}, 500);
									}}
									disableAlpha={true}
								/> */}
								</div>
							</>
						) : null}
					</div>
					<div className="fv-plugins-message-container text-danger fw-bolder small text-end mt-1">
						<ErrorMessage name={name} component="div" className="field-error-message" />
					</div>
				</div>
			</div>
		</ColorPickerWrapper>
	);
};

export default FormColorPicker;
