/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Amnil_UtilityServiceBackEnd_RoleManagement_UserDto } from '../models/Amnil_UtilityServiceBackEnd_RoleManagement_UserDto';
import type { Amnil_UtilityServiceBackEnd_UserManagement_RoleList } from '../models/Amnil_UtilityServiceBackEnd_UserManagement_RoleList';
import type { Amnil_UtilityServiceBackEnd_UserManagement_UserCreateBackendDto } from '../models/Amnil_UtilityServiceBackEnd_UserManagement_UserCreateBackendDto';
import type { Volo_Abp_Application_Dtos_ListResultDto_1 } from '../models/Volo_Abp_Application_Dtos_ListResultDto_1';
import type { Volo_Abp_Application_Dtos_PagedResultDto_1 } from '../models/Volo_Abp_Application_Dtos_PagedResultDto_1';
import type { Volo_Abp_Identity_IdentityUserDto } from '../models/Volo_Abp_Identity_IdentityUserDto';
import type { Volo_Abp_Identity_IdentityUserUpdateDto } from '../models/Volo_Abp_Identity_IdentityUserUpdateDto';
import type { Volo_Abp_Identity_IdentityUserUpdateRolesDto } from '../models/Volo_Abp_Identity_IdentityUserUpdateRolesDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class IdentityUserService {

    /**
     * @param requestBody
     * @returns Volo_Abp_Identity_IdentityUserDto Success
     * @throws ApiError
     */
    public static postIdentityUser(
        requestBody?: Amnil_UtilityServiceBackEnd_UserManagement_UserCreateBackendDto,
    ): CancelablePromise<Volo_Abp_Identity_IdentityUserDto> {
        return __request({
            method: 'POST',
            path: `/admin-api/api/app/identity-user`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param filter
     * @param sorting
     * @param skipCount
     * @param maxResultCount
     * @returns Volo_Abp_Application_Dtos_PagedResultDto_1<Amnil_UtilityServiceBackEnd_UserManagement_UserListDto_Amnil_UtilityServiceBackEnd_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null_> Success
     * @throws ApiError
     */
    public static getIdentityUser(
        skipCount?: number,
        maxResultCount?: number,
        filter?: string,
        sorting?: string,
    ): CancelablePromise<Volo_Abp_Application_Dtos_PagedResultDto_1> {
        return __request({
            method: 'GET',
            path: `/admin-api/api/app/identity-user`,
            query: {
                'Filter': filter,
                'Sorting': sorting,
                'SkipCount': skipCount,
                'MaxResultCount': maxResultCount,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param userId
     * @param requestBody
     * @returns boolean Success
     * @throws ApiError
     */
    public static postIdentityUser1(
        userId: string,
        requestBody?: Array<string>,
    ): CancelablePromise<boolean> {
        return __request({
            method: 'POST',
            path: `/admin-api/api/app/identity-user/permissions/${userId}`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param userId
     * @param requestBody
     * @returns boolean Success
     * @throws ApiError
     */
    public static putIdentityUser(
        userId: string,
        requestBody?: Array<string>,
    ): CancelablePromise<boolean> {
        return __request({
            method: 'PUT',
            path: `/admin-api/api/app/identity-user/granted-permissions/${userId}`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param id
     * @returns Amnil_UtilityServiceBackEnd_RoleManagement_UserDto Success
     * @throws ApiError
     */
    public static getIdentityUser1(
        id: string,
    ): CancelablePromise<Amnil_UtilityServiceBackEnd_RoleManagement_UserDto> {
        return __request({
            method: 'GET',
            path: `/admin-api/api/app/identity-user/${id}/user-permission`,
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param skip
     * @param take
     * @param searchValue
     * @returns Volo_Abp_Application_Dtos_PagedResultDto_1<Amnil_UtilityServiceBackEnd_RoleManagement_UserDto_Amnil_UtilityServiceBackEnd_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null_> Success
     * @throws ApiError
     */
    public static getIdentityUser2(
        skip?: number,
        take?: number,
        searchValue?: string,
    ): CancelablePromise<Volo_Abp_Application_Dtos_PagedResultDto_1> {
        return __request({
            method: 'GET',
            path: `/admin-api/api/app/identity-user/roles`,
            query: {
                'Skip': skip,
                'Take': take,
                'SearchValue': searchValue,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param userId
     * @param userStatus
     * @returns boolean Success
     * @throws ApiError
     */
    public static postIdentityUser2(
        userId: string,
        userStatus?: boolean,
    ): CancelablePromise<boolean> {
        return __request({
            method: 'POST',
            path: `/admin-api/api/app/identity-user/disable-user/${userId}`,
            query: {
                'UserStatus': userStatus,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param id
     * @returns any Success
     * @throws ApiError
     */
    public static deleteIdentityUser(
        id: string,
    ): CancelablePromise<any> {
        return __request({
            method: 'DELETE',
            path: `/admin-api/api/app/identity-user/${id}`,
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param id
     * @returns Volo_Abp_Identity_IdentityUserDto Success
     * @throws ApiError
     */
    public static getIdentityUser3(
        id: string,
    ): CancelablePromise<Volo_Abp_Identity_IdentityUserDto> {
        return __request({
            method: 'GET',
            path: `/admin-api/api/app/identity-user/${id}`,
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param id
     * @param requestBody
     * @returns Volo_Abp_Identity_IdentityUserDto Success
     * @throws ApiError
     */
    public static putIdentityUser1(
        id: string,
        requestBody?: Volo_Abp_Identity_IdentityUserUpdateDto,
    ): CancelablePromise<Volo_Abp_Identity_IdentityUserDto> {
        return __request({
            method: 'PUT',
            path: `/admin-api/api/app/identity-user/${id}`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param email
     * @returns Volo_Abp_Identity_IdentityUserDto Success
     * @throws ApiError
     */
    public static postIdentityUser3(
        email?: string,
    ): CancelablePromise<Volo_Abp_Identity_IdentityUserDto> {
        return __request({
            method: 'POST',
            path: `/admin-api/api/app/identity-user/find-by-email`,
            query: {
                'email': email,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param userName
     * @returns Volo_Abp_Identity_IdentityUserDto Success
     * @throws ApiError
     */
    public static postIdentityUser4(
        userName?: string,
    ): CancelablePromise<Volo_Abp_Identity_IdentityUserDto> {
        return __request({
            method: 'POST',
            path: `/admin-api/api/app/identity-user/find-by-username`,
            query: {
                'userName': userName,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Volo_Abp_Application_Dtos_ListResultDto_1<Volo_Abp_Identity_IdentityRoleDto_Volo_Abp_Identity_Application_Contracts_Version_4_4_4_0_Culture_neutral_PublicKeyToken_null_> Success
     * @throws ApiError
     */
    public static getIdentityUser4(): CancelablePromise<Volo_Abp_Application_Dtos_ListResultDto_1> {
        return __request({
            method: 'GET',
            path: `/admin-api/api/app/identity-user/assignable-roles`,
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param id
     * @returns Volo_Abp_Application_Dtos_ListResultDto_1<Volo_Abp_Identity_IdentityRoleDto_Volo_Abp_Identity_Application_Contracts_Version_4_4_4_0_Culture_neutral_PublicKeyToken_null_> Success
     * @throws ApiError
     */
    public static getIdentityUser5(
        id: string,
    ): CancelablePromise<Volo_Abp_Application_Dtos_ListResultDto_1> {
        return __request({
            method: 'GET',
            path: `/admin-api/api/app/identity-user/${id}/roles`,
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param id
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public static putIdentityUser2(
        id: string,
        requestBody?: Volo_Abp_Identity_IdentityUserUpdateRolesDto,
    ): CancelablePromise<any> {
        return __request({
            method: 'PUT',
            path: `/admin-api/api/app/identity-user/${id}/roles`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param email
     * @param oldPassword
     * @param newPassword
     * @param confirmPassword
     * @returns string Success
     * @throws ApiError
     */
    public static postIdentityUser5(
        email?: string,
        oldPassword?: string,
        newPassword?: string,
        confirmPassword?: string,
    ): CancelablePromise<string> {
        return __request({
            method: 'POST',
            path: `/admin-api/api/app/identity-user/change-password`,
            query: {
                'Email': email,
                'OldPassword': oldPassword,
                'NewPassword': newPassword,
                'ConfirmPassword': confirmPassword,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param id
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public static putIdentityUser3(
        id: string,
        requestBody?: Array<Amnil_UtilityServiceBackEnd_UserManagement_RoleList>,
    ): CancelablePromise<any> {
        return __request({
            method: 'PUT',
            path: `/admin-api/api/app/identity-user/${id}/bulk-roles`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param userId
     * @returns string Success
     * @throws ApiError
     */
    public static getIdentityUser6(
        userId: string,
    ): CancelablePromise<string> {
        return __request({
            method: 'GET',
            path: `/admin-api/api/app/identity-user/login-type/${userId}`,
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

}