/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Amnil_UtilityService_CmsSettings_Dtos_CmsSettingGroupDto } from '../models/Amnil_UtilityService_CmsSettings_Dtos_CmsSettingGroupDto';
import type { Amnil_UtilityService_CmsSettings_Dtos_CmsSettingsDto } from '../models/Amnil_UtilityService_CmsSettings_Dtos_CmsSettingsDto';
import type { Amnil_UtilityService_CmsSettings_Dtos_ResponseDto } from '../models/Amnil_UtilityService_CmsSettings_Dtos_ResponseDto';
import type { Amnil_UtilityServiceBackEnd_CmsSettings_Dtos_CmsSettingsUpdateResponseDto } from '../models/Amnil_UtilityServiceBackEnd_CmsSettings_Dtos_CmsSettingsUpdateResponseDto';
import type { Amnil_UtilityServiceBackEnd_CmsSettings_Dtos_CmsSettingTypesDto } from '../models/Amnil_UtilityServiceBackEnd_CmsSettings_Dtos_CmsSettingTypesDto';
import type { Amnil_UtilityServiceBackEnd_CmsSettings_Dtos_FrontUiCmsSettingsDto } from '../models/Amnil_UtilityServiceBackEnd_CmsSettings_Dtos_FrontUiCmsSettingsDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class CmsSettingsService {

    /**
     * @returns Amnil_UtilityService_CmsSettings_Dtos_CmsSettingGroupDto Success
     * @throws ApiError
     */
    public static getCmsSettings(): CancelablePromise<Array<Amnil_UtilityService_CmsSettings_Dtos_CmsSettingGroupDto>> {
        return __request({
            method: 'GET',
            path: `/admin-api/api/app/cms-settings/cms-setting-groups`,
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Amnil_UtilityServiceBackEnd_CmsSettings_Dtos_CmsSettingTypesDto Success
     * @throws ApiError
     */
    public static getCmsSettings1(): CancelablePromise<Array<Amnil_UtilityServiceBackEnd_CmsSettings_Dtos_CmsSettingTypesDto>> {
        return __request({
            method: 'GET',
            path: `/admin-api/api/app/cms-settings/cms-setting-types`,
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param cmsSettingTypeName 
     * @returns Amnil_UtilityServiceBackEnd_CmsSettings_Dtos_FrontUiCmsSettingsDto Success
     * @throws ApiError
     */
    public static getCmsSettings2(
cmsSettingTypeName?: string,
): CancelablePromise<Array<Amnil_UtilityServiceBackEnd_CmsSettings_Dtos_FrontUiCmsSettingsDto>> {
        return __request({
            method: 'GET',
            path: `/admin-api/api/app/cms-settings/settings-by-type`,
            query: {
                'cmsSettingTypeName': cmsSettingTypeName,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Amnil_UtilityService_CmsSettings_Dtos_CmsSettingsDto Success
     * @throws ApiError
     */
    public static getCmsSettings3(): CancelablePromise<Array<Amnil_UtilityService_CmsSettings_Dtos_CmsSettingsDto>> {
        return __request({
            method: 'GET',
            path: `/admin-api/api/app/cms-settings/cms-settings`,
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param formData 
     * @returns Amnil_UtilityServiceBackEnd_CmsSettings_Dtos_CmsSettingsUpdateResponseDto Success
     * @throws ApiError
     */
    public static putCmsSettings(
formData?: {
PrimaryColor?: string;
SecondaryColor?: string;
BackgroundColor?: string;
PrimaryButtonColor?: string;
MainBackgroundColor?: string;
FooterTextColor?: string;
FooterTitleTextColor?: string;
ButtonTextColor?: string;
HeaderBackgroundColor?: string;
HeaderTextColor?: string;
Favicon?: Blob;
LoadingScreenLogo?: Blob;
PrimaryLogo?: Blob;
SecondaryLogo?: Blob;
FooterLogo?: Blob;
FooterQrImage?: Blob;
SitemapUrl?: string;
PrivacyStatement?: string;
FacebookUrl?: string;
TwitterUrl?: string;
LinkedInUrl?: string;
InstagramUrl?: string;
ViberUrl?: string;
YoutubeUrl?: string;
PinterestUrl?: string;
WhatsappUrl?: string;
MobileAppQrImage?: Blob;
GooglePlaystoreUrl?: string;
AppstoreUrl?: string;
CustomerCareEmail?: string;
CustomerCarePhone?: string;
BankSwiftCode?: string;
CopyrightText?: string;
SidebarTextColor?: string;
SidebarBackgroundColor?: string;
SidebarLogo?: Blob;
ApplicationName?: string;
},
): CancelablePromise<Array<Amnil_UtilityServiceBackEnd_CmsSettings_Dtos_CmsSettingsUpdateResponseDto>> {
        return __request({
            method: 'PUT',
            path: `/admin-api/api/app/cms-settings/cms-settings`,
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param key 
     * @param value 
     * @returns Amnil_UtilityService_CmsSettings_Dtos_ResponseDto Success
     * @throws ApiError
     */
    public static postCmsSettings(
key?: string,
value?: string,
): CancelablePromise<Amnil_UtilityService_CmsSettings_Dtos_ResponseDto> {
        return __request({
            method: 'POST',
            path: `/admin-api/api/app/cms-settings/settings`,
            query: {
                'key': key,
                'value': value,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

}