import Button from 'components/Button/Button';
import React, { memo } from 'react';
import { useNavigate } from 'react-router-dom';
import { HiArrowSmLeft } from 'react-icons/hi';

interface Iprops {
	title: string;
	btnText?: string;
	onClick?: () => void;
	btnIcon?: any;
	extraButton?: any;
	pageBack?: any;
}

const Header: React.FC<Iprops> = ({ title, btnText, onClick, btnIcon, extraButton, pageBack }) => {
	return (
		<div className="d-flex justify-content-between align-items-center p-4">
			<div className="d-flex gap-2 align-items-center">
				{pageBack && (
					<div className="d-inline-flex align-items-center " onClick={pageBack}>
						<HiArrowSmLeft size={24} />
					</div>
				)}

				<h2 className="fw-bold">{title}</h2>
			</div>
			<div>
				{btnText && (
					<Button onClick={onClick} type="button">
						{btnIcon && <span className="me-2"> {btnIcon}</span>}
						{btnText}
					</Button>
				)}
				{extraButton ? extraButton : null}
			</div>
		</div>
	);
};

export default memo(Header);
