/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Amnil_UtilityServiceBackEnd_RoleManagement_RoleCreateDto } from '../models/Amnil_UtilityServiceBackEnd_RoleManagement_RoleCreateDto';
import type { Amnil_UtilityServiceBackEnd_RoleManagement_RoleDto } from '../models/Amnil_UtilityServiceBackEnd_RoleManagement_RoleDto';
import type { Amnil_UtilityServiceBackEnd_RoleManagement_UpdateRoleDto } from '../models/Amnil_UtilityServiceBackEnd_RoleManagement_UpdateRoleDto';
import type { Volo_Abp_Application_Dtos_PagedResultDto_1 } from '../models/Volo_Abp_Application_Dtos_PagedResultDto_1';
import type { Volo_Abp_Identity_IdentityRoleDto } from '../models/Volo_Abp_Identity_IdentityRoleDto';
import type { Volo_Abp_PermissionManagement_PermissionGrant } from '../models/Volo_Abp_PermissionManagement_PermissionGrant';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class IdentityRoleService {

    /**
     * @param requestBody
     * @returns Volo_Abp_Identity_IdentityRoleDto Success
     * @throws ApiError
     */
    public static postIdentityRole(
        requestBody?: Amnil_UtilityServiceBackEnd_RoleManagement_RoleCreateDto,
    ): CancelablePromise<Volo_Abp_Identity_IdentityRoleDto> {
        return __request({
            method: 'POST',
            path: `/admin-api/api/app/identity-role`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param filter
     * @param sorting
     * @param skipCount
     * @param maxResultCount
     * @returns Volo_Abp_Application_Dtos_PagedResultDto_1<Volo_Abp_Identity_IdentityRoleDto_Volo_Abp_Identity_Application_Contracts_Version_4_4_4_0_Culture_neutral_PublicKeyToken_null_> Success
     * @throws ApiError
     */
    public static getIdentityRole(
        filter?: string,
        sorting?: string,
        skipCount?: number,
        maxResultCount?: number,
    ): CancelablePromise<Volo_Abp_Application_Dtos_PagedResultDto_1> {
        return __request({
            method: 'GET',
            path: `/admin-api/api/app/identity-role`,
            query: {
                'Filter': filter,
                'Sorting': sorting,
                'SkipCount': skipCount,
                'MaxResultCount': maxResultCount,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param id
     * @returns any Success
     * @throws ApiError
     */
    public static deleteIdentityRole(
        id: string,
    ): CancelablePromise<any> {
        return __request({
            method: 'DELETE',
            path: `/admin-api/api/app/identity-role/${id}`,
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param id
     * @returns Volo_Abp_Identity_IdentityRoleDto Success
     * @throws ApiError
     */
    public static getIdentityRole1(
        id: string,
    ): CancelablePromise<Volo_Abp_Identity_IdentityRoleDto> {
        return __request({
            method: 'GET',
            path: `/admin-api/api/app/identity-role/${id}`,
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param id
     * @param requestBody
     * @returns Volo_Abp_Identity_IdentityRoleDto Success
     * @throws ApiError
     */
    public static putIdentityRole(
        id: string,
        requestBody?: Amnil_UtilityServiceBackEnd_RoleManagement_UpdateRoleDto,
    ): CancelablePromise<Volo_Abp_Identity_IdentityRoleDto> {
        return __request({
            method: 'PUT',
            path: `/admin-api/api/app/identity-role/${id}`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param skip
     * @param take
     * @param searchValue
     * @returns Volo_Abp_Application_Dtos_PagedResultDto_1<Amnil_UtilityServiceBackEnd_RoleManagement_RoleDto_Amnil_UtilityServiceBackEnd_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null_> Success
     * @throws ApiError
     */
    public static getIdentityRole2(
        skip?: number,
        take?: number,
    ): CancelablePromise<Volo_Abp_Application_Dtos_PagedResultDto_1> {
        return __request({
            method: 'GET',
            path: `/admin-api/api/app/identity-role/roles`,
            query: {
                'Skip': skip,
                'Take': take,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param id
     * @returns Amnil_UtilityServiceBackEnd_RoleManagement_RoleDto Success
     * @throws ApiError
     */
    public static getIdentityRole3(
        id: string,
    ): CancelablePromise<Amnil_UtilityServiceBackEnd_RoleManagement_RoleDto> {
        return __request({
            method: 'GET',
            path: `/admin-api/api/app/identity-role/${id}/role`,
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Volo_Abp_PermissionManagement_PermissionGrant Success
     * @throws ApiError
     */
    public static getIdentityRole4(): CancelablePromise<Array<Volo_Abp_PermissionManagement_PermissionGrant>> {
        return __request({
            method: 'GET',
            path: `/admin-api/api/app/identity-role/permissions`,
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param rolename
     * @param requestBody
     * @returns boolean Success
     * @throws ApiError
     */
    public static postIdentityRole1(
        rolename?: string,
        requestBody?: Array<string>,
    ): CancelablePromise<boolean> {
        return __request({
            method: 'POST',
            path: `/admin-api/api/app/identity-role/permissions`,
            query: {
                'rolename': rolename,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns any Success
     * @throws ApiError
     */
    public static postIdentityRole2(): CancelablePromise<any> {
        return __request({
            method: 'POST',
            path: `/admin-api/api/app/identity-role/flush-db`,
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

}