import { Form, Formik } from 'formik';
import { memo } from 'react';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';

import { Header, TextField } from 'components';
import Button from 'components/Button/Button';
import useAdminCoreUtilityApi from 'hooks/useAdminCoreUtilityApi';
import { IdentityUserService } from 'services/utility-core-services';
import { Security } from '../../assets';

const FORM_VALIDATION = Yup.object().shape({
	email: Yup.string().required('This field is required').email('The email is not valid'),
	OldPassword: Yup.string()
		.matches(
			/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/,
			'Password must be at least one alpha numeric character, 8 character long, at least one numeric.'
		)
		.required('This field is required'),
	NewPassword: Yup.string()
		.matches(
			/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,16}$/,
			'Password must be at least one alpha numeric character, 8 character long, at least one numeric.'
		)
		.required('This field is required'),
	ConfirmPassword: Yup.string()
		.oneOf([Yup.ref('NewPassword'), null], 'Passwords must match')
		.required('This field is required'),
});

const ChangePassword = () => {
	const { handleRequest } = useAdminCoreUtilityApi();
	const { email }: any = useSelector((state: any) => state.auth.userData || '');
	const theme = useSelector((state: any) => state.theme.theming);

	const postChangePassword = async (val: any, action: any) => {
		const { email, OldPassword, NewPassword, ConfirmPassword } = val;
		await handleRequest(
			IdentityUserService.postIdentityUser5(email, OldPassword, NewPassword, ConfirmPassword)
		);
		action.resetForm();
	};
	return (
		<div>
			<Header title="Change Password" />
			<div className="col-lg-6 mx-auto pt-3">
				<div className="mb-5 d-flex gap-4">
					<div
						style={{ width: '70px', height: '70px', background: theme.NavigationBackgroundColor }}>
						<img src={Security} alt="security" style={{ width: '70px' }} />
					</div>
					<div>
						<h5 className="mb-2">Password must contain</h5>
						<ul className="list-unstyled">
							<li>at least one alpha numeric character, 8 character long</li>
							<li>at least one numeric</li>
						</ul>
					</div>
				</div>
				<Formik
					initialValues={{
						email: email || '',
						OldPassword: '',
						NewPassword: '',
						ConfirmPassword: '',
					}}
					enableReinitialize={true}
					validationSchema={FORM_VALIDATION}
					validateOnMount={true}
					onSubmit={postChangePassword}>
					{({ isSubmitting, values, handleBlur, handleChange, isValid }) => (
						<Form>
							<TextField
								type="text"
								label="Email"
								name="email"
								inline
								onChange={handleChange}
								onBlur={handleBlur}
								readOnly
							/>
							<TextField
								label="Old Password"
								type="password"
								name="OldPassword"
								inline
								onChange={handleChange}
								onBlur={handleBlur}
							/>
							<TextField
								label="New Password"
								type="password"
								name="NewPassword"
								inline
								onChange={handleChange}
								onBlur={handleBlur}
							/>
							<TextField
								label="Confirm Password"
								type="password"
								name="ConfirmPassword"
								inline
								value={values.ConfirmPassword}
								onChange={handleChange}
								onBlur={handleBlur}
							/>
							<div className="float-end">
								<Button type="submit">Submit</Button>
							</div>
						</Form>
					)}
				</Formik>
			</div>
		</div>
	);
};

export default memo(ChangePassword);
