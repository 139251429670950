import React, { useState } from 'react';
import { ErrorMessage, FieldHookConfig, useField } from 'formik';
import { RiErrorWarningFill } from 'react-icons/ri';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import { useSelector } from 'react-redux';
interface IProps {
	label: string;
	placeholder?: string;
	type?: string;
	defaultValue?: string | number;
	readOnly?: boolean;
	onClick?: any;
	inline?: boolean;
}
const TextField = ({
	label,
	placeholder,
	type,
	readOnly,
	defaultValue,
	inline,
	onClick,
	...otherProps
}: IProps & FieldHookConfig<string>) => {
	const [field, meta] = useField(otherProps);
	const [passwordShown, setPasswordShown] = useState<boolean>(false);
	const theme = useSelector((state: any) => state.theme.theming);
	return (
		<div
			className={`input__text__feild__gap form-group mb-1 ${
				inline ? 'd-flex align-items-center' : ''
			}`}>
			<label
				style={{ width: inline ? '40%' : '100%' }}
				className={`input__label form-label`}
				htmlFor={field.name}>
				{label}{' '}
				<b className="text-danger position-absolute" style={{ marginLeft: '.2rem' }}>
					*
				</b>
			</label>
			<div style={{ flex: 1 }}>
				<div
					className={`${
						type === 'checkbox' ? 'form-check' : 'form-group'
					} mb-1 input__wrapper position-relative`}>
					{type === 'textarea' ? (
						<textarea className="form-control"></textarea>
					) : (
						<input
							// className={`form-control  ${meta.touched && meta.error ? 'is-invalid' : ''} ${
							// 	!meta.error && field.name != 'password' ? 'is-valid' : ''
							// }`}
							className={type === 'checkbox' ? 'form-check-input' : 'form-control'}
							{...field}
							type={type}
							placeholder={placeholder}
							defaultValue={defaultValue}
							autoComplete="off"
							readOnly={readOnly}
						/>
					)}

					{type === 'checkbox' && (
						<label className="form-check-label" htmlFor="flexCheckDefault">
							{label}
						</label>
					)}

					{field.name === 'password' && (
						<div
							onClick={() => {
								onClick();
								setPasswordShown(!passwordShown);
							}}
							className={`position-absolute eye_icon end-0 mx-3 mb-1 cursor-pointer text-muted top-15`}>
							{' '}
							{passwordShown ? (
								<AiOutlineEye size={20} color={theme.PrimaryColor} />
							) : (
								<AiOutlineEyeInvisible size={20} color={theme.PrimaryColor} />
							)}
						</div>
					)}
				</div>
				{meta.touched && meta.error ? (
					<div className="input__error__icon">
						<RiErrorWarningFill />
					</div>
				) : (
					''
				)}
				<ErrorMessage component="div" name={field.name} className="input__error__container mb-3" />
			</div>
		</div>
	);
};

export default React.memo(TextField);
