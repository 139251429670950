import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

const SpinnerWrapper = styled.div`
display:flex;
align-items-center;
justify-content:center;
color:${(props) => props.color}
`;

export default function Spinner() {
	const theme = useSelector((state: any) => state.theme.theming);
	return (
		<SpinnerWrapper
			className="spinner-border"
			role="status"
			color={theme.PrimaryColor}></SpinnerWrapper>
	);
}
