import { CustomSelect, TextField } from 'components';
import { Field, Form, Formik } from 'formik';
import { Modal } from 'react-bootstrap';
import Toast from 'utils/toast';
import React from 'react';
import * as Yup from 'yup';

import useAdminCoreUtilityApi from 'hooks/useAdminCoreUtilityApi';
import { IdentityUserService } from 'services/utility-core-services';
import Button from 'components/Button/Button';
import { RiErrorWarningFill } from 'react-icons/ri';

type Props = {
	userData: any;
	roleData: any;
	loginTypes: any;
	isOpen: boolean;
	onClose: () => void;
	fetchUsers: any;
};

interface UserType {
	email: string;
	firstname: string;
	lastname: string;
	number: string;
	role: any[];
	username: string;
}
interface UserTypeAdd extends UserType {
	password: string;
	loginType: string;
}

const FORM_VALIDATION = Yup.object().shape({
	username: Yup.string()
		.min(4, 'username must be at least 4 character long')
		.required('This field is required'),
	firstname: Yup.string()
		.min(3, 'First Name must be at least 3 character long')
		.required('This field is required'),
	lastname: Yup.string()
		.min(3, 'Last Name must be at least 3 character long')
		.required('This field is required'),
	email: Yup.string().required('This field is required').email('The email is not valid'),
	role: Yup.array().min(1, 'This field is required').required('This field is required'),
	number: Yup.string()
		.max(10, 'Phone number cannot exceed 10 characters')
		.matches(/^9\d{9}$/, 'Phone number is not valid')
		.required('This field is required'),
	loginType: Yup.string().min(1, 'This field is required').required('This field is required'),
});

export default function AddEditUser({
	userData,
	roleData,
	loginTypes,
	isOpen,
	onClose,
	fetchUsers,
}: Props) {
	const [roleList, setRoleList] = React.useState<any[]>([]);
	const { handleRequest: handleFetchRoles } = useAdminCoreUtilityApi();

	const filterValue = roleList?.map((e: { name: string; id: string }) => {
		return {
			label: e.name,
			value: e.id,
		};
	});
	const loginTypeArray = [
		{ label: 'AD', value: 'AD' },
		{ label: 'System', value: 'System' },
	];

	//retrive roles
	React.useEffect(() => {
		handleFetchRoles(IdentityUserService.getIdentityUser4()).then((response: any) => {
			setRoleList(response.items);
		});
	}, []);

	//fn to add user
	const handleAdd = async (values: UserTypeAdd, setSubmitting: any) => {
		const finalData = {
			userInfo: {
				userName: values.username,
				name: values.firstname,
				surname: values.lastname,
				email: values.email,
				phoneNumber: values.number,
				roleNames: values.role,
				password: values.password,
			},
			loginType: values.loginType,
		};
		IdentityUserService.postIdentityUser(finalData)
			.then((res) => {
				Toast('success', 'User Added Successfully');
				setSubmitting(false);
				onClose();
				fetchUsers();
			})
			.catch((err) => {
				Toast('error', err?.body?.error?.message ?? 'Username or Email is already taken ');
				setSubmitting(false);
			});
	};

	//fn to edit user
	const handleEdit = async (values: UserType, setSubmitting: any) => {
		const { concurrencyStamp, id } = userData;
		const payload: any = {
			concurrencyStamp: concurrencyStamp,
			userName: values.username,
			name: values.firstname,
			surname: values.lastname,
			email: values.email,
			phoneNumber: values.number,
			roleNames: values.role,
		};
		IdentityUserService.putIdentityUser1(id, payload)
			.then((res) => {
				Toast('success', 'User Edit Successfully');
				setSubmitting(false);
				onClose();
				fetchUsers();
			})
			.catch((err) => {
				Toast('error', err?.body?.error?.message ?? 'Username or Email is already exist');
				setSubmitting(false);
			});
	};

	return (
		<Modal show={isOpen} onHide={onClose}>
			<Modal.Header className="application__heading" closeButton>
				<Modal.Title>{!userData ? 'Add' : 'Edit'} Application User</Modal.Title>
			</Modal.Header>
			<Formik
				initialValues={{
					username: userData?.userName || '',
					firstname: userData?.name || '',
					lastname: userData?.surname || '',
					email: userData?.email || '',
					role: roleData || [],
					number: userData?.phoneNumber || '',
					password: userData?.password || 'Test@123',
					loginType: loginTypeArray[1]?.label,
				}}
				validationSchema={FORM_VALIDATION}
				// validateOnMount={true}
				onSubmit={(values, { setSubmitting }) => {
					// eslint-disable-next-line no-unused-expressions
					userData ? handleEdit(values, setSubmitting) : handleAdd(values, setSubmitting);
				}}
				enableReinitialize={true}>
				{({ values, handleBlur, handleChange, handleSubmit, isSubmitting, errors }) => (
					<Form onSubmit={handleSubmit}>
						<Modal.Body>
							<div className="row">
								<div className="col-6">
									<TextField
										placeholder=""
										type="text"
										label="First Name"
										name="firstname"
										value={values.firstname}
										onChange={handleChange}
										onBlur={handleBlur}
									/>
								</div>
								<div className="col-6">
									<TextField
										placeholder=""
										label="Last Name"
										type="text"
										name="lastname"
										value={values.lastname}
										onChange={handleChange}
										onBlur={handleBlur}
									/>
								</div>

								<div className="col-6">
									<TextField
										placeholder=""
										label="User Name"
										type="text"
										name="username"
										value={values.username}
										onChange={handleChange}
										onBlur={handleBlur}
									/>
								</div>
								<div className="col-6">
									<TextField
										placeholder=""
										label="Phone Number"
										type="text"
										name="number"
										value={values.username}
										onChange={handleChange}
										onBlur={handleBlur}
									/>
								</div>
								<div className="col-6">
									<TextField
										placeholder=""
										type="email"
										name="email"
										label="Email"
										value={values.email}
										onChange={handleChange}
										onBlur={handleBlur}
									/>
								</div>
								<div className="col-6">
									<label htmlFor="" className="input__label">
										Role
										<b className="text-danger position-absolute" style={{ marginLeft: '.2rem' }}>
											*
										</b>
									</label>
									<Field
										className="custom-select"
										name="role"
										options={filterValue}
										component={CustomSelect}
										placeholder="Select a role"
										isMulti={true}
									/>
									{errors && errors?.role && (
										<>
											<div className="input__error__icon">
												<RiErrorWarningFill />
											</div>
											<span className="input__error__container"> {errors.role}</span>
										</>
									)}
								</div>
								<div className="col-6">
									<label htmlFor="" className="input__label">
										Login Type
										<b className="text-danger position-absolute" style={{ marginLeft: '.2rem' }}>
											*
										</b>
									</label>
									<Field
										className="custom-select"
										name="loginType"
										options={loginTypeArray}
										component={CustomSelect}
										placeholder="Select Login Type"
										isMulti={false}
										readOnly
									/>
									{errors && errors?.loginType && (
										<>
											<div className="input__error__icon">
												<RiErrorWarningFill />
											</div>
											<span className="input__error__container"> {errors.loginType}</span>
										</>
									)}
								</div>
							</div>
						</Modal.Body>
						<Modal.Footer>
							<Button type="submit" loading={isSubmitting}>
								Save
							</Button>
							<Button type="btn" onClick={onClose} outlined>
								Cancel
							</Button>
						</Modal.Footer>
					</Form>
				)}
			</Formik>
		</Modal>
	);
}
