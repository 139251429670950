/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Amnil_UtilityServiceBackEnd_CmsMenuManagement_Dtos_AddCmsMenuResponseDto } from '../models/Amnil_UtilityServiceBackEnd_CmsMenuManagement_Dtos_AddCmsMenuResponseDto';
import type { Amnil_UtilityServiceBackEnd_CmsMenuManagement_Dtos_UtilityServiceCmsMenuCreateDto } from '../models/Amnil_UtilityServiceBackEnd_CmsMenuManagement_Dtos_UtilityServiceCmsMenuCreateDto';
import type { Amnil_UtilityServiceBackEnd_CmsMenuManagement_Dtos_UtilityServiceCmsMenuListDto } from '../models/Amnil_UtilityServiceBackEnd_CmsMenuManagement_Dtos_UtilityServiceCmsMenuListDto';
import type { Amnil_UtilityServiceBackEnd_CmsMenuManagement_Dtos_UtilityServiceCmsMenuStatusDto } from '../models/Amnil_UtilityServiceBackEnd_CmsMenuManagement_Dtos_UtilityServiceCmsMenuStatusDto';
import type { Amnil_UtilityServiceBackEnd_CmsMenuManagement_Dtos_UtilityServiceCmsMenuStatusUpdateDto } from '../models/Amnil_UtilityServiceBackEnd_CmsMenuManagement_Dtos_UtilityServiceCmsMenuStatusUpdateDto';
import type { Amnil_UtilityServiceBackEnd_CmsMenuManagement_Dtos_UtilityServiceCmsMenuUpdateDto } from '../models/Amnil_UtilityServiceBackEnd_CmsMenuManagement_Dtos_UtilityServiceCmsMenuUpdateDto';
import type { Amnil_UtilityServiceBackEnd_CmsMenuManagement_Dtos_UtilityServiceFrontendCmsMenuDto } from '../models/Amnil_UtilityServiceBackEnd_CmsMenuManagement_Dtos_UtilityServiceFrontendCmsMenuDto';
import type { Volo_Abp_Application_Dtos_PagedResultDto_1 } from '../models/Volo_Abp_Application_Dtos_PagedResultDto_1';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class CmsMenuManagementService {

    /**
     * @returns Amnil_UtilityServiceBackEnd_CmsMenuManagement_Dtos_UtilityServiceCmsMenuListDto Success
     * @throws ApiError
     */
    public static getCmsMenuManagement(): CancelablePromise<Array<Amnil_UtilityServiceBackEnd_CmsMenuManagement_Dtos_UtilityServiceCmsMenuListDto>> {
        return __request({
            method: 'GET',
            path: `/admin-api/api/app/cms-menu-management/cms-menu-list`,
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param searchKeyword 
     * @param sorting 
     * @param skipCount 
     * @param maxResultCount 
     * @returns Volo_Abp_Application_Dtos_PagedResultDto_1<Amnil_UtilityServiceBackEnd_CmsMenuManagement_Dtos_PagedCmsMenuResultDto_Amnil_UtilityServiceBackEnd_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null_> Success
     * @throws ApiError
     */
    public static getCmsMenuManagement1(
searchKeyword?: string,
sorting?: string,
skipCount?: number,
maxResultCount?: any,
): CancelablePromise<Volo_Abp_Application_Dtos_PagedResultDto_1> {
        return __request({
            method: 'GET',
            path: `/admin-api/api/app/cms-menu-management/cms-menus`,
            query: {
                'SearchKeyword': searchKeyword,
                'Sorting': sorting,
                'SkipCount': skipCount,
                'MaxResultCount': maxResultCount,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param cmsMenuType 
     * @returns Amnil_UtilityServiceBackEnd_CmsMenuManagement_Dtos_UtilityServiceFrontendCmsMenuDto Success
     * @throws ApiError
     */
    public static getCmsMenuManagement2(
cmsMenuType?: string,
): CancelablePromise<Array<Amnil_UtilityServiceBackEnd_CmsMenuManagement_Dtos_UtilityServiceFrontendCmsMenuDto>> {
        return __request({
            method: 'GET',
            path: `/admin-api/api/app/cms-menu-management/cms-menus-by-type`,
            query: {
                'cmsMenuType': cmsMenuType,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Amnil_UtilityServiceBackEnd_CmsMenuManagement_Dtos_AddCmsMenuResponseDto Success
     * @throws ApiError
     */
    public static postCmsMenuManagement(
requestBody?: Amnil_UtilityServiceBackEnd_CmsMenuManagement_Dtos_UtilityServiceCmsMenuCreateDto,
): CancelablePromise<Amnil_UtilityServiceBackEnd_CmsMenuManagement_Dtos_AddCmsMenuResponseDto> {
        return __request({
            method: 'POST',
            path: `/admin-api/api/app/cms-menu-management/cms-menu`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param cmsMenuId 
     * @param requestBody 
     * @returns Amnil_UtilityServiceBackEnd_CmsMenuManagement_Dtos_AddCmsMenuResponseDto Success
     * @throws ApiError
     */
    public static putCmsMenuManagement(
cmsMenuId: string,
requestBody?: Amnil_UtilityServiceBackEnd_CmsMenuManagement_Dtos_UtilityServiceCmsMenuUpdateDto,
): CancelablePromise<Amnil_UtilityServiceBackEnd_CmsMenuManagement_Dtos_AddCmsMenuResponseDto> {
        return __request({
            method: 'PUT',
            path: `/admin-api/api/app/cms-menu-management/cms-menu/${cmsMenuId}`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Amnil_UtilityServiceBackEnd_CmsMenuManagement_Dtos_UtilityServiceCmsMenuStatusUpdateDto Success
     * @throws ApiError
     */
    public static putCmsMenuManagement1(
requestBody?: Array<string>,
): CancelablePromise<Array<Amnil_UtilityServiceBackEnd_CmsMenuManagement_Dtos_UtilityServiceCmsMenuStatusUpdateDto>> {
        return __request({
            method: 'PUT',
            path: `/admin-api/api/app/cms-menu-management/cms-menu-statuses`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Amnil_UtilityServiceBackEnd_CmsMenuManagement_Dtos_UtilityServiceCmsMenuStatusDto Success
     * @throws ApiError
     */
    public static getCmsMenuManagement3(): CancelablePromise<Array<Amnil_UtilityServiceBackEnd_CmsMenuManagement_Dtos_UtilityServiceCmsMenuStatusDto>> {
        return __request({
            method: 'GET',
            path: `/admin-api/api/app/cms-menu-management/cms-menu-statuses`,
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param cmsMenuIds 
     * @returns any Success
     * @throws ApiError
     */
    public static deleteCmsMenuManagement(
cmsMenuIds?: Array<string>,
): CancelablePromise<any> {
        return __request({
            method: 'DELETE',
            path: `/admin-api/api/app/cms-menu-management/menus`,
            query: {
                'cmsMenuIds': cmsMenuIds,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns string Success
     * @throws ApiError
     */
    public static getCmsMenuManagement4(): CancelablePromise<string> {
        return __request({
            method: 'GET',
            path: `/admin-api/api/app/cms-menu-management/cms-menu-data-source`,
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

}