import axios from 'axios';
import store from 'redux/Store';
import { LogoutConstant } from 'redux/constant/auth.constant';
import { OpenAPI } from 'services/utility-core-services';

OpenAPI.BASE = window.__RUNTIME_CONFIG__.REACT_APP_UTILITY_API_ENDPOINT;
export const axiosConfig = axios.create({
	baseURL: OpenAPI.BASE,
	headers: {
		'cache-control': 'no-cache',
		'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
	},
});
axiosConfig.interceptors.request.use(
	(req: any) => {
		const token = store.getState().auth.token;
		if (token) {
			req.headers.Authorization = `Bearer ${token}`;
		}

		return req;
	},
	function (err) {
		return Promise.reject(err);
	}
);

axiosConfig.interceptors.response.use(
	(res) => {
		return res;
	},
	(error) => {
		const status = error.response ? error.response.status : 401;
		if (status && status === 401) {
			localStorage.clear();
			store.dispatch({
				type: LogoutConstant,
			});
		}
		return Promise.reject(error);
	}
);
