import React, { useEffect, useState } from 'react';
import { AiFillCaretDown, AiOutlineLogout } from 'react-icons/ai';
import { BsShieldLock } from 'react-icons/bs';
import { CgMenuRight } from 'react-icons/cg';
import { FaUserCircle } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import Toast from 'utils/toast';
import { LogoutAction } from '../../redux/Action/auth.action';
import { textUpperCase } from '../../utils/textUpperCase';
const NavList = styled.div`
	&:hover {
		background: ${(props) => props.theme.PrimaryColor};
	}
`;

const NavBar = ({ responsive, setResponsive }: any) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { pathname } = useLocation();
	const [text, setText] = useState('');
	const { userData } = useSelector((state: any) => state?.auth);
	const theme = useSelector((state: any) => state?.theme?.theming);
	const handleLogout = () => {
		dispatch(LogoutAction());
		Toast('success', ' Logout Success');
		navigate('/login');
	};

	useEffect(() => {
		if (pathname) {
			if (pathname === '/') setText('Dashboard');
			if (pathname === '/user-management') setText('User Management');
			if (pathname === '/role-management') setText('Role Management');
			if (pathname === '/menu-management') setText('Menu Management');
			if (pathname === '/request-list') setText('Request List');
			if (pathname === '/audit-log') setText('Audit Log');
			if (pathname === '/visadirect-log') setText('Visa Direct Log');
			if (pathname === '/change-password') setText('Change Password');
			if (pathname === '/setting-service') setText('Setting Service');
			if (pathname === '/balance-limit') setText('Balance Limit');
		}
	}, [pathname]);

	return (
		<div className={responsive ? 'responsive__navbar__container' : 'navbar__container'}>
			<div className="d-flex align-items-center justify-content-between">
				<div className="d-flex align-items-center gap-3">
					<div className="nav__menu__icon" onClick={() => setResponsive(!responsive)}>
						{!responsive && <CgMenuRight color="#111111" size={22} />}
					</div>
					{/* <b>{text}</b> */}
				</div>
				<div className="profile__container">
					<div className="admin_image">
						<FaUserCircle size={22} color="#00b98d" />
						<span className="pe-1 ms-2 profile__text">Welcome,</span>
						<span className="text-capitalize profile__text">
							{userData ? userData.userName && textUpperCase(userData.userName) : null}
						</span>
						<span>
							<AiFillCaretDown />
						</span>
					</div>
					<div className="navbar__dropdown">
						<NavList
							onClick={() => navigate('/change-password')}
							className="navbar__dropdown-list"
							theme={theme}>
							<span className="me-2">
								<BsShieldLock />
							</span>{' '}
							Change Password
						</NavList>
						<NavList onClick={handleLogout} className="navbar__dropdown-list" theme={theme}>
							<span className="me-2">
								<AiOutlineLogout />
							</span>{' '}
							Logout
						</NavList>
					</div>
				</div>
			</div>
		</div>
	);
};

export default React.memo(NavBar);
