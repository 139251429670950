import { Form, Formik } from 'formik';
import React, { memo } from 'react';
import { Modal } from 'react-bootstrap';
import { CgClose } from 'react-icons/cg';
import { Header, TextField } from 'components';
import useAdminCoreUtilityApi from 'hooks/useAdminCoreUtilityApi';
import { UtilityServiceRequestService } from 'services/utility-core-services';
import * as Yup from 'yup';
import Toast from 'utils/toast';
import Button from 'components/Button/Button';

const FORM_VALIDATION = Yup.object().shape({
	minimumBalance: Yup.number().required('This field is required'),
	maximumBalance: Yup.number().required('This field is required'),
});

const Setting = () => {
	const { handleRequest, isLoading } = useAdminCoreUtilityApi();
	const [response, setResponse] = React.useState<any>([]);
	const [show, setShow] = React.useState<boolean>(false);
	const fetchAmountLimit = async () => {
		const res: any = await handleRequest(UtilityServiceRequestService.getUtilityServiceRequest12());
		setResponse(res);
	};
	React.useEffect(() => {
		fetchAmountLimit();
	}, []);

	const handleBalanceEdit = async (val: any, setSubmitting: any) => {
		setSubmitting(true);
		let id = response[0].id;
		let minimumBalance = val?.minimumBalance;
		let maximumBalance = val?.maximumBalance;
		const res = await handleRequest(
			UtilityServiceRequestService.putUtilityServiceRequest(id, minimumBalance, maximumBalance)
		);
		if (res === true) {
			fetchAmountLimit();
			Toast('success', 'Balance Updated Successfully');
			setShow(!setShow);
			setSubmitting(false);
		}
		if (!res) {
			setSubmitting(false);
			Toast('error', 'Something went wrong please try again later');
		}
	};

	return (
		<>
			<div className="mx-3">
				<Header title="Balance Limit" btnText="Edit Balance" onClick={() => setShow(!show)} />
				{response
					? response.map((e: any) => (
							<div className="d-flex p-4" key={e.id}>
								<span className="p-5 setting__balance__container">
									<span className="setting__balance__text">Minimum Balance : </span>
									<span className="font-weight-bold">{e?.minimumBalance}</span>
								</span>
								{isLoading && (
									<div className="d-flex align-items-center justify-content-center my-5">
										<div className="spinner-border text-success" role="status" />
									</div>
								)}
								<span className="p-5 setting__balance__container">
									<span className="setting__balance__text"> Maximum Balance : </span>{' '}
									<span className="font-weight-bold">{e?.maximumBalance}</span>
								</span>
							</div>
					  ))
					: null}
				{/* Edit balance modal */}
				<Modal show={show} onHide={() => setShow(!show)}>
					<Modal.Header className="application__heading">
						<Modal.Title>Edit Balance</Modal.Title>
						<CgClose onClick={() => setShow(!show)} color="#FFFFFF" size={30} />
					</Modal.Header>
					<Formik
						initialValues={{
							minimumBalance: '',
							maximumBalance: '',
						}}
						validationSchema={FORM_VALIDATION}
						validateOnMount={true}
						onSubmit={(values, { setSubmitting }) => {
							handleBalanceEdit(values, setSubmitting);
						}}>
						{({ isSubmitting }) => (
							<Form>
								<Modal.Body>
									<div className="row">
										<div className="">
											<TextField
												placeholder=""
												type="number"
												label="Minimum Balance"
												name="minimumBalance"
											/>
										</div>
										<div className="">
											<TextField
												placeholder=""
												label="Maximum Balance"
												type="number"
												name="maximumBalance"
											/>
										</div>
									</div>
								</Modal.Body>
								<Modal.Footer>
									<Button type="submit" loading={isSubmitting}>
										Save
									</Button>
									<Button type="button" onClick={() => setShow(false)} outlined>
										Close
									</Button>
								</Modal.Footer>
							</Form>
						)}
					</Formik>
				</Modal>
			</div>
		</>
	);
};

export default memo(Setting);
