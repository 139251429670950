import React, { memo } from 'react';
import { useSelector } from 'react-redux';

import { NavBar, SideBar } from '../components';

const AdminLayout = ({ children }: any) => {
	const [responsive, setResponsive] = React.useState(false);
	const currentYear = new Date().getFullYear();
	const theme = useSelector((state: any) => state.theme.theming);
	return (
		<div>
			<div>
				<SideBar responsive={responsive} setResponsive={setResponsive} />
				<NavBar responsive={responsive} setResponsive={setResponsive} />
			</div>
			<main
				className={
					responsive
						? 'responsive__admin__main__content__left__side'
						: 'admin__main__content__left__side'
				}>
				{children}
			</main>
			<footer className={responsive ? 'responsive__footer__container' : 'footer__container'}>
				<div className="px-4 text-center">
					<span className="me-2 copyright__fotter">copyright &#169; {currentYear}</span>
					<a
						href="https://amniltech.com/"
						className="fw-bold"
						style={{ color: theme.PrimaryColor }}>
						AMNIL TECH.
					</a>
					<span className="ms-1 text-capitalize"> All rights reserved</span>
				</div>
			</footer>
		</div>
	);
};

export default memo(AdminLayout);
