import { themeConstant } from 'redux/constant/theme.constant';

interface Payload {
	name: string;
	value: string;
}

interface ThemePayload {
	theme: Payload[];
	image: Payload[];
}

interface ThemeState {
	isLoading: boolean;
	isError: boolean;
	theming: { [key: string]: string }[];
	image: { [key: string]: string }[];
}

const initState: ThemeState = {
	isLoading: false,
	isError: false,
	theming: [],
	image: [],
};

export const themeReducer = (
	state: ThemeState = initState,
	{ type, payload }: { type: string; payload: ThemePayload }
) => {
	switch (type) {
		case themeConstant.THEME_REQUEST: {
			return {
				...state,
				isLoading: true,
			};
		}
		case themeConstant.THEME_SUCCESS: {
			const obj1: any = {};
			payload.theme?.forEach((item: any) => {
				obj1[item.name] = item.value;
			});
			const obj2: any = {};
			payload.image?.forEach((item: any) => {
				obj2[item.name] = item.value;
			});
			return {
				...state,
				theming: obj1,
				image: obj2,
				isLoading: false,
			};
		}

		case themeConstant.THEME_FAILED: {
			return {
				...state,
				isLoading: false,
				isError: true,
			};
		}
		default:
			return state;
	}
};
