import { toast } from 'react-toastify';

const Toast = (variant: string, message: string) => {
	if (variant === 'success') {
		return toast.success(message);
	} else if (variant === 'error') {
		return toast.error(message);
	} else if (variant === 'info') {
		return toast.info(message);
	} else {
		return null;
	}
};

export default Toast;
