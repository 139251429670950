import { Header } from 'components';
import React from 'react';
import DataTable from 'react-data-table-component';

import Button from 'components/Button/Button';
import Spinner from 'components/Spinner';
import useAdminCoreUtilityApi from 'hooks/useAdminCoreUtilityApi';
import { Form } from 'react-bootstrap';
import { AiOutlineEdit } from 'react-icons/ai';
import { FaTrashAlt } from 'react-icons/fa';
import Toast from 'utils/toast';
import AddEditMenu from './AddEditMenus';
import FilterBar from 'components/Table/FilterBar';
import { CmsMenuManagementService } from 'services/utility-core-services/services/CmsMenuManagementService';

const MenuManagement = () => {
	const [menus, setMenus] = React.useState<any>([]);
	const [menuData, setMenuData] = React.useState<any>();
	const [totalRows, setTotalRows] = React.useState<any>(0);
	const [pageSkip, setPageSkip] = React.useState<any>(0);
	const [pageLimit, setPageLimit] = React.useState<number>(10);
	const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);
	const [filters, setFilters] = React.useState({
		searchQuery: '',
	});

	const { handleRequest: handleFetchMenu, isLoading } = useAdminCoreUtilityApi();

	const fetchMenu = () => {
		let SkipCount = pageSkip;
		let MaxResultCount = pageLimit;
		let sorting = '';
		handleFetchMenu(
			CmsMenuManagementService.getCmsMenuManagement1(
				filters.searchQuery,
				sorting,
				SkipCount,
				MaxResultCount
			).then((res) => {
				const calculatedData = res?.items?.map((row: any, index: any) => {
					return {
						...row,
						sn: index + 1 + pageSkip, // Calculate the serial number
					};
				});

				setMenus(calculatedData);
				setTotalRows(res?.totalCount);
			})
		);
	};
	const handleActivateMenu = (id: any) => {
		const result = confirm('Are you sure want to update status?');
		if (result) {
			CmsMenuManagementService.putCmsMenuManagement1(id).then((res) => {
				fetchMenu();
				Toast('success', 'Status updated successfully');
			});
		}
	};
	const handleDeleteMenu = (id: any) => {
		const result = confirm('Are you sure want to delete this item?');
		if (result) {
			CmsMenuManagementService.deleteCmsMenuManagement(id)
				.then((res) => {
					fetchMenu();
					Toast('success', 'Menu deleted successfully.');
				})
				.catch((err) => {
					Toast('error', 'Parent menu cannot be deleted.');
				});
		}
	};

	//paginations
	const handleOnChangePage = (page: number) => {
		page -= 1;
		setPageSkip(page * pageLimit);
	};
	const handlePerRowsChange = (newPage: number) => setPageLimit(newPage);
	const paginationRowsPerPage = [5, 10];

	React.useEffect(() => {
		fetchMenu();
	}, [pageSkip, pageLimit, filters]);

	const columns: any = [
		{
			name: 'S.N',
			maxWidth: '80px',
			minWidth: '80px',
			selector: (row: any) => row.sn,
		},
		{
			name: 'Menu Name',
			selector: (row: any) => row.displayName,
		},
		{
			name: 'Menu Type',
			selector: (row: any) => row.menuType?.name,
		},
		{
			name: 'Slug',
			selector: (row: any) => row.systemName,
		},
		{
			name: 'Parent',
			selector: (row: any) => row?.parentMenu?.name ?? '--',
		},
		{
			name: 'Status',
			selector: (row: any) => (
				<div className={`${row?.status?.name === 'Active' ? 'badge bg-success' : 'badge bg-dark'}`}>
					{row?.status?.name}
				</div>
			),
		},

		{
			name: 'Actions',
			minWidth: '10%',
			maxWidth: '20%',
			selector: (row: any) => (
				<div className="d-flex align-items-center gap-2">
					<Button
						type="button"
						title="Edit menu"
						onClick={() => {
							setIsModalOpen(true);
							setMenuData(row);
						}}>
						<AiOutlineEdit size={16} />
					</Button>
					<Button
						type="button"
						title="Delete menu"
						outlined
						onClick={() => {
							handleDeleteMenu(row.id);
							fetchMenu();
						}}>
						<FaTrashAlt size={16} />
					</Button>
					<Form>
						<Form.Check
							type="switch"
							id="custom-switch"
							onChange={() => {
								handleActivateMenu([row.id]);
							}}
							checked={row?.status?.name == 'Active' ? true : false}
						/>
					</Form>
				</div>
			),
		},
	];

	//fn to handle modal open and close
	const closeModal = () => {
		setIsModalOpen(false);
		setMenuData(null);
	};
	const handleOpen = () => {
		setIsModalOpen(true);
	};
	return (
		<div>
			<Header title="Menu Management" btnText="Add Menu" onClick={handleOpen} />
			<div className="px-4">
				<FilterBar
					searchVal={filters.searchQuery}
					onSearchValChange={(val: string) => {
						setFilters({
							...filters,
							searchQuery: val,
						});
					}}
					filters={[]}
					hideActionButton
					hideReportActionButton
				/>
			</div>
			<div className="table__container">
				<DataTable
					columns={columns}
					data={menus}
					striped
					pagination
					paginationServer
					paginationTotalRows={totalRows}
					onChangePage={handleOnChangePage}
					onChangeRowsPerPage={handlePerRowsChange}
					progressPending={isLoading}
					paginationRowsPerPageOptions={paginationRowsPerPage}
					progressComponent={<Spinner />}
				/>
			</div>
			{isModalOpen && (
				<AddEditMenu
					isOpen={isModalOpen}
					onClose={closeModal}
					menuData={menuData}
					fetchMenu={fetchMenu}
				/>
			)}
		</div>
	);
};
export default MenuManagement;
