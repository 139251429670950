import AdminLayout from 'container';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';
const BASE_IMAGE_URL = window.__RUNTIME_CONFIG__.REACT_APP_UTILITY_IMAGE_ENDPOINT;

const PrivateRoute: any = () => {
	const navigate = useNavigate();
	const { token, authenticate } = useSelector((state: any) => state.auth);
	const { theming, image } = useSelector((state: any) => state?.theme);

	const { pathname } = useLocation();
	const title = pathname?.replace('/', '');

	useEffect(() => {
		if (!authenticate) {
			navigate('/login');
		}
	}, [authenticate]);

	return token ? (
		<AdminLayout>
			<Helmet>
				<link rel="icon" type="image/*" href={BASE_IMAGE_URL + image?.Favicon || ''} />
				<link rel="apple-touch-icon" type="image/*" href={BASE_IMAGE_URL + image?.Favicon || ''} />
				<title>
					{theming?.ApplicationName || ''} {title ? '|' + ' ' + title : ''}
				</title>
			</Helmet>
			<Outlet />
		</AdminLayout>
	) : (
		<Navigate to={'/login'} />
	);
};

export default PrivateRoute;
