import { memo } from 'react';
import { useSelector } from 'react-redux';
// const BASE_URL = window.__RUNTIME_CONFIG__.REACT_APP_UTILITY_IMAGE_ENDPOINT;

const Dashbaord = () => {
	const { theming } = useSelector((state: any) => state?.theme);
	return (
		<div>
			<div className="dashboard_container">
				{/* {image?.NavigationLogo && (
					<img width={'50%'} className="mt-5" src={BASE_URL + image.NavigationLogo} alt="" />
				)} */}
				<h2 className="dashboard_description">
					Welcome To{' '}
					<b>
						{' '}
						<span style={{ color: theming?.PrimaryColor || '' }}>{theming?.ApplicationName}</span>
					</b>{' '}
					Utility Admin Portal.
				</h2>
			</div>
		</div>
	);
};

export default memo(Dashbaord);
