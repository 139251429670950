import Button from 'components/Button/Button';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { FiSearch } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import styled from 'styled-components';

interface Props {
	hideSearchBox?: Boolean;
	searchVal?: string;
	onSearchValChange?: (val: string) => void;
	onSearch?: any;
	filters?: Array<any>;
	hideActionButton?: boolean;
	showSecondActionButton?: boolean;
	actionButtonName?: string;
	actionButtonIcon?: any;
	actionSecondButtonName?: string;
	actionSecondButtonIcon?: any;
	actionButtonOnClick?: () => void;
	actionSecondButtonOnClick?: () => void;
	hideReportActionButton?: boolean;
	reportButtonName?: string;
	reportButtonIcon?: any;
	reportButtonOnClick?: () => void;
	placeholderName?: string;
	setPage?: any;
	showStartEndDate?: Boolean;
	onChangeStartDate?: any;
	startDate?: any;
	onChangeEndDate?: any;
	endDate?: any;
	onFetch?: any;
}

const FilterWrapper = styled.div``;

export default function FilterBar({
	hideSearchBox,
	searchVal = '',
	onSearch,
	onSearchValChange,
	filters,
	onFetch,
}: Props) {
	const [input, setInput] = useState(searchVal);
	const [timer, setTimer]: any = useState(null);
	const [now, setNow]: any = useState(dayjs());
	const themes = useSelector((state: any) => state?.theme?.theming);

	const inputChanged = (e: any) => {
		setInput(e.target.value);
		if (onSearchValChange) {
			clearTimeout(timer);

			const newTimer = setTimeout(() => {
				onSearchValChange(e.target.value);
			}, 500);

			setTimer(newTimer);
		}
	};

	return (
		<FilterWrapper>
			<div className="row">
				{!hideSearchBox && (
					<form
						onSubmit={(e) => {
							e.preventDefault();
							// onSearch();
						}}
						className="col-lg-3 col-md-6 col-12 mb-2">
						<div className="input-group input-group-icon ">
							<input
								className="form-control"
								placeholder={'Search...'}
								value={input}
								onChange={inputChanged}
							/>
							<span className="input-group-text">
								<FiSearch />
							</span>
						</div>
					</form>
				)}

				{filters?.map(
					(
						filter: {
							defaultOption: string;
							selectedOption: string;
							onChange: (e: string) => void;
							isDisabled: boolean;
							filterOptions: Array<any>;
							isMultiple: boolean;
							label: string;
							inputType: string;
							min: any;
							max: any;
							ToDate: any;
							filteredDate: any;
							onFetch: () => void;
						},
						i
					) => {
						const customFilterOptions: any = filter?.filterOptions?.map((val: any) => ({
							label: val?.title || val?.label,
							value: val?.value || '',
						}));
						return (
							<div className="col-lg-2 col-md-6 col-12 mb-2" key={i}>
								{filter.inputType === 'select' ? (
									<>
										<label>{filter.label}</label>
										<Select
											name={filter.defaultOption}
											isSearchable
											onChange={(value: any) =>
												filter.onChange(
													!filter.isMultiple ? value?.value : value?.map((item: any) => item.value)
												)
											}
											isDisabled={filter.isDisabled}
											placeholder={filter.defaultOption}
											options={customFilterOptions}
											defaultValue={filter.defaultOption}
											className="react-select"
											classNamePrefix="select"
											theme={(theme: any) => ({
												...theme,
												colors: {
													...theme.colors,
													primary25: themes.PrimaryColor,
													primary: themes.PrimaryColor,
												},
											})}
											isMulti={filter.isMultiple ? filter.isMultiple : false}
										/>
									</>
								) : filter.inputType === 'date' ? (
									<>
										<label htmlFor="">{filter.label}</label>
										<input
											type="date"
											name="FromDate"
											defaultValue={filter.defaultOption}
											className="custom__form__select datepicker"
											onChange={(e: any) => {
												const newDate = dayjs(e.target.value).format('YYYY-MM-DD');
												filter.onChange(newDate);
											}}
											autoComplete="off"
											min={filter?.min}
											max={filter?.max}
										/>
									</>
								) : filter.inputType === 'input' ? (
									<>
										<label htmlFor="">{filter.label}</label>
										<input
											type="text"
											name={filter.defaultOption}
											className="form-control"
											onChange={(e: any) => {
												filter.onChange(e.target.value);
											}}
											autoComplete="off"
										/>
									</>
								) : filter.inputType === 'number' ? (
									<>
										<label htmlFor="">{filter.label}</label>
										<input
											type="number"
											name={filter.defaultOption}
											className="form-control"
											onChange={(e: any) => {
												filter.onChange(e.target.value);
											}}
											autoComplete="off"
										/>
									</>
								) : filter.inputType === 'button' ? (
									<div className="mt-4">
										<Button type="button" onClick={filter.onFetch}>
											Search
										</Button>
									</div>
								) : (
									''
								)}
							</div>
						);
					}
				)}
			</div>
		</FilterWrapper>
	);
}
