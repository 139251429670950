import { ErrorMessage, Field, Formik } from 'formik';
import { Form, Modal } from 'react-bootstrap';

import { CustomSelect, TextField } from 'components';
import Button from 'components/Button/Button';
import useAdminCoreUtilityApi from 'hooks/useAdminCoreUtilityApi';
import React from 'react';
import { RiErrorWarningFill } from 'react-icons/ri';
import Toast from 'utils/toast';
import * as Yup from 'yup';
import { CmsMenuLinkTypeService } from 'services/utility-core-services/services/CmsMenuLinkTypeService';
import { CmsMenuManagementService } from 'services/utility-core-services/services/CmsMenuManagementService';
import { CmsMenuTypeService } from 'services/utility-core-services/services/CmsMenuTypeService';

const FORM_VALIDATION = Yup.object().shape({
	displayName: Yup.string().required('This field is Required'),
	utilityServiceCmsMenuTypeId: Yup.string().required('This field is Required'),
	utilityServiceCmsMenuLinkTypeId: Yup.string().required('This field is Required'),
	url: Yup.string()
		.matches(/^(https?:\/\/).*/, 'Invalid URL, start with http:// or https://')
		.required('This field is required'),
});

type Props = {
	isOpen: boolean;
	onClose: any;
	menuData: any;
	fetchMenu: any;
};

type Link = {
	label: string;
	value: string;
};

export default function AddEditMenu({ isOpen, onClose, menuData, fetchMenu }: Props) {
	const [menuType, setMenuType] = React.useState<Link[]>([]);
	const [linkType, setLinkType] = React.useState<Link[]>([]);
	const [menuList, setMenuList] = React.useState<any>([]);

	const { handleRequest: handleFetchMenuType } = useAdminCoreUtilityApi();
	const { handleRequest: handleFetchLinkType } = useAdminCoreUtilityApi();

	const fetchMenuType = () => {
		handleFetchMenuType(
			CmsMenuTypeService.getCmsMenuType().then((res) => {
				const type = res?.map((item: any) => {
					return {
						label: item.displayName,
						value: item.id,
					};
				});
				setMenuType(type);
			})
		);
	};
	const fetchLinkType = () => {
		handleFetchLinkType(
			CmsMenuLinkTypeService.getCmsMenuLinkType().then((res) => {
				const type = res?.map((item: any) => {
					return {
						label: item.displayName,
						value: item.id,
					};
				});
				setLinkType(type);
			})
		);
	};
	const fetchMenuList = () => {
		CmsMenuManagementService.getCmsMenuManagement().then((res) => {
			setMenuList(res);
		});
	};

	React.useEffect(() => {
		Promise.allSettled([fetchMenuList(), fetchMenuType(), fetchLinkType()]);
	}, []);

	//fn to add user
	const handleAdd = async (formData: any, setSubmitting: any) => {
		setSubmitting(true);
		CmsMenuManagementService.postCmsMenuManagement(formData)
			.then((res: any) => {
				if (res) {
					Toast('success', 'Menu Added Successfully');
				}
				setSubmitting(false);
				fetchMenu();
				onClose();
			})
			.catch((err) => {
				Toast('error', `${err?.body?.error?.message || 'Problem in creating Menu'}`);
				setSubmitting(false);
			});
	};

	//fn to edit user
	const handleEdit = async (formData: any, setSubmitting: any) => {
		setSubmitting(true);
		CmsMenuManagementService.putCmsMenuManagement(menuData.id, formData)
			.then((res: any) => {
				if (res) {
					Toast('success', 'Menu Updated Successfully');
				}
				setSubmitting(false);
				fetchMenu();
				onClose();
			})
			.catch((err) => {
				Toast('error', `${err?.body?.error?.message || 'Problem in creating Menu'}`);
				setSubmitting(false);
			});
	};

	return (
		<Modal show={isOpen} onHide={onClose}>
			<Modal.Header className="application__heading" closeButton>
				<Modal.Title>{menuData ? 'Edit Menu' : 'Add Menu'}</Modal.Title>
			</Modal.Header>
			<Formik
				initialValues={{
					displayName: menuData?.displayName || '',
					parentMenuId: menuData?.parentMenu?.id || '',
					utilityServiceCmsMenuTypeId: menuData?.menuType.id || '',
					utilityServiceCmsMenuLinkTypeId: menuData?.linkType.id || '',
					utilityServiceCmsMenuStatusId:
						menuData?.status.id || '3a0bf662-53ba-2264-c7da-05fe2b31ab2e',
					url: menuData?.url || '',
				}}
				validationSchema={FORM_VALIDATION}
				enableReinitialize={true}
				onSubmit={(values, { setSubmitting }) => {
					const formData = {
						displayName: values?.displayName || null,
						parentMenuId: values?.parentMenuId || null,
						utilityServiceCmsMenuTypeId: values?.utilityServiceCmsMenuTypeId || null,
						utilityServiceCmsMenuLinkTypeId: values?.utilityServiceCmsMenuLinkTypeId || null,
						utilityServiceCmsMenuStatusId: values?.utilityServiceCmsMenuStatusId,
						url: values?.url || null,
					};
					if (menuData) {
						handleEdit(formData, setSubmitting);
					} else {
						handleAdd(formData, setSubmitting);
					}
				}}>
				{({ values, handleBlur, handleChange, handleSubmit, setFieldValue, touched, errors }) => {
					const filteredParent =
						values.utilityServiceCmsMenuTypeId.length > 0
							? menuList?.filter(
									(item: any) => item?.menuTypeId === values.utilityServiceCmsMenuTypeId
							  )
							: [];
					return (
						<form onSubmit={handleSubmit}>
							<Modal.Body>
								<div className="row">
									<div className="col-6">
										<TextField
											placeholder=""
											type="text"
											label="Title"
											name="displayName"
											value={values.displayName}
											onChange={handleChange}
											onBlur={handleBlur}
										/>
									</div>
									<div className="col-6">
										<label htmlFor="" className="input__label">
											Menu Type
											<b className="text-danger position-absolute" style={{ marginLeft: '.2rem' }}>
												*
											</b>
										</label>
										<Field
											placeholder=""
											type="text"
											component={CustomSelect}
											name="utilityServiceCmsMenuTypeId"
											options={menuType}
										/>
										<>
											{touched.utilityServiceCmsMenuTypeId &&
												errors &&
												errors.utilityServiceCmsMenuTypeId && (
													<div className="input__error__icon">
														<RiErrorWarningFill />
													</div>
												)}
											<ErrorMessage
												component="div"
												name="utilityServiceCmsMenuTypeId"
												className="input__error__container mb-3"
											/>
										</>
									</div>
									<div className="col-6">
										<label htmlFor="" className="input__label">
											Parent Menu
										</label>
										<Field
											type="text"
											component={CustomSelect}
											name="parentMenuId"
											disabled={values.utilityServiceCmsMenuTypeId.length > 0 ? false : true}
											options={
												filteredParent?.map((itm: any) => {
													return { label: itm.displayName, value: itm.id };
												}) || []
											}
										/>
									</div>

									<div className="col-6">
										<label htmlFor="" className="input__label">
											Link Type
											<b className="text-danger position-absolute" style={{ marginLeft: '.2rem' }}>
												*
											</b>
										</label>
										<Field
											placeholder=""
											type="text"
											component={CustomSelect}
											name="utilityServiceCmsMenuLinkTypeId"
											options={linkType}
										/>
										<>
											{errors &&
											touched.utilityServiceCmsMenuLinkTypeId &&
											errors.utilityServiceCmsMenuLinkTypeId ? (
												<div className="input__error__icon">
													<RiErrorWarningFill />
												</div>
											) : (
												''
											)}
											<ErrorMessage
												component="div"
												name="utilityServiceCmsMenuLinkTypeId"
												className="input__error__container mb-3"
											/>
										</>
									</div>

									{values.utilityServiceCmsMenuLinkTypeId ===
										'3a0be17d-6270-65cf-ee7e-12c504a7e6a5' && (
										<div className="col-6">
											<label htmlFor="" className="input__label">
												Url
												<b
													className="text-danger position-absolute"
													style={{ marginLeft: '.2rem' }}>
													*
												</b>
											</label>
											<input
												type="text"
												className={'form-control'}
												name="url"
												defaultValue={values.url || ''}
												onChange={handleChange}
											/>
											<ErrorMessage
												component="div"
												name="url"
												className="input__error__container mb-3"
											/>
										</div>
									)}
									<div className="col-6">
										<label htmlFor="" className="input__label">
											Status
										</label>
										<Form.Check
											type="switch"
											id="custom-switch"
											onChange={(e) => {
												if (e.target.checked) {
													setFieldValue(
														'utilityServiceCmsMenuStatusId',
														'3a0bf662-53ba-1db0-579f-ade64d92b24b'
													);
												} else {
													setFieldValue(
														'utilityServiceCmsMenuStatusId',
														'3a0bf662-53ba-2264-c7da-05fe2b31ab2e'
													);
												}
											}}
											checked={
												values.utilityServiceCmsMenuStatusId ===
												'3a0bf662-53ba-1db0-579f-ade64d92b24b'
													? true
													: false
											}
										/>
									</div>
								</div>
							</Modal.Body>
							<Modal.Footer>
								<Button type="submit">Save</Button>
								<Button type="btn" onClick={onClose} outlined>
									Cancel
								</Button>
							</Modal.Footer>
						</form>
					);
				}}
			</Formik>
		</Modal>
	);
}
