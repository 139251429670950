import { Header } from 'components';
import { FieldArray, Form, Formik } from 'formik';
import { groupBy, isEmpty, mapValues } from 'lodash';
import React from 'react';
import { useDispatch } from 'react-redux';

import Button from 'components/Button/Button';
import FormColorPicker from 'components/form/FormColorPicker';
import useAdminCoreUtilityApi from 'hooks/useAdminCoreUtilityApi';
import { getTheme } from 'redux/Action/theme.action';
import { axiosConfig } from 'utils/interceptors';
import Toast from 'utils/toast';
import * as Yup from 'yup';
import { CmsSettingsService } from 'services/utility-core-services/services/CmsSettingsService';
import { RiErrorWarningFill } from 'react-icons/ri';
const BASE_URL_IMAGE = window.__RUNTIME_CONFIG__.REACT_APP_UTILITY_IMAGE_ENDPOINT;
const BASE_URL = window.__RUNTIME_CONFIG__.REACT_APP_UTILITY_API_ENDPOINT;

const FORM_VALIDATION = Yup.object().shape({
	CustomerCarePhone: Yup.string().test(
		'length',
		'Invalid Phone Number',
		(val: any) => !val || (val && val.length < 15)
	),
	FacebookUrl: Yup.string().matches(
		/^(https?:\/\/).*/,
		'Invalid URL, start with http:// or https://'
	),
	InstagramUrl: Yup.string().matches(
		/^(https?:\/\/).*/,
		'Invalid URL, start with http:// or https://'
	),
	YoutubeUrl: Yup.string().matches(
		/^(https?:\/\/).*/,
		'Invalid URL, start with http:// or https://'
	),
	TwitterUrl: Yup.string().matches(
		/^(https?:\/\/).*/,
		'Invalid URL, start with http:// or https://'
	),
	WhatsappUrl: Yup.string().matches(
		/^(https?:\/\/).*/,
		'Invalid URL, start with http:// or https://'
	),
	PinterestUrl: Yup.string().matches(
		/^(https?:\/\/).*/,
		'Invalid URL, start with http:// or https://'
	),
	LinkedInUrl: Yup.string().matches(
		/^(https?:\/\/).*/,
		'Invalid URL, start with http:// or https://'
	),
	GooglePlaystoreUrl: Yup.string().matches(
		/^(https?:\/\/).*/,
		'Invalid URL, start with http:// or https://'
	),
	AppstoreUrl: Yup.string().matches(
		/^(https?:\/\/).*/,
		'Invalid URL, start with http:// or https://'
	),
});

const ThemeSetting = () => {
	const [settingData, setData] = React.useState<any>();
	const [cmsData, setcmsData] = React.useState<any>([]);
	const dispatch = useDispatch();
	const { handleRequest: handleBackendSetting } = useAdminCoreUtilityApi();

	const fetchBackenSetting = () => {
		handleBackendSetting(CmsSettingsService.getCmsSettings3()).then((response: any) => {
			const newSettingData = mapValues(groupBy(response, 'settingTypeName'));
			const result = mapValues(newSettingData, (group) => groupBy(group, 'settingGroupName'));
			setData(result);
			setcmsData(response);
		});
	};

	React.useEffect(() => {
		fetchBackenSetting();
	}, []);

	const updateBackendSetting = async (values: any, setSubmitting: any) => {
		setSubmitting(true);
		const formData = new FormData();

		//map the key value in formData
		for (let key in values) {
			formData.append(key, values[key]);
		}

		try {
			// CmsSettingsService.putCmsSettings(formData)
			await axiosConfig
				.put(`${BASE_URL}/admin-api/api/app/cms-settings/cms-settings`, formData)
				.then(() => {
					Toast('success', 'Setting Updated Successfully');
					fetchBackenSetting();
					dispatch(getTheme());
				});
			setSubmitting(false);
		} catch (err: any) {
			Toast('error', err?.response?.data?.error?.code || 'Problem in updating setting');
			setSubmitting(false);
		}

		//call dispatch action here
		setSubmitting(false);
	};

	const handleFileChange = (e: any, setFieldValue: any, setFieldError: any, item: any) => {
		const file = e.target.files[0];
		const types = ['image/png', 'image/jpg', 'image/jpeg', 'image/svg', 'image/ico'];
		// Validate file size (maximum 2MB)
		if (!types.includes(file.type)) {
			setFieldError(item.name, 'Invalid image format');
			return;
		}
		const maxSize = 2 * 1024 * 1024; // MB
		if (file.size > maxSize) {
			setFieldError(item.name, 'The selected image exceeds the maximum file size limit (2MB).');
			return;
		}
		setFieldValue(item.name, file);

		const reader = new FileReader();

		//previeww the image on change input
		reader.onload = (event: any) => {
			const previewImageURL = event.target.result;
			setFieldValue(item.name + 'prievew', previewImageURL);
		};
		reader.readAsDataURL(file);
	};

	return (
		<div>
			<Header title="Backend Settings" />
			<div className="p-4">
				<Formik
					initialValues={{
						data: [],
					}}
					validationSchema={FORM_VALIDATION}
					onSubmit={(values: any, { setSubmitting }) => {
						updateBackendSetting(values, setSubmitting);
					}}>
					{({
						isSubmitting,
						touched,
						handleChange,
						errors,
						values,
						setFieldValue,
						setFieldError,
					}) => {
						//update formik input field
						React.useEffect(() => {
							if (!isEmpty(cmsData)) {
								cmsData?.forEach((field: any) => setFieldValue(field.name, field.value, false));
							}
						}, [cmsData]);
						return (
							<Form className="bg-white">
								<div className="card-body">
									<FieldArray
										name="friends"
										render={() => (
											<div>
												{settingData && (
													<div>
														{Object.keys(settingData)?.map((key: any, index) => {
															return (
																<div className="mb-4 p-3 bg-body-tertiary rounded" key={index}>
																	<h2 className="heading__component__title fw-bolder mb-3 pb-2 border-bottom border-2">
																		{key}
																	</h2>
																	{Object.keys(settingData[key])?.map((group, i) => {
																		return (
																			<div key={i} className="heading__sub__component mx-3">
																				<h3
																					className="heading__sub__component__title fw-bolder mb-3"
																					key={i}>
																					{group}
																				</h3>
																				<div className="row">
																					{settingData[key][group]?.map((item: any, j: number) => {
																						if (item?.formType === 'Text field') {
																							return (
																								<div className="col-lg-4 mb-4" key={j}>
																									<label htmlFor={item.name}>{item?.label}</label>
																									<input
																										id={item.name}
																										type="text"
																										className="form-control"
																										name={item.name}
																										value={values[item.name]}
																										placeholder={item?.label}
																										onChange={handleChange}
																									/>
																									{item.name === 'FacebookUrl' && (
																										<>
																											{errors && errors.FacebookUrl && (
																												<span className="text-danger">
																													{errors.FacebookUrl}
																												</span>
																											)}
																										</>
																									)}
																									{item.name === 'InstagramUrl' && (
																										<>
																											{errors && errors.InstagramUrl && (
																												<span className="text-danger">
																													{errors.InstagramUrl}
																												</span>
																											)}
																										</>
																									)}
																									{item.name === 'TwitterUrl' && (
																										<>
																											{errors && errors.TwitterUrl && (
																												<span className="text-danger">
																													{errors.TwitterUrl}
																												</span>
																											)}
																										</>
																									)}
																									{item.name === 'YoutubeUrl' && (
																										<>
																											{errors && errors.YoutubeUrl && (
																												<span className="text-danger">
																													{errors.YoutubeUrl}
																												</span>
																											)}
																										</>
																									)}

																									{item.name === 'PinterestUrl' && (
																										<>
																											{errors && errors.PinterestUrl && (
																												<span className="text-danger">
																													{errors.PinterestUrl}
																												</span>
																											)}
																										</>
																									)}
																									{item.name === 'WhatsappUrl' && (
																										<>
																											{errors && errors.WhatsappUrl && (
																												<span className="text-danger">
																													{errors.WhatsappUrl}
																												</span>
																											)}
																										</>
																									)}
																									{item.name === 'LinkedInUrl' && (
																										<>
																											{errors && errors.LinkedInUrl && (
																												<span className="text-danger">
																													{errors.LinkedInUrl}
																												</span>
																											)}
																										</>
																									)}
																									{item.name === 'AppstoreUrl' && (
																										<>
																											{errors && errors.AppstoreUrl && (
																												<span className="text-danger">
																													{errors.AppstoreUrl}
																												</span>
																											)}
																										</>
																									)}
																									{item.name === 'GooglePlaystoreUrl' && (
																										<>
																											{errors && errors.GooglePlaystoreUrl && (
																												<span className="text-danger">
																													{errors.GooglePlaystoreUrl}
																												</span>
																											)}
																										</>
																									)}
																								</div>
																							);
																						} else if (item?.formType === 'Color picker field') {
																							return (
																								<div className="col-lg-4 mb-4" key={j}>
																									<FormColorPicker
																										name={item.name}
																										label={item?.label}
																										labelClassName="col-lg-12"
																										containerClassName="col-lg-12"
																										value={values[item.name] || ''}
																										onChange={handleChange}
																										errors={errors}
																										touched={touched}
																										setFieldValue={setFieldValue}
																									/>
																								</div>
																							);
																						} else if (item?.formType === 'Email') {
																							return (
																								<div className="col-lg-4 mb-4" key={j}>
																									<div className="mb-3">
																										<label htmlFor={item.name}>{item?.label}</label>
																										<input
																											id={item.name}
																											name={item.name}
																											type="email"
																											defaultValue={values[item.name]}
																											className="form-control"
																											onChange={handleChange}
																										/>
																									</div>
																								</div>
																							);
																						} else if (item?.formType === 'Number field') {
																							return (
																								<div className="col-lg-4 mb-4" key={j}>
																									<div className="mb-3">
																										<label htmlFor={item.name}>{item?.label}</label>
																										<div className="position-relative">
																											{item?.label === 'Customer care phone' && (
																												<span className="input-prefix">+977</span>
																											)}
																											<input
																												id={item.name}
																												name={item.name}
																												type="number"
																												defaultValue={values[item.name]}
																												className="form-control ps-5"
																												onChange={(e) => {
																													setFieldValue(
																														item.name,
																														e.target.value.toString()
																													);
																												}}
																											/>
																										</div>
																									</div>
																									{errors && errors.CustomerCarePhone && (
																										<>
																											<div className="input__error__icon">
																												<RiErrorWarningFill />
																											</div>
																											<span className="input__error__container">
																												{errors.CustomerCarePhone}
																											</span>
																										</>
																									)}
																								</div>
																							);
																						} else if (item?.formType === 'File') {
																							return (
																								<div className="col-lg-4 mb-4" key={j}>
																									<label htmlFor={item.name}>{item?.label}</label>
																									<input
																										id={item.name}
																										type="file"
																										className="form-control"
																										name={item.name}
																										accept=".png,.jpg,.jpeg"
																										placeholder={item?.label}
																										onChange={(e: any) =>
																											handleFileChange(
																												e,
																												setFieldValue,
																												setFieldError,
																												item
																											)
																										}
																										key={j}
																									/>
																									<span className="text-danger">
																										{errors[item.name]}
																									</span>
																									<br />
																									<img
																										src={
																											values[item.name + 'prievew'] ||
																											BASE_URL_IMAGE + item.value
																										}
																										alt=""
																										width="100px"
																									/>

																									<label
																										htmlFor=""
																										className="ms-4 text-capitalize">
																										{values[item.name + 'prievew']
																											? ''
																											: item?.label}
																									</label>
																								</div>
																							);
																						} else {
																							return <></>;
																						}
																					})}
																				</div>
																			</div>
																		);
																	})}
																</div>
															);
														})}
													</div>
												)}
											</div>
										)}
									/>
								</div>
								<div className="d-flex justify-content-end px-5 pb-9">
									<Button
										type="submit"
										loading={isSubmitting}
										disabled={Object.keys(errors).length === 0 ? false : true}>
										Save Update
									</Button>
								</div>
							</Form>
						);
					}}
				</Formik>
			</div>
		</div>
	);
};
export default ThemeSetting;
