import { Header } from 'components';
import Spinner from 'components/Spinner';
import useAdminCoreUtilityApi from 'hooks/useAdminCoreUtilityApi';
import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { toast } from 'react-toastify';
import { UtilityServiceRequestService } from 'services/utility-core-services';
import { ServiceChargeService } from 'services/utility-core-services/services/ServiceChargeService';
import * as Yup from 'yup';

interface ServiceCharge {
	index: number;
	serviceChargeName: string;
	serviceChargeValue: number;
	serviceChargeRuleId: string;
	isActive: boolean;
	isServiceChargeInclusiveOfPrincipalAmount: boolean;
	orderNumber: number;
}

const FORM_VALIDATION = Yup.object().shape({
	serviceChargeName: Yup.string()
		.required('This field is required'),
	serviceChargeValue: Yup.number()
		.required('This field is required'),
	serviceChargeRuleId: Yup.string().required('This field is required'),
	isActive: Yup.boolean().required('This field is required'),
});

const Services: React.FC = () => {
	var curr = new Date();
	curr.setDate(curr.getDate());
	var todayDate = curr.toISOString().substr(0, 10);

	const [serviceNameGroupList, setServiceNameGroupList] = React.useState<any>('');
	const [serviceName, setServiceName] = React.useState<any>('');
	const [service, setService] = React.useState<any>('');
	const [totalRows, setTotalRows] = React.useState(0);
	const [pageSkip, setPageSkip] = React.useState<any>(0);
	const [pageLimit, setPageLimit] = React.useState(10);
	const [show, setShow] = React.useState(false);
	const { isLoading, handleRequest, error } = useAdminCoreUtilityApi();
	const { isLoading:isStatusLoading, handleRequest:updateStatus, error:statusError } = useAdminCoreUtilityApi();
	const [serviceCharges, setServiceCharges] = useState<ServiceCharge[]>([]);
	const [servieRules, setServiceRules] = useState<any>([]);
	const [utilityServiceId, setUtilityServiceId] = useState<string>()


	const fetchServiceRules = async () => {
		let response: any = await handleRequest(ServiceChargeService.getServiceCharge());
		setServiceRules(response)
	}

	const fetchServiceCharges =async (id: string) => {
		let idx = id == "4a95ab19-7d9c-11ec-b416-02420a000b05"?"f22e60a7-7d9c-11ec-b416-02420a000b05" : "3a096c07-60bd-cec7-03f6-f124c576ea5a"
		let response: any = await handleRequest(ServiceChargeService.getServiceCharge1(idx));
		response = response.map((serviceCharge: any,_index: any)=>{
			return {
				...serviceCharge,
				index: _index+1
			}
		})
		setServiceCharges(response);
	}


	const filterValue = servieRules.map((e: { name: string; id: string }) => {
		return {
			label: e.name,
			value: e.id,
		};
	});


	


	function getRuleNameById(id: string) {
		let service = servieRules.filter((s: any) => s.id === id)[0];
		return service.name;
	}

	const removeServiceChargeFromList = (index: any) => {
		setServiceCharges(serviceCharges.filter(serviceCharge => serviceCharge.index !== index));
	};

	const newColumns = [
		{
			name: 'S.N.',
			maxWidth: '80px',
			minWidth: '80px',
			selector: (_row: any) => _row.index,
		},
		{ name: 'Charge Name', selector: (row: any) => row.serviceChargeName },
		{ name: 'Value', selector: (row: any) => row.serviceChargeValue },
		{ name: 'Rule', selector: (row: any) => getRuleNameById(row.serviceChargeRuleId) },
		{ name: 'Active', selector: (row: any) => row.isActive ? "True" : "False" },
		{
			name: 'Actions', selector: (row: any) => (
				<div onClick={() => removeServiceChargeFromList(row.index)} className='btn btn-sm btn-danger'>x</div>
			)
		},
	]

	const handleStatusUpdate = async (id: any) => {
	 await updateStatus(
			UtilityServiceRequestService.postUtilityServiceRequest1(id)
		).then(resp=>{
			if(resp===true){
			 toast.success('Service has been activated') 
			}
			else{
				toast.success('Service has been deactivated')
			}
		});
		getSearchServiceName()
	};

	// Table data fetch


	const getServiceName = async () => {
		const response: any = await handleRequest(
			UtilityServiceRequestService.getUtilityServiceRequest18())

		setServiceNameGroupList(response);

	};

	const getSearchServiceName = async () => {
		const response: any = await handleRequest(
			UtilityServiceRequestService.getUtilityServiceRequest18(service))

			const calculatedData = response?.map((row: any, index: any) => {
				return {
					...row,
					sn: index + 1 + pageSkip, // Calculate the serial number
				};
			});

		setServiceName(calculatedData);
	};
	useEffect(() => {
		getServiceName()
		getSearchServiceName()

	}, []);
	useEffect(() => {

		getSearchServiceName()

	}, [service]);
	const selectedServiceGroup =
		serviceNameGroupList &&

		serviceNameGroupList?.map((e: any) => {
			return {
				label: e.name,
				value: e.name,
			};
		});

	const handleOnChangePage = (page: number) => {
		page -= 1;
		setPageSkip(page * pageLimit);
	};
	const columns: any =React.useMemo(()=>
	[
		{
			name: 'S.N.',
			maxWidth: '80px',
			minWidth: '80px',
			selector: (row: any) => row.sn,
		},
		{
			name: 'Service Name',
			maxWidth:'15%',
			selector: (row: any) => row.name,
		},
		{
			name: 'URL',
			selector: (row: any) => `https://payments.nabilbank.com/${row?.name.toLowerCase().replace(/\s/g, "")}/${row?.id}`,
		},

		{
			name: 'Actions',
			minWidth: '6%',
			maxWidth: '6%',
			selector: (row: any) => (
				<div className='d-flex ms-2'>
					<Form >
					<Form.Check 
						type="switch"
						id="custom-switch"
						onChange={() =>handleStatusUpdate(row?.id)}
						checked={row.isEnabled}
					/>
					</Form>
					
				</div>

			),
		},
	],[]
	) 
	// Table Row Page change

	const initValues = {
		serviceGroup: ''
	};
	const rowsPerPage = [10, 20, 50, 100];
	const [metaData, setMetaData] = React.useState({
		current_page: 1,
		perPage: 10,
		total: 1200,
	});

	const postServiceCharge = async () => {
		try {
			const res: any = await handleRequest(ServiceChargeService.putServiceCharge({
				utilityServiceId: utilityServiceId,
				utilityServiceServiceChargeDto: serviceCharges
			}))

			if (!res) {

			}

			toast.success('Service Charge Added Successfully');

		}
		catch (err) {
			toast.error('Failed to add service charges')
		}

	}

	return (
		<>
		
			<Header title="Service Groups List" />

			{/* <div className="table__filter">

					<Formik
						initialValues={initValues}
						onSubmit={(values) => 
						{({ values, handleBlur, handleChange }) => (
							<Form>

								<div className="row d-flex align-items-center p-3    my-3 ">
									<div className="col-lg-3 col-md-4 col-sm-6 col-12 mb-2">
										<label htmlFor="" className="label__heading mb-1">
											Service  Name
										</label>
										<br />
										<Field
											className="custom-select"
											options={selectedServiceGroup ? selectedServiceGroup : ''}
											component={selectedServiceGroup ? CustomSelect : ''}
											placeholder="All"
											name='serviceGroup'
											isMulti={false}
										/>
										{setService(values?.serviceGroup)}
									</div>



								</div>
							</Form>
						)}
					</Formik>
				</div> */}
			<div className="table__container">
				<DataTable
					dense
					columns={columns}
					data={serviceName}
					striped
					pagination
					onChangePage={(page: any) => {
						setMetaData({ ...metaData, current_page: page });
					}}
					onChangeRowsPerPage={(newPerPage: any) =>
						setMetaData({ ...metaData, perPage: newPerPage })
					}
					paginationPerPage={metaData.perPage}
					paginationRowsPerPageOptions={rowsPerPage}
					progressPending={isLoading}

					progressComponent={
						<Spinner/>
					}
				/>
				{/* <ExportCSV csvData={DataTable.name} fileName={DataTable.name} /> */}

			</div>

		</>
	);
};

export default React.memo(Services);
