import axios from 'axios';
import dayjs from 'dayjs';
import { saveAs } from 'file-saver';
import React from 'react';
import DataTable from 'react-data-table-component';
import { AiOutlineEye } from 'react-icons/ai';
import store from 'redux/Store';

import { Header } from 'components';
import Button from 'components/Button/Button';
import Spinner from 'components/Spinner';
import FilterBar from 'components/Table/FilterBar';
import useAdminCoreUtilityApi from 'hooks/useAdminCoreUtilityApi';
import { HiRefresh } from 'react-icons/hi';
import { UtilityServiceRequestService } from 'services/utility-core-services';
import { textUpperCase } from 'utils/textUpperCase';
import getToken from 'utils/token';
import TransactionDetail from './TransactionDetail';
// import TableFilter from 'components/Table/TableFilter';

const BASEURL = window.__RUNTIME_CONFIG__.REACT_APP_UTILITY_API_ENDPOINT;

const { auth } = store.getState();

const axiosConfig = axios.create({
	baseURL: BASEURL,
	headers: {
		'content-type': 'application/octet-stream',
	},
});

axiosConfig.interceptors.request.use(
	function (config: any) {
		if (auth.token) {
			config.headers.Authorization = `Bearer ${auth.token}`;
			return config;
		} else {
			config.headers.Authorization = `Bearer ${getToken()}`;
			return config;
		}
	},
	function (error) {
		return Promise.reject(error);
	}
);

const RequestList: React.FC = () => {
	var curr = new Date();
	curr.setDate(curr.getDate());
	var todayDate = curr.toISOString().substr(0, 10);
	const [requestList, setRequestList] = React.useState<any>([]);
	const [utilityServiceGroupIdentifier, setUtilityServiceGroupIdentifier] = React.useState<any>('');
	const [paymentGatewayList, setPaymentGatewayList] = React.useState<any>([]);
	const [paymentStatusList, setPaymentStatusList] = React.useState<any>([]);
	const [deliveryStatusList, setDeliveryStatusList] = React.useState<any>([]);
	const [serviceNameList, setServiceNameList] = React.useState<any>([]);
	const [serviceNameGroupList, setServiceNameGroupList] = React.useState<any>([]);
	const [totalRows, setTotalRows] = React.useState(0);
	const [pageSkip, setPageSkip] = React.useState<any>(0);
	const [pageLimit, setPageLimit] = React.useState(10);
	const [isExportLoading, setExportLoading] = React.useState(false);
	const [detailId, setDetailId] = React.useState();
	const [showDetail, setShowDetail] = React.useState(false);
	const [filters, setFilters] = React.useState({
		FromDate: '',
		ToDate: '',
		ServiceNameGroup: '',
		PaymentGateway: '',
		PrimaryIdentifier: '',
		RequestNumber: '',
		ServiceName: '',
		PaymentStatus: '',
		DeliveryStatus: '',
	});

	const { isLoading, handleRequest: fetchRequestList } = useAdminCoreUtilityApi();
	const { handleRequest } = useAdminCoreUtilityApi();
	// handle details actions

	const getServiceName = async () => {
		const res: any = await handleRequest(UtilityServiceRequestService.getUtilityServiceRequest18());
		if (res) {
			const service = [{ name: 'All', value: '' }, ...res];
			setServiceNameGroupList(service);
		}
	};
	const getServiceNameGroup = async () => {
		const res: any = await axios.get(
			window.__RUNTIME_CONFIG__.REACT_APP_UTILITY_CLIENT_API_ENDPOINT +
				`/framework/api/app/utility/utility-services/?utilityServiceGroupIdentifier=${
					utilityServiceGroupIdentifier ? utilityServiceGroupIdentifier[0] : ''
				}`
		);
		if (res) {
			const serviceGroup = [{ name: 'All', value: '' }, ...res.data];
			setServiceNameList(serviceGroup);
		}
	};
	React.useEffect(() => {
		if (utilityServiceGroupIdentifier) {
			getServiceNameGroup();
		}
	}, [utilityServiceGroupIdentifier]);

	const selectedServiceGroup =
		serviceNameGroupList &&
		serviceNameGroupList?.map((e: any) => {
			return {
				label: e.name,
				value: e.name == 'All' ? '' : e.name,
			};
		});

	const serviceNameArray =
		serviceNameList &&
		serviceNameList?.map((e: any) => {
			return {
				label: e.name,
				value: e.name == 'All' ? '' : e.name,
			};
		});

	// Table data fetch
	const fetctRequestList = async () => {
		let take = pageLimit;
		const serviceNameInLowerCase = filters.ServiceName.replace(/\s+/g, '-').toLowerCase();
		let skip = pageSkip;
		const response: any = await fetchRequestList(
			UtilityServiceRequestService.getUtilityServiceRequest(
				skip,
				take,
				serviceNameInLowerCase,
				filters.ServiceNameGroup,
				filters.RequestNumber,
				filters.FromDate,
				filters.ToDate,
				filters.PrimaryIdentifier,
				filters.PaymentGateway,
				filters.PaymentStatus,
				filters.DeliveryStatus
			)
		);
		const calculatedData = response?.items?.map((row: any, index: any) => {
			return {
				...row,
				sn: index + 1 + pageSkip, // Calculate the serial number
			};
		});
		setRequestList(calculatedData);
		setTotalRows(response?.totalCount);
	};

	// Thrid party identifier dropdown list
	const fetctThirdPartyList = async () => {
		const res: any = await handleRequest(UtilityServiceRequestService.getUtilityServiceRequest1());
		if (res) {
			const thirdPartyResponse = [{ label: 'All', value: '' }, ...res];
			setPaymentGatewayList(thirdPartyResponse);
		}
	};

	// Payment status  identifier dropdown list
	const fetchPaymentStatusList = async () => {
		const res: any = await handleRequest(UtilityServiceRequestService.getUtilityServiceRequest5());
		if (res) {
			const paymentStatusResponse = [{ label: 'All', value: '' }, ...res];
			setPaymentStatusList(paymentStatusResponse);
		}
	};

	// Delivewry status  identifier dropdown list
	const fetchDeliveryStatusList = async () => {
		const res: any = await handleRequest(UtilityServiceRequestService.getUtilityServiceRequest6());
		if (res) {
			const deliveryStatusResponse = [{ label: 'All', value: '' }, ...res];
			setDeliveryStatusList(deliveryStatusResponse);
		}
	};

	//export to excel
	const handleExportToExcel = async () => {
		setExportLoading(true);
		const {
			FromDate,
			PaymentGateway,
			PrimaryIdentifier,
			PaymentStatus,
			DeliveryStatus,
			RequestNumber,
			ServiceName,
			ServiceNameGroup,
			ToDate,
		} = filters;

		const { data } = await axiosConfig.get(
			`/admin-api/api/app/utility-service-request/send-detail-to-excel?ServiceName=${ServiceName}&GroupName=${ServiceNameGroup}&RequestNumber=${RequestNumber}&FromDate=${FromDate}&ToDate=${ToDate}&PrimaryIdentifier=${PrimaryIdentifier}&PaymentGateway=${PaymentGateway}&PaymentStatus=${
				PaymentStatus === undefined ? '' : PaymentStatus
			}&DeliveryStatus=${DeliveryStatus === undefined ? '' : DeliveryStatus}`,
			{
				responseType: 'arraybuffer',
			}
		);
		let blob = new Blob([data], {
			type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
		});
		saveAs(blob, `RequestList-${todayDate}-${new Date().toLocaleTimeString()}.xlsx`);
		setExportLoading(false);
	};

	// service name   identifier dropdown list
	// const fetchServiceNameList = async () => {
	// 	const serviceNameResponse = await handleRequest(
	// 		UtilityServiceRequestService.getUtilityServiceRequest4()
	// 	);
	// 	setServiceNameList(serviceNameResponse);
	// };

	const columns: any = [
		{
			name: 'S.N.',
			maxWidth: '80px',
			minWidth: '80px',
			selector: (row: any) => row?.sn,
		},
		{
			name: 'Services',
			selector: (row: any) => textUpperCase(row?.groupName),
		},
		{
			name: 'Service Name',
			selector: (row: any) => textUpperCase(row?.serviceName),
		},
		{
			name: 'Amount',
			selector: (row: any) => `Rs. ${row?.amount}`,
			width: '100px',
		},
		{
			name: 'Primary Identifier',
			selector: (row: any) => row.primaryIdentifier,
			width: '150px',
		},
		{
			name: 'Request Number',
			selector: (row: any) => row?.requestNumber,
			width: '150px',
		},
		{
			name: 'Request Date',
			selector: (row: any) => dayjs(row.requestDate).format('MM/DD/YYYY hh:mm:ss'),
		},

		{
			name: 'Payment Status',
			selector: (row: any) => row.paymentStatus,
		},
		{
			name: 'Delivery Status',
			selector: (row: any) => row.deliveryStatus,
		},
		{
			name: 'Payment Date',
			selector: (row: any) => dayjs(row.paymentDate).format('MM/DD/YYYY hh:mm:ss'),
		},
		{
			name: 'Delivery Date',
			selector: (row: any) => dayjs(row.deliveryDate).format('MM/DD/YYYY hh:mm:ss'),
		},

		{
			name: 'Payment Gateway',
			selector: (row: any) =>
				row.paymentGateway === null ? <span className="text-center">-</span> : row.paymentGateway,
			width: '150px',
			center: true,
		},
		{
			name: 'CBS RefId',
			selector: (row: any) => row.cbsRefId || '-',
		},
		{
			name: 'Actions',
			selector: (row: any) => (
				<Button
					type="button"
					onClick={() => {
						setDetailId(row?.utilityServiceRequestId);
						setShowDetail(true);
					}}>
					<AiOutlineEye size={16} />
				</Button>
			),
		},
	];
	const handleOnChangePage = (page: number) => {
		page -= 1;
		setPageSkip(page * pageLimit);
	};

	// Table Row Page change
	const handlePerRowsChange = (newPage: any) => setPageLimit(newPage);
	const paginationRowsPerPage = [10, 25, 50, 100];

	React.useEffect(() => {
		fetctRequestList();
	}, [pageLimit, pageSkip]);

	React.useEffect(() => {
		fetctThirdPartyList();
		fetchPaymentStatusList();
		fetchDeliveryStatusList();
		// fetchServiceNameList();
		getServiceName();
		getServiceNameGroup();
	}, []);

	const filtersList = [
		{
			label: 'Start Date',
			defaultOption: filters.FromDate,
			inputType: 'date',
			selectedOption: filters.FromDate,
			max: filters.ToDate,
			onChange: (val: string) =>
				setFilters({
					...filters,
					FromDate: val,
				}),
		},
		{
			label: 'To Date',
			defaultOption: filters.ToDate,
			inputType: 'date',
			selectedOption: filters.ToDate,
			min: filters.FromDate,
			onChange: (val: string) =>
				setFilters({
					...filters,
					ToDate: val,
				}),
		},
		{
			label: 'Payment Status',
			defaultOption: filters.PaymentStatus || 'All',
			inputType: 'select',
			selectedOption: filters.PaymentStatus,
			onChange: (val: string) => {
				setFilters({
					...filters,
					PaymentStatus: val,
				});
				localStorage.setItem('rFromDate', val);
			},
			filterOptions: paymentStatusList,
		},
		{
			label: 'Delivery Status',
			defaultOption: filters.DeliveryStatus || 'All',
			inputType: 'select',
			selectedOption: filters.DeliveryStatus,
			onChange: (val: string) => {
				setFilters({
					...filters,
					DeliveryStatus: val,
				});
			},
			filterOptions: deliveryStatusList,
		},
		{
			label: 'Service Group',
			defaultOption: filters.ServiceNameGroup || 'All',
			inputType: 'select',
			selectedOption: filters.ServiceNameGroup,
			onChange: (val: string) =>
				setFilters({
					...filters,
					ServiceNameGroup: val,
				}),
			filterOptions: selectedServiceGroup,
		},
		{
			label: 'Service  Name',
			defaultOption: filters.ServiceName || 'All',
			inputType: 'select',
			selectedOption: filters.ServiceName,
			onChange: (val: string) =>
				setFilters({
					...filters,
					ServiceName: val,
				}),
			filterOptions: serviceNameArray,
		},
		{
			label: 'Payment Gateway',
			defaultOption: filters.PaymentGateway || 'All',
			inputType: 'select',
			selectedOption: filters.PaymentGateway,
			onChange: (val: string) =>
				setFilters({
					...filters,
					PaymentGateway: val,
				}),
			filterOptions: paymentGatewayList,
		},
		{
			label: 'Request Number',
			defaultOption: filters.RequestNumber || '',
			inputType: 'input',
			selectedOption: filters.RequestNumber,
			onChange: (val: string) =>
				setFilters({
					...filters,
					RequestNumber: val,
				}),
		},
		{
			label: 'Primary Identifier',
			defaultOption: filters.PrimaryIdentifier || '',
			inputType: 'input',
			selectedOption: filters.PrimaryIdentifier,
			onChange: (val: string) =>
				setFilters({
					...filters,
					PrimaryIdentifier: val,
				}),
		},
		{
			label: '',
			defaultOption: 'All',
			inputType: 'button',
			onFetch: fetctRequestList,
		},
	];

	return (
		<>
			{!showDetail ? (
				<>
					<Header
						title="Request List"
						btnText="Refresh"
						btnIcon={<HiRefresh size={18} />}
						onClick={fetctRequestList}
					/>
					<div className="table__filter ">
						<FilterBar
							hideSearchBox
							filters={filtersList}
							hideActionButton
							hideReportActionButton
						/>
					</div>

					<div className="table__container">
						<DataTable
							dense
							columns={columns}
							data={requestList}
							striped
							pagination
							paginationServer
							paginationTotalRows={totalRows}
							onChangePage={handleOnChangePage}
							onChangeRowsPerPage={handlePerRowsChange}
							progressPending={isLoading}
							paginationRowsPerPageOptions={paginationRowsPerPage}
							progressComponent={<Spinner />}
						/>
						{/* <ExportCSV csvData={DataTable.name} fileName={DataTable.name} /> */}
					</div>
				</>
			) : (
				<TransactionDetail id={detailId} close={setShowDetail} />
			)}
		</>
	);
};

export default React.memo(RequestList);
