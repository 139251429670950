import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

type ButtonProps = {
	children: React.ReactNode;
	type: string;
	onClick?: () => void;
	outlined?: boolean;
	disabled?: boolean;
	loading?: boolean;
	title?: string;
	width?: string;
};

const ButtonWrapper = styled.button<any>`
	background: ${(props: { backgroundColor: string; outlined: boolean; disabled: boolean }) =>
		props.outlined
			? 'transparent'
			: props.disabled
			? props.backgroundColor
			: props.backgroundColor};
	color: ${(props: { backgroundColor: string; outlined: boolean; color: string }) =>
		props.outlined ? props.backgroundColor : props.color};
	border-radius: 7px;
	border: ${(props: { backgroundColor: string; outlined: boolean }) =>
		props.outlined ? '1px solid' + props.backgroundColor : '1px solid transparent'};
	font-size: 0.8125rem;
	width: ${(props) => props.widths};
	text-align: center;
	font-weight: 600;
	padding: 7px 15px;
	align-items: center;
	border: 1px solid;
	transition: 0.2s;
	&:hover {
		transition: 0.2s;
		background: ${(props: { backgroundColor: string; outlined: boolean }) =>
			props.outlined ? props.backgroundColor : props.backgroundColor};
		color: #fff;
	}
`;

export default function Button({
	children,
	type = 'button',
	onClick,
	outlined = false,
	disabled = false,
	loading,
	title,
	width = 'auto',
}: ButtonProps) {
	const theme = useSelector((state: any) => state.theme.theming);
	return (
		<ButtonWrapper
			backgroundColor={outlined ? theme?.SecondaryColor : theme?.PrimaryButtonColor}
			width={width}
			color={theme?.ButtonTextColor ?? '#000'}
			title={title || ''}
			type={type}
			onClick={onClick}
			disabled={disabled || loading}
			outlined={outlined}>
			{children}
			{loading && (
				<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
			)}
		</ButtonWrapper>
	);
}
