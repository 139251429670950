import { Header } from 'components';
import dayjs from 'dayjs';
import React from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

import useAdminCoreUtilityApi from 'hooks/useAdminCoreUtilityApi';
import { UtilityServiceRequestService } from 'services/utility-core-services';
import Spinner from 'components/Spinner';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import FilterHeader from 'components/Table/FilterHeader';

const TabsContainer = styled.div<any>`
	.nav-item {
		.nav-link {
			color: ${(props) => props.theme.PrimaryColor};
			font-weight: 700;
			&.active {
				border-color: ${(props) => props.theme.PrimaryColor};
				background-color: ${(props) => props.theme.PrimaryColor};
				color: #fff;
			}
		}
	}
`;

export default function TransactionDetail({ id, close }: any) {
	// const [requestServiceChargeDetail, setRequestServiceChargeDetail] = React.useState<any>({});
	const theme = useSelector((state: any) => state.theme.theming);
	const [requestListViewDetailList, setRequestListViewDetailList] = React.useState<any>({});
	const [deliveryLog, setDeliveryLog] = React.useState([]);
	const [paymentLog, setPaymentLog] = React.useState([]);
	const [ipgLog, setIpgLog] = React.useState<any>({});

	const { isLoading, handleRequest: fetchRequestDetailList } = useAdminCoreUtilityApi();
	// const { handleRequest: fetchServiceChargeDetail } = useAdminCoreUtilityApi();
	const { handleRequest: fetchPaymentLog } = useAdminCoreUtilityApi();
	const { handleRequest: fetchDeliveryLog } = useAdminCoreUtilityApi();
	const { handleRequest: fetchIpgLog } = useAdminCoreUtilityApi();

	React.useEffect(() => {
		if (id) {
			fetchRequestDetailList(UtilityServiceRequestService.getUtilityServiceRequest3(id)).then(
				(resp) => setRequestListViewDetailList(resp)
			);
			// fetchServiceChargeDetail(UtilityServiceRequestService.getUtilityServiceRequest19(id)).then(
			// 	(serviceCharge) => setRequestServiceChargeDetail(serviceCharge)
			// );

			fetchPaymentLog(UtilityServiceRequestService.getUtilityServiceRequest9(id)).then(
				(paymentLog: any) => setPaymentLog(paymentLog)
			);
			fetchDeliveryLog(UtilityServiceRequestService.getUtilityServiceRequest10(id)).then(
				(deliveryLog: any) => setDeliveryLog(deliveryLog)
			);
			fetchIpgLog(UtilityServiceRequestService.getUtilityServiceRequest11(id)).then((ipg) =>
				setIpgLog(ipg)
			);
		}
	}, [id]);

	const prettyData =
		requestListViewDetailList?.parameter !== undefined || ''
			? JSON.parse(requestListViewDetailList.parameter)
			: '';

	const [key, setKey] = React.useState('details');

	const exportToExcel = () => {
		const data0 = {};
		const data1 = [
			{
				'Service  Name': requestListViewDetailList?.serviceName ?? '',
				'Primary Identifier': requestListViewDetailList?.primaryIdentifier ?? '',
				'Request Number': requestListViewDetailList.requestNumber ?? '',
				Parameter: JSON.stringify(prettyData, null, 4) ?? '',
				'Payment Status': requestListViewDetailList.paymentStatus ?? '',
				'Delivery Status': requestListViewDetailList.deliveryStatus ?? '',
				'Requested Status': requestListViewDetailList.requestDate ?? '',
				// 'Delivery Status': requestListViewDetailList.deliveryStatus ?? '',
				'Created On': requestListViewDetailList.createdOn ?? '',
			},
		];
		const data2 = [
			['SN', 'Status', 'Parameter', 'Created On'],
			...(paymentLog.map((item: any, i: any) => [
				i + 1,
				item.displayName,
				item.logParameter,
				dayjs(item.creationTime).format('YYYY-MM-DDD, HH:MM a'),
			]) ?? [{}]),
		];
		const data3 = [
			['SN', 'Status', 'Parameter', 'Created On'],
			...(deliveryLog?.map((item: any, i: any) => [
				i + 1,
				item.displayName,
				item.logParameter,
				dayjs(item.creationTime).format('YYYY-MM-DDD, HH:MM a'),
			]) ?? [{}]),
		];
		const data4 = [
			['SN', 'Created On', 'status'],
			...(ipgLog?.transactionReportDetailLogDTOList?.map((item: any, i: any) => [
				i + 1,
				dayjs(item.timestamp).format('YYYY-MM-DDD, HH:MM a'),
				item.transactionStatus,
			]) ?? [{}]),
		];
		const data5 = [
			['SN', 'TransactionId', 'Message', 'Activity Date'],
			...(ipgLog?.transactionReportDetailDTOList?.map((item: any, i: any) => [
				i + 1,
				item.transactionId,
				item.message,
				dayjs(item.activityDate).format('YYYY-MM-DDD, HH:MM a'),
			]) ?? [{}]),
		];

		const wb = XLSX.utils.book_new();

		const ws = XLSX.utils.json_to_sheet([data0], {
			header: [`Transaction detail of${id}`, '', '', '', '', '', '', '', ''],
		});
		const range = XLSX.utils.decode_range(ws['!ref'] as any);

		const next = range.e.r + 2;
		XLSX.utils.sheet_add_json(ws, data1, {
			header: Object.keys(data1),
			origin: { r: range.e.r + 2, c: range.s.c },
		});
		range.e.r = next + data1.length;

		//**@table: Payment logs */
		const headerStartRow1 = range.e.r + 3;
		const headerEndRow1 = headerStartRow1;
		const headerStartCol1 = range.s.c;
		const headerEndCol1 = headerStartCol1 + 3;
		XLSX.utils.sheet_add_json(ws, [['Payment Logs'], ['']], {
			header: [],
			skipHeader: true,
			origin: { r: range.e.r + 3, c: range.s.c },
		});

		const nextRow = range.e.r + 4;
		XLSX.utils.sheet_add_json(ws, data2, {
			header: [],
			skipHeader: true,
			origin: { r: nextRow, c: range.s.c },
		});
		range.e.r = nextRow + data2.length;

		//**@table: Delivery logs */
		const headerStartRow2 = range.e.r + 3;
		const headerEndRow2 = headerStartRow2;
		const headerStartCol2 = range.s.c;
		const headerEndCol2 = headerStartCol2 + 3;
		XLSX.utils.sheet_add_json(ws, [['Delivery Logs'], ['']], {
			header: [],
			skipHeader: true,
			origin: { r: range.e.r + 3, c: range.s.c },
		});

		const nextRow1 = range.e.r + 4;
		XLSX.utils.sheet_add_json(ws, data3, {
			header: [],
			skipHeader: true,
			origin: { r: nextRow1, c: range.s.c },
		});
		range.e.r = nextRow1 + data3.length - 1;

		//**@table: ipg logs */
		XLSX.utils.sheet_add_json(ws, [['Ipg Logs'], ['']], {
			header: [],
			skipHeader: true,
			origin: { r: range.e.r + 3, c: range.s.c },
		});
		ws['!merges'] = [
			{
				s: { r: headerStartRow1, c: headerStartCol1 },
				e: { r: headerEndRow1, c: headerEndCol1 + 10 },
			},
			{
				s: { r: headerStartRow2, c: headerStartCol2 },
				e: { r: headerEndRow2, c: headerEndCol2 + 10 },
			},
			{ s: { r: range.e.r + 3, c: range.s.c }, e: { r: range.e.r + 3, c: range.s.c + 10 } },
		];
		const nextRow2 = range.e.r + 4;
		XLSX.utils.sheet_add_json(ws, data4, {
			header: [],
			skipHeader: true,
			origin: { r: nextRow2, c: range.s.c },
		});
		range.e.r = nextRow2 + data4.length - 1;

		const nextRow3 = range.e.r + 4;
		XLSX.utils.sheet_add_json(ws, data5, {
			header: [],
			skipHeader: true,
			origin: { r: nextRow3, c: range.s.c },
		});
		range.e.r = nextRow3 + data4.length - 1;

		ws['!ref'] = XLSX.utils.encode_range(range);

		XLSX.utils.book_append_sheet(wb, ws, 'Table');
		XLSX.writeFile(wb, `${id}.xlsx`);
	};

	return (
		<div className="container-fluid">
			{/* {error && <span>Not Found</span>} */}
			<Header
				title="Transaction Details"
				pageBack={() => close(null)}
				extraButton={
					<FilterHeader
						handleExportToExcel={exportToExcel}
						// isExportLoading={isExportLoading}
					/>
				}
			/>
			<div className="px-4 position-relative">
				{isLoading && (
					<div
						className="d-flex align-items-center justify-content-center position-absolute bg-white"
						role="status"
						style={{
							left: ' 50%',
							top: '37%',
						}}>
						<Spinner />
					</div>
				)}
				<TabsContainer theme={theme}>
					<Tabs
						id="controlled-tab-example"
						activeKey={key}
						onSelect={(k: any) => setKey(k)}
						className="mb-3">
						<Tab eventKey="details" title="Details">
							{requestListViewDetailList ? (
								<div className="row">
									<div className="col-md-6 col-12">
										<div className="table-responsive">
											<table className="table table-bordered">
												<tbody>
													<tr>
														<td className="first__column">Service Name</td>
														<td>
															{requestListViewDetailList ? (
																requestListViewDetailList?.serviceName
															) : (
																<span>N/A</span>
															)}
														</td>
													</tr>

													<tr>
														<td className="first__column">Request Number</td>
														<td>
															{requestListViewDetailList ? (
																requestListViewDetailList?.requestNumber
															) : (
																<span>N/A</span>
															)}
														</td>
													</tr>
													<tr>
														<td className="first__column">Request Date</td>
														<td>
															{requestListViewDetailList ? (
																dayjs(requestListViewDetailList?.createdOn).format(
																	'MM/DD/YYYY hh:mm:ss'
																)
															) : (
																<span>N/A</span>
															)}
														</td>
													</tr>
												</tbody>
											</table>
										</div>
									</div>
									<div className="col-md-6 col-12">
										<table className="table table-bordered">
											<tbody>
												<tr>
													<td className="first__column">Payment Status</td>
													<td>
														{requestListViewDetailList ? (
															requestListViewDetailList?.paymentStatus
														) : (
															<span>N/A</span>
														)}
													</td>
												</tr>
												<tr>
													<td className="first__column">Delivery Status</td>
													<td>
														{requestListViewDetailList ? (
															requestListViewDetailList?.deliveryStatus
														) : (
															<span>N/A</span>
														)}
													</td>
												</tr>

												<tr>
													<td className="first__column">Created On</td>
													<td>
														{requestListViewDetailList ? (
															dayjs(requestListViewDetailList?.createdOn).format(
																'MM/DD/YYYY hh:mm:ss'
															)
														) : (
															<span>N/A</span>
														)}
													</td>
												</tr>
											</tbody>
										</table>
									</div>
									<div className="col-md-6 col-12 mt-5">
										<div className="table-responsive">
											<table className="table table-bordered">
												<tbody>
													<tr>
														<td className="first__column">Primary Identifier</td>
														<td>
															{requestListViewDetailList &&
																requestListViewDetailList.primaryIdentifier}
														</td>
													</tr>
													<tr>
														<td className="first__column">Parameter</td>
														<td>
															{requestListViewDetailList && (
																<pre className="pre">{JSON.stringify(prettyData, null, 4)}</pre>
															)}
														</td>
													</tr>
												</tbody>
											</table>
										</div>
									</div>
									<div className="col-md-6 col-12 mt-5">
										<div className="table-responsive">
											<table className="table table-bordered ">
												<tbody>
													<tr>
														<td className="first__column">Service Charge</td>
														<td>
															{prettyData?.ServiceChargeAmount && 'Rs.'}
															{prettyData?.ServiceChargeAmount || 'N/A'}
														</td>
													</tr>
													{(requestListViewDetailList?.serviceName === 'KhanePani' ||
														requestListViewDetailList?.serviceName === 'Electricity') && (
														<tr>
															<td className="first__column">Bill Amount</td>

															<td>
																{prettyData?.Amount && 'Rs.'}
																{parseInt(prettyData?.Amount) -
																	parseInt(prettyData?.ServiceChargeAmount) || 'N/A'}
															</td>
														</tr>
													)}
													<tr>
														<td className="first__column">Total Amount</td>
														<td>
															{prettyData?.Amount && 'Rs.'}
															{prettyData?.Amount || 'N/A'}
														</td>
													</tr>
												</tbody>
											</table>
										</div>
									</div>
								</div>
							) : (
								<div className="d-flex align-items-center justify-content-center mt-5">
									<h2 className="my-5">No data found</h2>
								</div>
							)}
						</Tab>
						<Tab eventKey="payment-logs" title="Payment Logs">
							<div className="table-responsive">
								{!paymentLog || paymentLog?.length === 0 ? (
									<div className="px-3">No data found</div>
								) : (
									<table className="table table-striped">
										<thead>
											<tr>
												<th className="text-nowrap">S.N</th>
												<th className="text-nowrap">Status</th>
												<th className="text-nowrap">Parameters</th>
												<th className="text-nowrap">Created On</th>
											</tr>
										</thead>
										<tbody>
											{paymentLog?.length !== 0 &&
												paymentLog
													?.sort((a: any, b: any) => (b.creationTime < a.creationTime ? -1 : 1))
													?.map((e: any, i: number) => (
														<tr key={i}>
															<td> {i + 1} </td>
															<td> {e?.systemName.toLocaleUpperCase()} </td>
															<td> {e?.logParameter}</td>
															<td> {dayjs(e?.creationTime).format('MM/DD/YYYY hh:mm:ss')}</td>
														</tr>
													))}
										</tbody>
									</table>
								)}
							</div>
						</Tab>
						<Tab eventKey="delivery-logs" title="Delivery Logs">
							<div className="table-responsive">
								{!deliveryLog || deliveryLog?.length === 0 ? (
									<div className="px-3">No data found</div>
								) : (
									<table className="table table-striped">
										<thead>
											<tr>
												<th className="text-nowrap">S.N</th>
												<th className="text-nowrap">Status</th>
												<th className="text-nowrap">Parameter</th>
												<th className="text-nowrap">Created On</th>
											</tr>
										</thead>
										<tbody>
											{deliveryLog?.length !== 0 &&
												deliveryLog?.map((e: any, i: number) => (
													<tr key={i}>
														<td>{i + 1} </td>
														<td>{e?.displayName.toLocaleUpperCase()} </td>

														<td>
															{e.logParameter ? JSON.stringify(e?.logParameter, null, 2) : 'N/A'}{' '}
														</td>

														<td> {dayjs(e?.creationTime).format('MM/DD/YYYY hh:mm:ss')}</td>
													</tr>
												))}
										</tbody>
									</table>
								)}
							</div>
						</Tab>
						<Tab eventKey="ipg-logs" title="IPG Logs">
							{ipgLog?.transactionReportDetailLogDTOList ? (
								<div className="table-responsive">
									<table className="table table-striped">
										<thead>
											<tr>
												<th className="text-nowrap">S.N</th>
												<th className="text-nowrap">Created on</th>
												<th className="text-nowrap">Status</th>
											</tr>
										</thead>
										<tbody>
											{ipgLog?.transactionReportDetailLogDTOList?.length !== 0 &&
												ipgLog?.transactionReportDetailLogDTOList
													?.sort((a: any, b: any) => (b.timestamp < a.timestamp ? -1 : 1))
													?.map((e: any, i: number) => (
														<tr key={i}>
															<td>{i + 1} </td>
															<td> {dayjs(e?.timestamp).format('MM/DD/YYYY hh:mm:ss')}</td>
															<td>{e?.transactionStatus} </td>
														</tr>
													))}
										</tbody>
									</table>
								</div>
							) : (
								<div className="px-3">No data found</div>
							)}
							{ipgLog?.transactionReportDetailDTOList && (
								<div className="table-responsive">
									<table className="table ">
										<thead>
											<tr>
												<th className="text-nowrap">S.N</th>
												<th className="text-nowrap">Transaction Id</th>
												<th className="text-nowrap">Message</th>
												<th className="text-nowrap">Activity Date</th>
											</tr>
										</thead>
										<tbody>
											{ipgLog?.transactionReportDetailDTOList
												?.sort((a: any, b: any) => (b.activityDate < a.activityDate ? -1 : 1))
												?.map((e: any, i: number) => (
													<tr key={i}>
														<td>{i + 1} </td>
														<td>{e?.transactionId} </td>
														<td>{e?.message} </td>
														<td> {dayjs(e?.activityDate).format('MM/DD/YYYY hh:mm:ss')}</td>
													</tr>
												))}
										</tbody>
									</table>
								</div>
							)}
						</Tab>
					</Tabs>
				</TabsContainer>
			</div>
		</div>
	);
}
