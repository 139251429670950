import React from 'react';
import { Collapse, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { AiOutlineAudit, AiOutlineGroup } from 'react-icons/ai';
import { BiGitPullRequest, BiUserCircle } from 'react-icons/bi';
import { BsMenuButton, BsPersonLock } from 'react-icons/bs';
import { CgMenuRight } from 'react-icons/cg';
import { FaAngleDown, FaAngleRight, FaRegWindowMaximize } from 'react-icons/fa';
import { FiList } from 'react-icons/fi';
import { LuSettings2 } from 'react-icons/lu';
import { MdOutlineMiscellaneousServices, MdSpaceDashboard } from 'react-icons/md';
import { RiVisaFill } from 'react-icons/ri';
import { useSelector } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';
import styled from 'styled-components';

const BASE_URL = window.__RUNTIME_CONFIG__.REACT_APP_UTILITY_IMAGE_ENDPOINT;

type sidebarCompProps = {
	backgroundColor: string;
	responsive: boolean;
	textColor?: string;
	secondColor?: string;
};

const Sidebar = styled.aside.attrs((props: any) => ({ className: props.className }))`
	background: ${(props: sidebarCompProps) => props.backgroundColor};
	overflow-y: auto;
	width: ${(props: sidebarCompProps) => (props.responsive ? '66px' : '223px')};
	position: fixed;
	top: 0;
	left: 0;
	scroll-behavior: smooth;
	height: 100vh;
	z-index: 999;
	transition: 0.4s;
	svg {
		flex: 0 0 25px;
	}

	.sidebar__list {
		color: ${(props: sidebarCompProps) => props.textColor || '#000000'};
		justify-content: ${(props: sidebarCompProps) => (props.responsive ? 'center' : 'start')};
		display: ${(props: sidebarCompProps) => (props.responsive ? 'grid' : 'flex')};
		position: relative;
		padding: 12px;
		align-items: center;
		white-space: nowrap;
	}
	.sidebar__list.active::before {
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		width: 4px;
		height: 100%;
		background: ${(props: sidebarCompProps) => props.secondColor || '#000000'};
		border-radius: 0 2px 2px 0;
	}
	@media only screen and (max-width: 768px) {
		width: ${(props: sidebarCompProps) => (props.responsive ? '223px' : '0')};
		.sidebar__list {
			display: flex;
			justify-content: ${(props: sidebarCompProps) => (props.responsive ? 'start' : 'start')};

			.child-menu-wrapper {
				flex-direction: column;
				align-items: self-start;
			}
		}
		.responsive__sidebar__list__text {
			display: block !important;
			margin-left: 12px;
			font-weight: 600;
			text-transform: capitalize;
		}
	}
`;

const menus = [
	{
		id: 0,
		icon: MdSpaceDashboard,
		title: 'Dashboard',
		path: '/',
	},
	{
		id: 1,
		icon: BiUserCircle,
		title: 'user management',
		path: '/user-management',
	},

	{
		id: 2,
		icon: BsPersonLock,
		title: 'Role Managment',
		path: '/role-management',
	},
	{ id: 3, icon: BiGitPullRequest, title: 'Request List', path: '/request-list' },
	{
		id: 4,
		icon: AiOutlineAudit,
		title: 'Audit Log',
		path: '/audit-log',
	},
	{
		id: 5,
		icon: RiVisaFill,
		title: 'Visa Direct Log',
		path: '/visadirect-log',
	},
	{
		id: 6,
		icon: MdOutlineMiscellaneousServices,
		title: 'services',
		children: [
			{
				id: 0,
				icon: FaRegWindowMaximize,
				title: 'Balance Limit',
				path: '/services/balance-limit',
			},
			{
				id: 1,
				icon: AiOutlineGroup,
				title: 'Services Group',
				path: '/services/setting-service',
			},
			{
				id: 2,
				icon: FiList,
				title: 'Service-list',
				path: '/services/setting-utilityservice',
			},
		],
	},
	{
		id: 7,
		icon: LuSettings2,
		title: 'Backend Setting',
		path: '/backend-settings',
	},
	{
		id: 8,
		icon: BsMenuButton,
		title: 'Menu Manager',
		path: '/menu-manager',
	},
];

const findActiveIndex = (path: string) => {
	const regex = /^\/([^/]+)\//;
	const match = path.match(regex);
	const url = match ? match[1] : null;

	return menus.findIndex((item) => item.title.toLowerCase() === `${url}`);
};

const SideBar = ({ responsive, setResponsive }: any) => {
	const response = useSelector((state: any) => state.app?.data?.auth?.grantedPolicies);
	const { theming, image } = useSelector((state: any) => state?.theme);

	const filterData = menus.filter(
		(e: any) =>
			e.title === 'Dashboard' ||
			(e.title === 'user management' && response?.BackEnd_Users) ||
			(e.title === 'Audit Log' && response?.BackEnd_AuditLogs) ||
			(e.title === 'Request List' && response?.BackEnd_RequestLists) ||
			(e.title === 'Role Managment' && response?.BackEnd_Roles) ||
			(e.title === 'Visa Direct Log' && response?.BackEnd_VisaDirect) ||
			(e.title === 'Backend Setting' && response?.BackEnd_Themes) ||
			(e.title === 'Menu Manager' && response?.BackEnd_Menus) ||
			(e.title === 'services' && response?.BackEnd_Services)
	);

	return (
		<Sidebar
			backgroundColor={theming?.SidebarBackgroundColor}
			textColor={theming?.SidebarTextColor}
			secondColor={theming?.SecondaryColor}
			responsive={responsive}>
			<div>
				<div className={responsive ? 'responsive__sidebar__logo' : 'sidebar__logo '}>
					{image && (
						<img src={BASE_URL + image.SidebarLogo || ''} alt="logo" className="img-fluid" />
					)}
				</div>

				{responsive && (
					<div className="" onClick={() => setResponsive(!responsive)}>
						<CgMenuRight color="#FFFFFFFF" size={26} className="my-3 mx-3" />
					</div>
				)}

				<ul className="list-unstyled">
					{filterData?.map((menu, i) => {
						return (
							<li key={i}>
								{menu.children ? (
									<MenuWithChildren menu={menu} responsive={responsive} indexs={menu.id} />
								) : (
									<Menu menu={menu} responsive={responsive} />
								)}
							</li>
						);
					})}
				</ul>
			</div>
		</Sidebar>
	);
};

export default React.memo(SideBar);

const Menu = ({ menu, responsive }: any) => {
	const theme = useSelector((state: any) => state.theme.theming);

	return (
		<OverlayTrigger
			delay={{ hide: 100, show: 100 }}
			overlay={(props) => (responsive ? <Tooltip {...props}>{menu.title}</Tooltip> : <></>)}
			placement="right">
			<NavLink to={menu.path} key={menu.id} className="sidebar__list">
				{<menu.icon size={18} color={theme.SidebarTextColor} />}
				<span
					className={`${responsive ? 'responsive__sidebar__list__text' : 'sidebar__list__text'}`}>
					{menu.title}
				</span>
			</NavLink>
		</OverlayTrigger>
	);
};

const MenuWithChildren = ({ menu, responsive, indexs }: any) => {
	const theme = useSelector((state: any) => state.theme);

	const { pathname } = useLocation();
	const [activeIndex, setActiveIndex] = React.useState(() => findActiveIndex(pathname));

	const toggleAccordion = React.useCallback((index: number) => {
		setActiveIndex((prevIndex: number) => (prevIndex === index ? -1 : index));
	}, []);

	React.useEffect(() => {
		const activeIndex = findActiveIndex(pathname);
		setActiveIndex(activeIndex);
	}, [pathname]);
	return (
		<div className={!responsive ? 'w-100' : 'sidebar__list'}>
			<div className="child-menu-wrapper">
				<div
					className={
						responsive
							? 'justify-content-center'
							: 'justify-content-between  align-items-center sidebar__list'
					}
					data-toggle="collapse"
					data-target="#collapseExample"
					onClick={() => toggleAccordion(indexs)}>
					<div className="d-flex align-items-center justify-content-center ">
						<menu.icon size={18} color={theme.SidebarTextColor} className="me-1" />
						<span
							className={`${
								responsive ? 'responsive__sidebar__list__text' : 'sidebar__list__text'
							}`}>
							{menu.title}
						</span>
					</div>
					{!responsive && <div>{activeIndex !== indexs ? <FaAngleRight /> : <FaAngleDown />}</div>}
				</div>
				<Collapse in={activeIndex == indexs ? true : false}>
					<div className={`child-menu`} id="collapseExample">
						{menu.children.map((item: any, i: number) => {
							return (
								<div className="d-flex flex-column w-100" key={i}>
									<OverlayTrigger
										delay={{ hide: 100, show: 100 }}
										overlay={(props) =>
											responsive ? <Tooltip {...props}>{item.title}</Tooltip> : <></>
										}
										placement="right">
										<NavLink to={item.path} className="sidebar__list">
											{<item.icon size={16} color={theme.SidebarTextColor} />}
											<span
												className={`${
													responsive ? 'responsive__sidebar__list__text' : 'sidebar__list__text'
												}`}>
												{item.title}
											</span>
										</NavLink>
									</OverlayTrigger>
								</div>
							);
						})}
					</div>
				</Collapse>
			</div>
		</div>
	);
};
