import { LogoutConstant } from 'redux/constant/auth.constant';
import { appConstant } from '../constant/app.constant';

const initState = {
	isLoading: false,
	data: {},
	error: '',
};

export const appReducer = (state = initState, { type, payload }: any) => {
	switch (type) {
		case appConstant.ADMIN_CORE_UTILITY_REQUSET:
			state = {
				...state,
				isLoading: true,
			};
			break;
		case appConstant.ADMIN_CORE_UTILITY_SUCCESS:
			state = {
				...state,
				isLoading: false,
				data: payload,
			};

			break;
		case appConstant.ADMIN_CORE_UTILITY_FAILED:
			state = {
				...state,
				isLoading: false,
				error: 'error',
			};
			break;
		case LogoutConstant:
			state = {
				...state,
				isLoading: false,
				data: {},
			};
			break;
	}
	return state;
};
