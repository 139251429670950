import { combineReducers } from 'redux';
import { authReducer } from './auth.reducer';
import { appReducer } from './app.reducer';
import { themeReducer } from './theme.reducer';

const rootReducers = combineReducers({
	auth: authReducer,
	app: appReducer,
	theme: themeReducer,
});

export default rootReducers;
