import React from 'react';

interface Iprops {
	file: any;
}

const PreviewImage: React.FC<Iprops> = ({ file }) => {
	const [preview, setPreview] = React.useState<any>(null);
	const reader = new FileReader();
	reader.readAsDataURL(file);
	reader.onload = () => setPreview(reader.result);
	return (
		<div className="d-flex align-items-center justify-content-center">
			<img src={preview} alt="preview" width={200} height={100} />
		</div>
	);
};

export default PreviewImage;
