/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Amnil_UtilityServiceBackEnd_UtilityServiceRequest_Dtos_DeliveryLogDetailsDto } from '../models/Amnil_UtilityServiceBackEnd_UtilityServiceRequest_Dtos_DeliveryLogDetailsDto';
import type { Amnil_UtilityServiceBackEnd_UtilityServiceRequest_Dtos_DeliveryStatusDto } from '../models/Amnil_UtilityServiceBackEnd_UtilityServiceRequest_Dtos_DeliveryStatusDto';
import type { Amnil_UtilityServiceBackEnd_UtilityServiceRequest_Dtos_PaymentLogDetailsDto } from '../models/Amnil_UtilityServiceBackEnd_UtilityServiceRequest_Dtos_PaymentLogDetailsDto';
import type { Amnil_UtilityServiceBackEnd_UtilityServiceRequest_Dtos_PaymentStatusDto } from '../models/Amnil_UtilityServiceBackEnd_UtilityServiceRequest_Dtos_PaymentStatusDto';
import type { Amnil_UtilityServiceBackEnd_UtilityServiceRequest_Dtos_ServiceLogDetailsDto } from '../models/Amnil_UtilityServiceBackEnd_UtilityServiceRequest_Dtos_ServiceLogDetailsDto';
import type { Amnil_UtilityServiceBackEnd_UtilityServiceRequest_Dtos_ThirdPartyGateWayDto } from '../models/Amnil_UtilityServiceBackEnd_UtilityServiceRequest_Dtos_ThirdPartyGateWayDto';
import type { Amnil_UtilityServiceBackEnd_UtilityServiceRequest_Dtos_TransactionReportDetailCombined } from '../models/Amnil_UtilityServiceBackEnd_UtilityServiceRequest_Dtos_TransactionReportDetailCombined';
import type { Amnil_UtilityServiceBackEnd_UtilityServiceRequest_Dtos_UtilityServiceBalanceDto } from '../models/Amnil_UtilityServiceBackEnd_UtilityServiceRequest_Dtos_UtilityServiceBalanceDto';
import type { Amnil_UtilityServiceBackEnd_UtilityServiceRequest_Dtos_UtilityServiceDto } from '../models/Amnil_UtilityServiceBackEnd_UtilityServiceRequest_Dtos_UtilityServiceDto';
import type { Amnil_UtilityServiceBackEnd_UtilityServiceRequest_Dtos_UtilityServiceGroupDto } from '../models/Amnil_UtilityServiceBackEnd_UtilityServiceRequest_Dtos_UtilityServiceGroupDto';
import type { Amnil_UtilityServiceBackEnd_UtilityServiceRequest_Dtos_UtilityServicesDto } from '../models/Amnil_UtilityServiceBackEnd_UtilityServiceRequest_Dtos_UtilityServicesDto';
import type { Amnil_UtilityServiceBackEnd_VisaDirect_VisaDirectListDto } from '../models/Amnil_UtilityServiceBackEnd_VisaDirect_VisaDirectListDto';
import type { Amnil_UtilityServiceBackEnd_VisaDirect_VisaDirectOrderStatusDto } from '../models/Amnil_UtilityServiceBackEnd_VisaDirect_VisaDirectOrderStatusDto';
import type { Volo_Abp_Application_Dtos_PagedResultDto_1 } from '../models/Volo_Abp_Application_Dtos_PagedResultDto_1';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';
import { Amnil_UtilityServiceBackEnd_UtilityServiceRequest_Dtos_UtilityServiceServiceRequestChargeDto } from '../models/Amnil_UtilityServiceBackEnd_UtilityServiceRequest_Dtos_UtilityServiceServiceRequestChargeDto';

export class UtilityServiceRequestService {

    /**
     * @param skip
     * @param take
     * @param searchValue
     * @param serviceName
     * @param groupName
     * @param requestNumber
     * @param fromDate
     * @param toDate
     * @param primaryIdentifier
     * @param paymentGateway
     * @param paymentStatus
     * @param deliveryStatus
     * @returns Volo_Abp_Application_Dtos_PagedResultDto_1<Amnil_UtilityServiceBackEnd_UtilityServiceRequest_Dtos_UtilityServiceRequestListDto_Amnil_UtilityServiceBackEnd_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null_> Success
     * @throws ApiError
     */
    public static getUtilityServiceRequest(
        skip?: number,
        take?: number,
        serviceName?: string,
        groupName?: string,
        requestNumber?: string,
        fromDate?: string,
        toDate?: string,
        primaryIdentifier?: string,
        paymentGateway?: string,
        paymentStatus?: string,
        deliveryStatus?: string,
    ): CancelablePromise<Volo_Abp_Application_Dtos_PagedResultDto_1> {
        return __request({
            method: 'GET',
            path: `/admin-api/api/app/utility-service-request/request-list-data`,
            query: {
                'Skip': skip,
                'Take': take,
                'ServiceName': serviceName,
                'GroupName': groupName,
                'RequestNumber': requestNumber,
                'FromDate': fromDate,
                'ToDate': toDate,
                'PrimaryIdentifier': primaryIdentifier,
                'PaymentGateway': paymentGateway,
                'PaymentStatus': paymentStatus,
                'DeliveryStatus': deliveryStatus,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Amnil_UtilityServiceBackEnd_UtilityServiceRequest_Dtos_ThirdPartyGateWayDto Success
     * @throws ApiError
     */
    public static getUtilityServiceRequest1(): CancelablePromise<Array<Amnil_UtilityServiceBackEnd_UtilityServiceRequest_Dtos_ThirdPartyGateWayDto>> {
        return __request({
            method: 'GET',
            path: `/admin-api/api/app/utility-service-request/third-party-payment-gate-way`,
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Amnil_UtilityServiceBackEnd_UtilityServiceRequest_Dtos_UtilityServiceDto Success
     * @throws ApiError
     */
    public static getUtilityServiceRequest2(): CancelablePromise<Array<Amnil_UtilityServiceBackEnd_UtilityServiceRequest_Dtos_UtilityServiceDto>> {
        return __request({
            method: 'GET',
            path: `/admin-api/api/app/utility-service-request/utility-service`,
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param utilityServiceRequestId
     * @returns Amnil_UtilityServiceBackEnd_UtilityServiceRequest_Dtos_ServiceLogDetailsDto Success
     * @throws ApiError
     */
    public static getUtilityServiceRequest3(
        utilityServiceRequestId: string,
    ): CancelablePromise<Amnil_UtilityServiceBackEnd_UtilityServiceRequest_Dtos_ServiceLogDetailsDto> {
        return __request({
            method: 'GET',
            path: `/admin-api/api/app/utility-service-request/view-details/${utilityServiceRequestId}`,
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Amnil_UtilityServiceBackEnd_UtilityServiceRequest_Dtos_UtilityServiceDto Success
     * @throws ApiError
     */
    public static getUtilityServiceRequest4(): CancelablePromise<Array<Amnil_UtilityServiceBackEnd_UtilityServiceRequest_Dtos_UtilityServiceDto>> {
        return __request({
            method: 'GET',
            path: `/admin-api/api/app/utility-service-request/utility-services`,
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Amnil_UtilityServiceBackEnd_UtilityServiceRequest_Dtos_PaymentStatusDto Success
     * @throws ApiError
     */
    public static getUtilityServiceRequest5(): CancelablePromise<Array<Amnil_UtilityServiceBackEnd_UtilityServiceRequest_Dtos_PaymentStatusDto>> {
        return __request({
            method: 'GET',
            path: `/admin-api/api/app/utility-service-request/payment-status`,
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Amnil_UtilityServiceBackEnd_UtilityServiceRequest_Dtos_DeliveryStatusDto Success
     * @throws ApiError
     */
    public static getUtilityServiceRequest6(): CancelablePromise<Array<Amnil_UtilityServiceBackEnd_UtilityServiceRequest_Dtos_DeliveryStatusDto>> {
        return __request({
            method: 'GET',
            path: `/admin-api/api/app/utility-service-request/delivery-status`,
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param serviceName
     * @param groupName
     * @param requestNumber
     * @param fromDate
     * @param toDate
     * @param primaryIdentifier
     * @param paymentGateway
     * @param paymentStatus
     * @param deliveryStatus
     * @returns string Success
     * @throws ApiError
     */
    public static getUtilityServiceRequest7(
        serviceName?: string,
        groupName?: string,
        requestNumber?: string,
        fromDate?: string,
        toDate?: string,
        primaryIdentifier?: string,
        paymentGateway?: string,
        paymentStatus?: string,
        deliveryStatus?: string,
    ): CancelablePromise<string> {
        return __request({
            method: 'GET',
            path: `/admin-api/api/app/utility-service-request/export-request-detail-to-excel`,
            query: {
                'ServiceName': serviceName,
                'GroupName': groupName,
                'RequestNumber': requestNumber,
                'FromDate': fromDate,
                'ToDate': toDate,
                'PrimaryIdentifier': primaryIdentifier,
                'PaymentGateway': paymentGateway,
                'PaymentStatus': paymentStatus,
                'DeliveryStatus': deliveryStatus,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param serviceName
     * @param groupName
     * @param requestNumber
     * @param fromDate
     * @param toDate
     * @param primaryIdentifier
     * @param paymentGateway
     * @param paymentStatus
     * @param deliveryStatus
     * @returns void
     * @throws ApiError
     */
    public static getUtilityServiceRequest8(
        serviceName?: string,
        groupName?: string,
        requestNumber?: string,
        fromDate?: string,
        toDate?: string,
        primaryIdentifier?: string,
        paymentGateway?: string,
        paymentStatus?: string,
        deliveryStatus?: string,
    ): CancelablePromise<void> {
        return __request({
            method: 'GET',
            path: `/admin-api/api/app/utility-service-request/send-detail-to-excel`,
            query: {
                'ServiceName': serviceName,
                'GroupName': groupName,
                'RequestNumber': requestNumber,
                'FromDate': fromDate,
                'ToDate': toDate,
                'PrimaryIdentifier': primaryIdentifier,
                'PaymentGateway': paymentGateway,
                'PaymentStatus': paymentStatus,
                'DeliveryStatus': deliveryStatus,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param utilityServiceRequestId
     * @returns Amnil_UtilityServiceBackEnd_UtilityServiceRequest_Dtos_PaymentLogDetailsDto Success
     * @throws ApiError
     */
    public static getUtilityServiceRequest9(
        utilityServiceRequestId: string,
    ): CancelablePromise<Array<Amnil_UtilityServiceBackEnd_UtilityServiceRequest_Dtos_PaymentLogDetailsDto>> {
        return __request({
            method: 'GET',
            path: `/admin-api/api/app/utility-service-request/payment-log/${utilityServiceRequestId}`,
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param utilityServiceRequestId
     * @returns Amnil_UtilityServiceBackEnd_UtilityServiceRequest_Dtos_DeliveryLogDetailsDto Success
     * @throws ApiError
     */
    public static getUtilityServiceRequest10(
        utilityServiceRequestId: string,
    ): CancelablePromise<Array<Amnil_UtilityServiceBackEnd_UtilityServiceRequest_Dtos_DeliveryLogDetailsDto>> {
        return __request({
            method: 'GET',
            path: `/admin-api/api/app/utility-service-request/delivery-log/${utilityServiceRequestId}`,
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param utilityServiceRequestId
     * @returns Amnil_UtilityServiceBackEnd_UtilityServiceRequest_Dtos_TransactionReportDetailCombined Success
     * @throws ApiError
     */
    public static getUtilityServiceRequest11(
        utilityServiceRequestId: string,
    ): CancelablePromise<Amnil_UtilityServiceBackEnd_UtilityServiceRequest_Dtos_TransactionReportDetailCombined> {
        return __request({
            method: 'GET',
            path: `/admin-api/api/app/utility-service-request/i-pGLog/${utilityServiceRequestId}`,
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Amnil_UtilityServiceBackEnd_UtilityServiceRequest_Dtos_UtilityServiceBalanceDto Success
     * @throws ApiError
     */
    public static getUtilityServiceRequest12(): CancelablePromise<Array<Amnil_UtilityServiceBackEnd_UtilityServiceRequest_Dtos_UtilityServiceBalanceDto>> {
        return __request({
            method: 'GET',
            path: `/admin-api/api/app/utility-service-request/utility-service-amount-range`,
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param id
     * @param minimumBalance
     * @param maximumBalance
     * @returns boolean Success
     * @throws ApiError
     */
    public static putUtilityServiceRequest(
        id: string,
        minimumBalance?: number,
        maximumBalance?: number,
    ): CancelablePromise<boolean> {
        return __request({
            method: 'PUT',
            path: `/admin-api/api/app/utility-service-request/amount-range/${id}`,
            query: {
                'minimumBalance': minimumBalance,
                'maximumBalance': maximumBalance,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param utilityServiceGroupIdentifier
     * @returns Amnil_UtilityServiceBackEnd_UtilityServiceRequest_Dtos_UtilityServiceGroupDto Success
     * @throws ApiError
     */
    public static getUtilityServiceRequest13(
        utilityServiceGroupIdentifier?: string,
    ): CancelablePromise<Array<Amnil_UtilityServiceBackEnd_UtilityServiceRequest_Dtos_UtilityServiceGroupDto>> {
        return __request({
            method: 'GET',
            path: `/admin-api/api/app/utility-service-request/utility-service-group`,
            query: {
                'utilityServiceGroupIdentifier': utilityServiceGroupIdentifier,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param utilityServiceGroupIdentifier
     * @returns Amnil_UtilityServiceBackEnd_UtilityServiceRequest_Dtos_UtilityServicesDto Success
     * @throws ApiError
     */
    public static getUtilityServiceRequest14(
        utilityServiceGroupIdentifier: string,
    ): CancelablePromise<Array<Amnil_UtilityServiceBackEnd_UtilityServiceRequest_Dtos_UtilityServicesDto>> {
        return __request({
            method: 'GET',
            path: `/admin-api/${utilityServiceGroupIdentifier}`,
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param skip
     * @param take
     * @param searchValue
     * @param mobileNumber
     * @param fromDate
     * @param toDate
     * @param orderStatus
     * @returns Volo_Abp_Application_Dtos_PagedResultDto_1<Amnil_UtilityServiceBackEnd_VisaDirect_VisaDirectListDto_Amnil_UtilityServiceBackEnd_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null_> Success
     * @throws ApiError
     */
    public static getUtilityServiceRequest15(
        skip?: number,
        take?: number,
        mobileNumber?: string,
        fromDate?: string,
        toDate?: string,
        orderStatus?: string,
    ): CancelablePromise<Volo_Abp_Application_Dtos_PagedResultDto_1> {
        return __request({
            method: 'GET',
            path: `/admin-api/api/app/utility-service-request/visa-direct-request-list`,
            query: {
                'Skip': skip,
                'Take': take,
                'MobileNumber': mobileNumber,
                'FromDate': fromDate,
                'ToDate': toDate,
                'OrderStatus': orderStatus,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param orderId
     * @returns Amnil_UtilityServiceBackEnd_VisaDirect_VisaDirectListDto Success
     * @throws ApiError
     */
    public static getUtilityServiceRequest16(
        orderId: string,
    ): CancelablePromise<Amnil_UtilityServiceBackEnd_VisaDirect_VisaDirectListDto> {
        return __request({
            method: 'GET',
            path: `/admin-api/api/app/utility-service-request/visa-direct-detail/${orderId}`,
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns Amnil_UtilityServiceBackEnd_VisaDirect_VisaDirectOrderStatusDto Success
     * @throws ApiError
     */
    public static postUtilityServiceRequest(): CancelablePromise<Array<Amnil_UtilityServiceBackEnd_VisaDirect_VisaDirectOrderStatusDto>> {
        return __request({
            method: 'POST',
            path: `/admin-api/api/app/utility-service-request/visa-direct-order-status`,
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param mobileNumber
     * @param fromDate
     * @param toDate
     * @param orderStatus
     * @returns void
     * @throws ApiError
     */
    public static getUtilityServiceRequest17(
        mobileNumber?: string,
        fromDate?: string,
        toDate?: string,
        orderStatus?: string,
    ): CancelablePromise<void> {
        return __request({
            method: 'GET',
            path: `/admin-api/api/app/utility-service-request/send-visa-direct-to-excel`,
            query: {
                'MobileNumber': mobileNumber,
                'FromDate': fromDate,
                'ToDate': toDate,
                'OrderStatus': orderStatus,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param serviceGroupId
     * @returns boolean Success
     * @throws ApiError
     */
    public static postUtilityServiceRequest1(
        serviceGroupId: string,
    ): CancelablePromise<boolean> {
        return __request({
            method: 'POST',
            path: `/admin-api/api/app/utility-service-request/enable-disable-service-group/${serviceGroupId}`,
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param serviceId
     * @returns boolean Success
     * @throws ApiError
     */
    public static postUtilityServiceRequest2(
        serviceId: string,
    ): CancelablePromise<boolean> {
        return __request({
            method: 'POST',
            path: `/admin-api/api/app/utility-service-request/enable-disable-service/${serviceId}`,
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param name
     * @returns Amnil_UtilityServiceBackEnd_UtilityServiceRequest_Dtos_UtilityServiceGroupDto Success
     * @throws ApiError
     */
    public static getUtilityServiceRequest18(
        name?: string,
    ): CancelablePromise<Array<Amnil_UtilityServiceBackEnd_UtilityServiceRequest_Dtos_UtilityServiceGroupDto>> {
        return __request({
            method: 'GET',
            path: `/admin-api/api/app/utility-service-request/service-group`,
            query: {
                'name': name,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param utilityServiceRequestId
     * @returns Amnil_UtilityServiceBackEnd_UtilityServiceRequest_Dtos_UtilityServiceGroupDto Success
     * @throws ApiError
     */
    public static getUtilityServiceRequest19(
        utilityServiceRequestId?: string,
    ): CancelablePromise<Amnil_UtilityServiceBackEnd_UtilityServiceRequest_Dtos_UtilityServiceServiceRequestChargeDto> {
        return __request({
            method: 'GET',
            path: `/admin-api/api/app/utility-service-request/service-charge/${utilityServiceRequestId}`,
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }
    /**
     * @param utilityServiceRequestId
     * @returns Amnil_UtilityServiceBackEnd_UtilityServiceRequest_Dtos_UtilityServiceGroupDto Success
     * @throws ApiError
     */
    public static getUtilityServiceRequest20(
        skip?: number,
        take?: number,
        searchValue?: string,
    ): CancelablePromise<any> {
        return __request({
            method: 'GET',
            path: `/admin-api/api/app/utility-service-request/utility-services-list`,
            query: {
                'Skip': skip,
                'Take': take,
                'searchValue': searchValue,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }
}