/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Amnil_UtilityServiceBackEnd_ServiceChargeManagement_Dto_ServiceChargeRuleDto } from '../models/Amnil_UtilityServiceBackEnd_ServiceChargeManagement_Dto_ServiceChargeRuleDto';
import type { Amnil_UtilityServiceBackEnd_ServiceChargeManagement_Dto_UtilityServiceServiceChargeCreateUpdateDto } from '../models/Amnil_UtilityServiceBackEnd_ServiceChargeManagement_Dto_UtilityServiceServiceChargeCreateUpdateDto';
import type { Amnil_UtilityServiceBackEnd_ServiceChargeManagement_Dto_UtilityServiceServiceChargeDto } from '../models/Amnil_UtilityServiceBackEnd_ServiceChargeManagement_Dto_UtilityServiceServiceChargeDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class ServiceChargeService {

    /**
     * @returns Amnil_UtilityServiceBackEnd_ServiceChargeManagement_Dto_ServiceChargeRuleDto Success
     * @throws ApiError
     */
    public static getServiceCharge(): CancelablePromise<Array<Amnil_UtilityServiceBackEnd_ServiceChargeManagement_Dto_ServiceChargeRuleDto>> {
        return __request({
            method: 'GET',
            path: `/admin-api/api/app/service-charge/service-charge-rule`,
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param utilityServiceId 
     * @returns Amnil_UtilityServiceBackEnd_ServiceChargeManagement_Dto_UtilityServiceServiceChargeDto Success
     * @throws ApiError
     */
    public static getServiceCharge1(
utilityServiceId: string,
): CancelablePromise<Array<Amnil_UtilityServiceBackEnd_ServiceChargeManagement_Dto_UtilityServiceServiceChargeDto>> {
        return __request({
            method: 'GET',
            path: `/admin-api/api/app/service-charge/utility-service-service-charge/${utilityServiceId}`,
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns Amnil_UtilityServiceBackEnd_ServiceChargeManagement_Dto_UtilityServiceServiceChargeDto Success
     * @throws ApiError
     */
    public static putServiceCharge(
requestBody?: Amnil_UtilityServiceBackEnd_ServiceChargeManagement_Dto_UtilityServiceServiceChargeCreateUpdateDto,
): CancelablePromise<Array<Amnil_UtilityServiceBackEnd_ServiceChargeManagement_Dto_UtilityServiceServiceChargeDto>> {
        return __request({
            method: 'PUT',
            path: `/admin-api/api/app/service-charge/utility-service-service-charge`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

}