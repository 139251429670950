import { GrPowerReset } from 'react-icons/gr';
import { RiFileExcelFill } from 'react-icons/ri';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import Button from 'components/Button/Button';

type Props = {
	handleReset?: any;
	handleExportToExcel?: any;
	isExportLoading?: boolean;
	buttonDisabled?: boolean;
};

const ButtonHeader = styled.button`
	background: ${(props) => props.theme.PrimaryColor};
	transition: 0.2s;
	padding: 5px 10px;
	&:hover {
		transition: 0.2s;
		background: ${(props) => props.theme.PrimaryColor + 'b5'};
		color: #fff;
	}
`;

export default function FilterHeader({
	handleExportToExcel,
	handleReset,
	isExportLoading,
	buttonDisabled,
}: Props) {
	const theme = useSelector((state: any) => state.theme);
	return (
		<div className="d-flex justify-content-between">
			<div>
				{handleExportToExcel && (
					<Button
						type="button"
						title="Export To Excel"
						onClick={handleExportToExcel}
						disabled={buttonDisabled}
						loading={isExportLoading}>
						<RiFileExcelFill color="#fff" /> &nbsp; Export
					</Button>
				)}
				{handleReset && (
					<ButtonHeader
						className="reset__button"
						type="reset"
						title="Reset"
						onClick={handleReset}
						theme={theme}>
						<GrPowerReset />
					</ButtonHeader>
				)}
			</div>
		</div>
	);
}
