/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Amnil_UtilityServiceBackEnd_FileManagement_Dtos_DocumentDto } from '../models/Amnil_UtilityServiceBackEnd_FileManagement_Dtos_DocumentDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class DocumentService {

    /**
     * @param utilityServiceId
     * @param formData
     * @returns Amnil_UtilityServiceBackEnd_FileManagement_Dtos_DocumentDto Success
     * @throws ApiError
     */
    public static postDocument(
        utilityServiceId: string,
        formData?: {
            Banner?: Array<Blob>;
        },
    ): CancelablePromise<Array<Amnil_UtilityServiceBackEnd_FileManagement_Dtos_DocumentDto>> {
        return __request({
            method: 'POST',
            path: `/admin-api/api/app/document/upload/${utilityServiceId}`,
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param id
     * @returns boolean Success
     * @throws ApiError
     */
    public static deleteDocument(
        id: string,
    ): CancelablePromise<boolean> {
        return __request({
            method: 'DELETE',
            path: `/admin-api/api/app/document/${id}/file`,
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param utilityServiceId
     * @returns void
     * @throws ApiError
     */
    public static getDocument(
        utilityServiceId: string,
    ): CancelablePromise<void> {
        return __request({
            method: 'GET',
            path: `/admin-api/api/app/document/file/${utilityServiceId}`,
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

}