import { axiosConfig } from 'utils/interceptors';
import { OpenAPI } from '../../services/utility-core-services';
import { authConstant, LogoutConstant, userConstant } from '../constant/auth.constant';
import axios from 'axios';

OpenAPI.BASE = window.__RUNTIME_CONFIG__.REACT_APP_AUTH_URL;

const axiosLogin = axios.create({
	baseURL: OpenAPI.BASE,
	headers: {
		'cache-control': 'no-cache',
		'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
	},
});

export const authAction = (user: any) => {
	return async (dispatch: any) => {
		dispatch({
			type: authConstant.LOGIN_REQUSET,
		});
		try {
			const { data } = await axiosLogin.post('/connect/token', user);
			const accessToken = data.access_token;
			dispatch({
				type: authConstant.LOGIN_SUCCESS,
				payload: accessToken,
			});
		} catch (err: any) {
			dispatch({
				type: authConstant.LOGIN_FAILED,
				payload: err?.response?.data.error_description,
			});
		}
	};
};
export const getUser = () => {
	return async (dispatch: any) => {
		dispatch({
			type: userConstant.USER_REQUSET,
		});
		try {
			const res = await axiosConfig.get('/admin-api/api/identity/my-profile');
			dispatch({
				type: userConstant.USER_SUCCESS,
				payload: res?.data,
			});
		} catch (err: any) {
			dispatch({
				type: userConstant.USER_FAILED,
			});
		}
	};
};
export const isUserLoggedIn = () => {
	return async (dispatch: any) => {
		const accessToken = window.localStorage.getItem('accessToken');
		if (accessToken) {
			dispatch({
				type: authConstant.LOGIN_SUCCESS,
				payload: accessToken,
			});
		}
	};
};
export const LogoutAction = () => {
	return async (dispatch: any) => {
		window.localStorage.clear();
		dispatch({
			type: LogoutConstant,
			payload: 'Logout Successfull',
		});
	};
};
