import React, { Suspense } from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import {
	ChangePassword,
	Login,
	MenuManagement,
	PageNotFound,
	Service,
	Setting,
	VisaDirect,
} from './pages';

import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PrivateRoute from './HOC/PrivateRoute';
import { getUser, isUserLoggedIn } from './redux/Action/auth.action';

import Spinner from 'components/Spinner';
import TransactionDetail from 'pages/RequestList/TransactionDetail';
import ServiceList from 'pages/ServiceList';
import VisaTransactionDetail from 'pages/VisaDirect/VisaTransactionDetail';
import { fetchRoles } from 'redux/Action/app.action';
import { getTheme } from 'redux/Action/theme.action';
import styled from 'styled-components';
import ImageUpload from './pages/Setting/ImageUpload';
import { OpenAPI } from './services/utility-core-services';
import getToken from './utils/token';
const Audit = React.lazy(() => import('./pages/AuditLog'));
const Dashbaord = React.lazy(() => import('./pages/Dashboard'));
const UserManagement = React.lazy(() => import('./pages/UserManagement'));
const RoleManagement = React.lazy(() => import('./pages/RoleManagement'));
const ThemeSetting = React.lazy(() => import('./pages/BackendSetting'));
const RequestList = React.lazy(() => import('./pages/RequestList'));

const StyleWrapper = styled.div`
	.form-check-input:checked {
		background-color: ${(props: any) => props.theme.PrimaryColor} !important;
		border-color: ${(props: any) => props.theme.PrimaryColor} !important;
	}
	input[type='checkbox'] {
		accent-color: ${(props: any) => props.theme.PrimaryColor} !important;
	}
	.input-prefix {
		position: absolute;
		left: 0;
		bottom: 0;
		height: 100%;
		background: ${(props: any) => props.theme.PrimaryColor};
		color: #fff;
		padding: 10px 8px;
		border-radius: 3px 0px 0px 3px;
	}
`;

const App = () => {
	const dispatch = useDispatch();
	const auth = useSelector((state: any) => state.auth);
	const token = useSelector((state: any) => state?.auth?.token);
	const { theming } = useSelector((state: any) => state?.theme);
	OpenAPI.BASE = window.__RUNTIME_CONFIG__.REACT_APP_UTILITY_API_ENDPOINT;
	const response = useSelector((state: any) => state.app?.data?.auth?.grantedPolicies);
	//fetch user roles using token

	React.useEffect(() => {
		if (token) dispatch(fetchRoles());
	}, [token]);

	React.useEffect(() => {
		dispatch(getTheme());
	}, []);

	//fetch user profile using token
	React.useEffect(() => {
		if (token) dispatch(getUser());
	}, [token]);

	//logout
	React.useEffect(() => {
		if (!auth.authenticate) {
			dispatch(isUserLoggedIn());
		}
	}, [auth.authenticate]);

	OpenAPI.TOKEN = token || getToken();

	return (
		<StyleWrapper theme={theming}>
			<Router>
				<ToastContainer />
				<Suspense fallback={<Spinner />}>
					<Routes>
						<Route path="/login" element={<Login />} />
						<Route path="/" element={<PrivateRoute />}>
							<Route path="/" element={<Dashbaord />} />
							{response?.BackEnd_Users && (
								<Route path="/user-management" element={<UserManagement />} />
							)}
						</Route>
						<Route path="/" element={<PrivateRoute />}>
							{response?.BackEnd_RequestLists && (
								<Route path="/request-list" element={<RequestList />} />
							)}
						</Route>
						<Route path="/" element={<PrivateRoute />}>
							{response?.BackEnd_RequestLists && (
								<Route
									path="/request-list/transaction-detail/:id"
									element={<TransactionDetail />}
								/>
							)}
						</Route>
						<Route path="/" element={<PrivateRoute />}>
							{response?.BackEnd_Roles && (
								<Route path="/role-management" element={<RoleManagement />} />
							)}
						</Route>
						<Route path="/" element={<PrivateRoute />}>
							{response?.BackEnd_AuditLogs && <Route path="/audit-log" element={<Audit />} />}
						</Route>
						<Route path="/" element={<PrivateRoute />}>
							{response?.BackEnd_VisaDirect && (
								<Route
									path="/visadirect-log/transaction-detail/:id"
									element={<VisaTransactionDetail />}
								/>
							)}
						</Route>
						<Route path="/" element={<PrivateRoute />}>
							{response?.BackEnd_VisaDirect && (
								<Route path="/visadirect-log" element={<VisaDirect />} />
							)}
						</Route>
						<Route path="/" element={<PrivateRoute />}>
							{response?.BackEnd_Services && (
								<Route path="/change-password" element={<ChangePassword />} />
							)}
						</Route>
						<Route path="/" element={<PrivateRoute />}>
							{response?.BackEnd_Services && (
								<Route path="/services/balance-limit" element={<Setting />} />
							)}
						</Route>
						<Route path="/" element={<PrivateRoute />}>
							{response?.BackEnd_Services && (
								<Route path="/services/setting-image-upload" element={<ImageUpload />} />
							)}
						</Route>
						<Route path="/" element={<PrivateRoute />}>
							{response?.BackEnd_Services && (
								<Route path="/services/setting-service" element={<Service />} />
							)}
						</Route>
						<Route path="/" element={<PrivateRoute />}>
							{response?.BackEnd_Services && (
								<Route path="/services/setting-utilityservice" element={<ServiceList />} />
							)}
						</Route>
						<Route path="/" element={<PrivateRoute />}>
							{response?.BackEnd_Menus && (
								<Route path="/menu-manager" element={<MenuManagement />} />
							)}
						</Route>
						<Route path="/" element={<PrivateRoute />}>
							{response?.BackEnd_Themes && (
								<Route path="/backend-settings" element={<ThemeSetting />} />
							)}
						</Route>
						<Route path="*" element={<PageNotFound />} />
					</Routes>
				</Suspense>
			</Router>
		</StyleWrapper>
	);
};

export default App;
