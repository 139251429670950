import { axiosConfig } from 'utils/interceptors';
import { appConstant } from 'redux/constant/app.constant';

export const fetchRoles = () => {
	return async (dispatch: any) => {
		dispatch({
			type: appConstant.ADMIN_CORE_UTILITY_REQUSET,
		});
		try {
			const res = await axiosConfig.get('/admin-api/api/abp/application-configuration');
			const res2 = await axiosConfig.get(
				'/admin-api/api/app/cms-menu-management/cms-menu-data-source'
			);

			const data = {
				...res?.data,
				source: res2?.data,
			};

			dispatch({
				type: appConstant.ADMIN_CORE_UTILITY_SUCCESS,
				payload: data,
			});
		} catch (err: any) {
			dispatch({
				type: appConstant.ADMIN_CORE_UTILITY_FAILED,
			});
		}
	};
};
