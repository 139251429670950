import { TextField } from 'components';
import Button from 'components/Button/Button';
import { Form, Formik, FormikProps } from 'formik';
import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { authAction } from 'redux/Action/auth.action';
import styled from 'styled-components';
import * as Yup from 'yup';
import ReCAPTCHA from 'react-google-recaptcha';
import { RecapchaService } from 'services/utility-core-services';
import { Amnil_UtilityServiceBackEnd_Recapcha_Dtos_RecapchaDto } from 'services/utility-core-services/models/Amnil_UtilityServiceBackEnd_Recapcha_Dtos_RecapchaDto';

interface ValueProps {
	card_number: number;
	bank_branch: string;
	amount: number;
	account_name: string;
	bank: string;
	payment: string;
}

const BASE_IMAGE_URL = window.__RUNTIME_CONFIG__.REACT_APP_UTILITY_IMAGE_ENDPOINT;

const FORM_VALIDATION = Yup.object().shape({
	user_name: Yup.string()
		.min(3, 'User Name must be at least 3 characters long')
		.required('User Name is Required'),
	password: Yup.string()
		.min(6, 'Password must be at least 6 characters long.')
		.required('Password is Required'),
	recaptcha: Yup.string().required('Please verify you are not a robot'),
});

const LoginWrapper = styled.div`
	.welcome__text {
		color: ${(props: { theme: { PrimaryColor: string } }) => props?.theme?.PrimaryColor};
	}
`;

const Login: React.FC = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [showPassword, setShowPassword] = useState(false);
	const [recaptchaKey, setRecaptchaKey] = useState<string | null>(null);
	const theme = useSelector((state: any) => state.theme.theming);

	useEffect(() => {
		// Fetch Recaptcha keys
		RecapchaService.getRecapcha()
			.then((response: Amnil_UtilityServiceBackEnd_Recapcha_Dtos_RecapchaDto) => {
				setRecaptchaKey(response.siteKey || '');
			})
			.catch((error) => {
				console.error('Error fetching Recaptcha key:', error);
			});
	}, []);

	const togglePassword = () => {
		setShowPassword(!showPassword);
	};

	const initValues = {
		client_id: 'UtilityServiceBackEnd_Client',
		grant_type: 'password',
		user_name: '',
		password: '',
		recaptcha: '',
	};

	const handleLoginRequest = (val: any, { setSubmitting }: any) => {
		setSubmitting(true);
		const params = new URLSearchParams();
		params.append('client_id', val.client_id);
		params.append('username', val.user_name);
		params.append('password', val.password);
		params.append('grant_type', initValues.grant_type);
		params.append('client_id', initValues.client_id);
		params.append('recaptcha', val.recaptcha);
		dispatch(authAction(params));

		console.log('Login request sent with:', params.toString());

		setSubmitting(false);
	};

	const { authenticate, isLoading } = useSelector((state: any) => state.auth);
	const authError: any = useSelector((state: any) => state.auth.error);
	const { theming, image } = useSelector((state: any) => state.theme);
	if (authenticate) {
		navigate('/');
	}

	return (
		<LoginWrapper theme={theme}>
			<div className="login__container">
				<div>
					<div className="text-center">
						{image?.SidebarLogo && (
							<img
								className="login__logo img-fluid"
								src={BASE_IMAGE_URL + image?.SidebarLogo}
								alt="DigiBank Logo"
							/>
						)}
					</div>
					<div className={authError ? 'form__error bg-lightgray' : 'bg-lightgray'}>
						<div className="login__box position-relative">
							<h3 className="text-center welcome__text">
								<b>Welcome</b> , {theming.ApplicationName} Payment Aggregator
							</h3>

							<div className="mt-3">
								{authError && <h6 className="text-danger mb-2">{authError}</h6>}
								<Formik
									initialValues={initValues}
									validateOnMount={true}
									validationSchema={FORM_VALIDATION}
									onSubmit={handleLoginRequest}>
									{({ isValid, setFieldValue }: FormikProps<ValueProps>) => (
										<Form className="d-flex flex-column gap-2">
											<TextField
												label="Username"
												type="text"
												placeholder="Enter your user name"
												name="user_name"
											/>
											<div className="position-relative">
												<TextField
													label="Password"
													type={showPassword ? 'text' : 'password'}
													placeholder="Password"
													name="password"
													className="mt-4"
													onClick={togglePassword}
												/>
											</div>

											{recaptchaKey && (
												<ReCAPTCHA
													sitekey={recaptchaKey}
													onChange={(value) => setFieldValue('recaptcha', value)}
													className="mt-4"
												/>
											)}

											<Button type="submit" loading={isLoading} width="100%">
												Login
											</Button>
										</Form>
									)}
								</Formik>
							</div>
						</div>
					</div>
				</div>
			</div>
		</LoginWrapper>
	);
};

export default memo(Login);
