import { Field, Form, Formik } from 'formik';
import { Form as BootstrapForm } from 'react-bootstrap';
import React, { memo, useState } from 'react';
import { Modal } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { CgClose } from 'react-icons/cg';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import { CustomSelect, Header, TextField } from 'components';
import FilterBar from 'components/Table/FilterBar';
import useAdminCoreUtilityApi from 'hooks/useAdminCoreUtilityApi';
import { ServiceChargeService } from 'services/utility-core-services/services/ServiceChargeService';
import { UtilityServiceRequestService } from 'services/utility-core-services/services/UtilityServiceRequestService';
import Spinner from 'components/Spinner';

interface ServiceListDto {
	sn: number;
	name: string;
	systemName: string;
	serviceGroup: string;
	isEnabled: boolean;
	displayOrder: number;
}

interface ServiceCharge {
	index: number;
	serviceChargeName: string;
	serviceChargeValue: number;
	serviceChargeRuleId: string;
	isActive: boolean;
	isServiceChargeInclusiveOfPrincipalAmount: boolean;
	orderNumber: number;
}
const FORM_VALIDATION = Yup.object().shape({
	serviceChargeName: Yup.string().required('This field is required'),
	serviceChargeValue: Yup.number().required('This field is required'),
	serviceChargeRuleId: Yup.string().required('This field is required'),
	isActive: Yup.boolean().required('This field is required'),
});

const ServiceList = () => {
	const [serviceListDetail, setgetServiceList] = useState<ServiceListDto[]>([]);
	const [totalRows, setTotalRows] = React.useState<number>(0);
	const [pageSkip, setPageSkip] = React.useState<number>(0);
	const [pageLimit, setPageLimit] = React.useState<number>(10);
	const [show, setShow] = React.useState(false);
	const [servieRules, setServiceRules] = useState<any>([]);
	const [serviceCharges, setServiceCharges] = useState<ServiceCharge[]>([]);
	const [utilityServiceId, setUtilityServiceId] = useState<string>();
	const [filters, setFilters] = React.useState({
		searchQuery: '',
	});

	const { isLoading, handleRequest: handleFetchServicList } = useAdminCoreUtilityApi();
	const { handleRequest } = useAdminCoreUtilityApi();

	const getServiceList = async () => {
		let take = pageLimit;
		let skip = pageSkip;
		const response: any = await handleFetchServicList(
			UtilityServiceRequestService.getUtilityServiceRequest20(skip, take, filters.searchQuery)
		);

		const calculatedData = response?.items?.map((row: any, index: any) => {
			return {
				...row,
				sn: index + 1 + pageSkip, // Calculate the serial number
			};
		});
		setgetServiceList(calculatedData);
		setTotalRows(response?.totalCount);
	};
	// const filtterServiceList = async (value: any) => {
	// 	let take = pageLimit;
	// 	let skip = pageSkip;
	// 	let searchValue = value;
	// 	const response: any = await handleRequest(
	// 		UtilityServiceRequestService.getUtilityServiceRequest20(skip, take, searchValue)
	// 	);
	// 	setgetServiceList(response?.items);
	// 	setTotalRows(response?.totalCount);
	// };

	const fetchServiceCharges = async (id: string) => {
		let response: any = await handleRequest(ServiceChargeService.getServiceCharge1(id));
		response = response?.map((serviceCharge: any, _index: any) => {
			return {
				...serviceCharge,
				index: _index + 1,
			};
		});
		setServiceCharges(response);
	};

	React.useEffect(() => {
		getServiceList();
	}, [pageLimit, pageSkip, filters]);

	const removeServiceChargeFromList = (index: any) => {
		setServiceCharges(serviceCharges.filter((serviceCharge) => serviceCharge.index !== index));
	};

	const fetchServiceRules = async () => {
		let response: any = await handleRequest(ServiceChargeService.getServiceCharge());
		setServiceRules(response);
	};

	const filterValue = servieRules?.map((e: { name: string; id: string }) => {
		return {
			label: e.name,
			value: e.id,
		};
	});
	function getRuleNameById(id: string) {
		let service = servieRules.filter((s: any) => s.id === id)[0];
		return service.name;
	}

	const newColumns = [
		{
			name: 'S.N.',
			maxWidth: '80px',
			minWidth: '80px',
			selector: (_row: any) => _row.index,
		},
		{ name: 'Charge Name', selector: (row: any) => row.serviceChargeName },
		{ name: 'Value', selector: (row: any) => row.serviceChargeValue },
		{ name: 'Rule', selector: (row: any) => getRuleNameById(row.serviceChargeRuleId) },
		{ name: 'Active', selector: (row: any) => (row.isActive ? 'True' : 'False') },
		{
			name: 'Actions',
			selector: (row: any) => (
				<div
					onClick={() => removeServiceChargeFromList(row.index)}
					className="btn btn-sm btn-danger">
					x
				</div>
			),
		},
	];

	const columns: any = [
		{
			name: 'S.N',
			maxWidth: '80px',
			minWidth: '80px',
			selector: (row: any) => row.sn,
		},
		{
			name: 'Service Name',
			selector: (row: any) => row.name,
		},
		{
			name: 'Service Group',
			selector: (row: any) => row.serviceGroup,
		},

		{
			name: 'Actions',
			minWidth: '6%',
			maxWidth: '6%',
			selector: (row: any) => (
				<div className="d-flex ms-2">
					<BootstrapForm>
						<BootstrapForm.Check
							type="switch"
							id="custom-switch"
							// onChange={() =>handleStatusUpdate(row?.id)}
							checked={row.isEnabled}
						/>
					</BootstrapForm>
				</div>
			),
		},
	];

	const postServiceCharge = async () => {
		try {
			const res: any = await handleRequest(
				ServiceChargeService.putServiceCharge({
					utilityServiceId: utilityServiceId,
					utilityServiceServiceChargeDto: serviceCharges,
				})
			);
			setShow(false);
			if (!res) {
				toast.error('Failed to add service charges');
			} else {
				toast.success('Service Charge Added Successfully');
			}
		} catch (err) {
			toast.error('Failed to add service charges');
		}
	};

	const handleOnChangePage = (page: number) => {
		page -= 1;
		setPageSkip(page * pageLimit);
	};

	const handlePerRowsChange = (newPage: any) => setPageLimit(newPage);
	const paginationRowsPerPage = [10, 25, 50, 100];

	return (
		<>
			<Header title="Service List" />
			<div className="px-4">
				<FilterBar
					searchVal={filters.searchQuery}
					onSearchValChange={(val: string) => {
						setFilters({
							...filters,
							searchQuery: val,
						});
					}}
					filters={[]}
					hideActionButton
					hideReportActionButton
				/>
			</div>
			<div className="table__container">
				<DataTable
					dense
					columns={columns}
					data={serviceListDetail}
					striped
					pagination
					paginationServer
					paginationTotalRows={totalRows}
					onChangePage={handleOnChangePage}
					onChangeRowsPerPage={handlePerRowsChange}
					progressPending={isLoading}
					paginationRowsPerPageOptions={paginationRowsPerPage}
					progressComponent={<Spinner />}
				/>

				<Modal show={show}>
					<Modal.Header className="application__heading">
						<Modal.Title>Manage Service Charges</Modal.Title>
						<CgClose onClick={() => setShow(!show)} color="#FFFFFF" size={30} />
					</Modal.Header>
					<Formik
						initialValues={{
							index: serviceCharges.length > 0 ? serviceCharges.length + 1 : 1,
							serviceChargeName: '',
							serviceChargeValue: 0,
							serviceChargeRuleId: '',
							isActive: true,
							isServiceChargeInclusiveOfPrincipalAmount: false,
							//orderNumber: 0,
						}}
						validationSchema={FORM_VALIDATION}
						validateOnMount={true}
						onSubmit={(values, actions) => {
							const updatedValues = { ...values, index: serviceCharges.length + 1 };
							setServiceCharges([...serviceCharges, updatedValues as ServiceCharge]);

							actions.resetForm({
								values: {
									index: 0,
									serviceChargeName: '',
									serviceChargeValue: 0,
									serviceChargeRuleId: '',
									isActive: false,
									isServiceChargeInclusiveOfPrincipalAmount: false,
									//orderNumber: 0
								},
							});
						}}>
						{({ values, handleBlur, handleChange }) => (
							<Form>
								<Modal.Body>
									<div className="row">
										<div className="col-6">
											<TextField
												placeholder=""
												type="text"
												label="Service Charge Name"
												name="serviceChargeName"
												value={values.serviceChargeName}
												onChange={handleChange}
												onBlur={handleBlur}
											/>
										</div>
										<div className="col-6">
											<TextField
												placeholder=""
												label="Service Charge Value"
												type="text"
												name="serviceChargeValue"
												value={values.serviceChargeValue}
												onChange={handleChange}
												onBlur={handleBlur}
											/>
										</div>

										<div className="col-6">
											<label htmlFor="" className="input__label">
												Service Charge Rule
												<b
													className="text-danger position-absolute"
													style={{ marginLeft: '.2rem' }}>
													*
												</b>
											</label>
											<Field
												className="custom-select"
												name="serviceChargeRuleId"
												options={filterValue}
												component={CustomSelect}
												placeholder="Select a rule"
												isMulti={false}
											/>
										</div>

										<div className="col-md-6">
											<label htmlFor="" className="input__label">
												Is Active ?
											</label>
											<input
												style={{ margin: '10px' }}
												type="checkbox"
												checked={values.isActive}
												onChange={handleChange}
												id="cb"
												name="isActive"
											/>
										</div>
									</div>

									<div className="col-md-6 mt-4">
										<button type="submit" className="btn btn-gradient-success">
											Add to list
										</button>
									</div>

									<DataTable striped dense data={serviceCharges} columns={newColumns} />
								</Modal.Body>
								<Modal.Footer className="mt-5">
									<button
										onClick={postServiceCharge}
										className="btn btn-gradient-success"
										disabled={false}>
										Save
									</button>
								</Modal.Footer>
							</Form>
						)}
					</Formik>
				</Modal>
			</div>
		</>
	);
};

export default memo(ServiceList);
