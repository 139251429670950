import styled from 'styled-components';
import notFound from '../../assets/404.c40d388d.svg';
import { Link } from 'react-router-dom';
import Button from 'components/Button/Button';

const PageNotFound = styled.div`
	display: flex;
	min-height: 100vh;
	width: 100%;
	align-items: center;
	justify-content: center;
	.not__found {
		&__sorry__text {
			font-size: 46px;
			color: rgb(235, 31, 39) !important;
		}
		&__text {
			font-size: 32px;
		}
		&__bottom__text {
			font-size: 16px;
		}
	}
	.img-container {
		width: 280px;
		margin: 0 auto;
	}
`;

const index = () => {
	return (
		<PageNotFound>
			<div className="container">
				<div className="d-flex justify-content-center mt-5">
					<h6 className="not__found__sorry__text fw-bold">SORRY</h6>
				</div>
				<div className="d-flex justify-content-center my-2">
					<h6 className="not__found__text fw-bold">The requested page was not found.</h6>
				</div>
				<div className="d-flex justify-content-center mb-5">
					<h6 className="not__found__bottom__text fw-bold">
						It seems you have ventured onto a broken link, please use the menu bar to navigate to
						the desired page.
					</h6>
				</div>
				<div className="d-flex d-flex justify-content-center my-5 img-container">
					<img src={notFound} alt="404" className="img-fluid" />
				</div>
				<div className="d-flex justify-content-center mb-5">
					<Link to="/">
						<Button type="link">Back to Home</Button>
					</Link>
				</div>
			</div>
		</PageNotFound>
	);
};

export default index;
