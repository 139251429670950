import React from 'react';
import { Tab, TabContainer, Tabs } from 'react-bootstrap';

import { Header } from 'components';
import AddGroup from './AddGroup';
import AddSetting from './AddSettings';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

const TabsContainer = styled.div<any>`
	.nav-item {
		.nav-link {
			color: ${(props) => props.theme.PrimaryColor};
			font-weight: 700;
			&.active {
				border-color: ${(props) => props.theme.PrimaryColor};
				background-color: ${(props) => props.theme.PrimaryColor};
				color: #fff;
			}
		}
	}
`;

export default function Settings() {
	const [key, setKey] = React.useState('setting');
	const theme = useSelector((state: any) => state.theme.theming);
	const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);

	const closeModal = () => {
		setIsModalOpen(false);
	};
	const handleOpen = () => {
		setIsModalOpen(true);
	};
	return (
		<div>
			{key == 'group' ? (
				<Header title="Create Setting" btnText="Add Group" onClick={handleOpen} />
			) : (
				<Header title="Create Setting" />
			)}

			<div className="p-4">
				<TabsContainer theme={theme}>
					<Tabs
						id="controlled-tab-example"
						activeKey={key}
						onSelect={(k: any) => setKey(k)}
						className="mb-3">
						<Tab eventKey="setting" title="Settings">
							<AddSetting />
						</Tab>
						<Tab eventKey="group" title="Group Settings"></Tab>
					</Tabs>
				</TabsContainer>
			</div>
			<AddGroup isOpen={isModalOpen} onClose={closeModal} />
		</div>
	);
}
