import { Form, Formik } from 'formik';
import { Modal } from 'react-bootstrap';
import * as Yup from 'yup';

import { TextField } from 'components';
import Button from 'components/Button/Button';

type Props = {
	isOpen: boolean;
	onClose: any;
};

const FORM_VALIDATION = Yup.object().shape({
	name: Yup.string().required('This field is required'),
});
export default function AddGroup({ isOpen, onClose }: Props) {
	//fn to add user
	const handleAdd = async () => {};

	//fn to edit user
	const handleEdit = async () => {};

	return (
		<Modal show={isOpen} onHide={onClose}>
			<Modal.Header className="application__heading" closeButton>
				<Modal.Title>Add Group</Modal.Title>
			</Modal.Header>
			<Formik
				initialValues={{
					name: '',
				}}
				validationSchema={FORM_VALIDATION}
				// validateOnMount={true}
				onSubmit={(values, { setSubmitting }) => {
					// eslint-disable-next-line no-unused-expressions
				}}
				enableReinitialize={true}>
				{({ values, handleBlur, handleChange, handleSubmit, isSubmitting }) => (
					<Form onSubmit={handleSubmit}>
						<Modal.Body>
							<div className="row">
								<div className="col-12">
									<TextField
										placeholder=""
										type="text"
										label="Name"
										name="name"
										value={''}
										onChange={handleChange}
										onBlur={handleBlur}
									/>
								</div>
							</div>
						</Modal.Body>
						<Modal.Footer>
							<Button type="submit" loading={isSubmitting}>
								Save
							</Button>
							<Button type="btn" onClick={onClose} outlined>
								Cancel
							</Button>
						</Modal.Footer>
					</Form>
				)}
			</Formik>
		</Modal>
	);
}
