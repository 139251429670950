import dayjs from 'dayjs';
import React, { useEffect } from 'react';
import { Tab, Tabs } from 'react-bootstrap';

import { Header } from 'components';
import useAdminCoreUtilityApi from 'hooks/useAdminCoreUtilityApi';
import { UtilityServiceRequestService } from 'services/utility-core-services';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

const TabsContainer = styled.div<any>`
	.nav-item {
		.nav-link {
			color: ${(props) => props.theme.PrimaryColor};
			font-weight: 700;
			&.active {
				border-color: ${(props) => props.theme.PrimaryColor};
				background-color: ${(props) => props.theme.PrimaryColor};
				color: #fff;
			}
		}
	}
`;

export default function VisaTransactionDetail({ id, close }: any) {
	// const [key, setKey] = React.useState('details');
	const [visaLog, setVisaLog] = React.useState<any>({});
	const theme = useSelector((state: any) => state.theme.theming);
	const { handleRequest: fetchVisaDirectDetail } = useAdminCoreUtilityApi();

	useEffect(() => {
		if (id) {
			fetchVisaDirectDetail(
				UtilityServiceRequestService.getUtilityServiceRequest16(id).then((ipg: any) => {
					if (ipg?.status !== 200) {
						setVisaLog(ipg);
					}
				})
			);
		}
	}, [id]);

	const prettyData =
		visaLog?.responseParameter !== undefined || '' ? JSON.parse(visaLog.responseParameter) : '';

	return (
		<div className="container-fluid">
			{/* {error && <span>Not Found</span>} */}
			<Header title="Transaction Details" pageBack={() => close(null)} />
			<div className="px-4 position-relative">
				<TabsContainer theme={theme}>
					<Tabs
						id="controlled-tab-example"
						activeKey={'details'}
						// onSelect={(k: any) => setKey(k)}
						className="mb-3">
						<Tab eventKey="details" title="Details">
							{visaLog ? (
								<div className="py-4 row">
									<div className="col-12">
										<div className="table-responsive">
											<table className="table table-bordered">
												<tbody>
													<tr>
														<td className="first__column">Mobile Number</td>
														<td>{visaLog ? visaLog?.mobileNumber : <span>N/A</span>}</td>
													</tr>

													<tr>
														<td className="first__column">Amount</td>
														<td>{visaLog ? visaLog?.amount : <span>N/A</span>}</td>
													</tr>

													<tr>
														<td className="first__column">Session ID</td>
														<td>{visaLog ? visaLog?.sessionId : <span>N/A</span>}</td>
													</tr>
												</tbody>
											</table>
										</div>
									</div>

									<div className="col-md-6 col-12">
										<table className="table table-bordered">
											<tbody>
												<tr>
													<td className="first__column">Order Status</td>
													<td>{visaLog ? visaLog?.orderStatus : <span>N/A</span>}</td>
												</tr>
												<tr>
													<td className="first__column">Request Date</td>
													<td>
														{visaLog ? (
															dayjs(visaLog?.requestDate).format('MM/DD/YYYY hh:mm:ss')
														) : (
															<span>N/A</span>
														)}
													</td>
												</tr>

												<tr>
													<td className="first__column">Response Date Time</td>
													<td>
														{visaLog ? (
															dayjs(visaLog?.nabilResponseDateTime).format('MM/DD/YYYY hh:mm:ss')
														) : (
															<span>N/A</span>
														)}
													</td>
												</tr>
											</tbody>
										</table>
									</div>
									<div className="col-12 mt-5">
										<div className="table-responsive">
											<table className="table table-bordered ">
												<tbody>
													<tr>
														<td className="first__column">Parameter</td>
														<td>
															{visaLog && (
																<pre className="pre">{JSON.stringify(prettyData, null, 4)}</pre>
															)}
														</td>
													</tr>
												</tbody>
											</table>
										</div>
									</div>
								</div>
							) : (
								<div className="d-flex align-items-center justify-content-center mt-5">
									<h2 className="my-5">No data found</h2>
								</div>
							)}
						</Tab>
					</Tabs>
				</TabsContainer>
			</div>
		</div>
	);
}
