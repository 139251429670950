import { Banner } from 'assets';
import axios from 'axios';
import { Header } from 'components';
import AdminLayout from 'container';
import React from 'react';
import { Modal } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { AiOutlineDelete } from 'react-icons/ai';
import { CgClose } from 'react-icons/cg';
import { IoMdCloudUpload } from 'react-icons/io';

import useAdminCoreUtilityApi from 'hooks/useAdminCoreUtilityApi';
import { DocumentService, OpenAPI } from 'services/utility-core-services';
import Toast from 'utils/toast';
import PreviewImage from './PreviewImage';

const ImageUpload = () => {
	let URL = window.__RUNTIME_CONFIG__.REACT_APP_UTILITY_CLIENT_API_ENDPOINT;

	const token = window.localStorage.getItem('accessToken');

	const axiosConfig = axios.create({
		baseURL: URL,
		headers: {
			authorization: `Bearer ${token || OpenAPI.TOKEN}`,
		},
	});

	const [show, setShow] = React.useState(false);
	const [disable, setDisable] = React.useState(true);
	const [selectedFile, setSelectedFile] = React.useState<any>(null);
	const [dataPackID, setDataPackID] = React.useState<any>('');
	const [utilityServiceList, setUtilityServiceList] = React.useState<any>([]);
	const [imageShow, setImageShow] = React.useState<any>(null);
	const inputRef = React.useRef<any>(null);
	const { handleRequest } = useAdminCoreUtilityApi();

	const columns: any = [
		{
			name: 'S.N.',
			maxWidth: '80px',
			minWidth: '80px',
			selector: (row: any, idx: number) => idx + 1,
		},
		{
			name: 'Image',
			selector: (row: any) => (
				<img src={row?.image} alt={row?.image} className="setting__image__table" />
			),
		},
		{
			name: 'Actions',
			selector: () => (
				<div className="d-flex">
					<div
						className="d-flex align-items-center btn-ouline-success btn btn-sm btn-outline-success"
						onClick={() => {}}>
						<AiOutlineDelete className="" size={20} />
						<span className="mx-2">Delete</span>
					</div>
				</div>
			),
		},
	];
	const fetchId = async () => {
		const res = await axiosConfig.get(`/framework/api/app/utility/utility-services`);
		setUtilityServiceList(res.data);
	};

	const filterData = utilityServiceList
		? utilityServiceList.filter(
				(e: any) => e.name === 'Ntc Datapack' || e.name === 'Ncell DataPack'
		  )
		: null;

	React.useEffect(() => {
		fetchId();
	}, []);
	const handleChange = (e: any) => {
		setSelectedFile(e.target.files);
		setImageShow(e.target.files[0]);
	};
	const handleSubmit = async (e: any) => {
		e.preventDefault();
		const postData: any = {
			Banner: selectedFile[0],
		};
		const response: any = await handleRequest(DocumentService.postDocument(dataPackID, postData));
		if (response?.length > 0) {
			setShow(false);
			Toast('success', 'Banner uploaded successfull');
		} else {
			Toast('error', 'Failed to add Banner');
		}
	};

	// const fetchImage = async () => {
	// 	const response: any = await handleRequest(DocumentService.postDocument());
	// };

	return (
		<AdminLayout>
			<Header title="Banner Upload" btnText="Add Banner" onClick={() => setShow(!show)} />
			<div className="ms-3 mt-5">
				<DataTable
					dense
					columns={columns}
					data={[
						{
							image: Banner,
						},
					]}
					striped
					progressComponent={
						<div
							className="spinner-border text-success d-flex align-items-center justify-content-center"
							role="status"></div>
					}
				/>
			</div>
			<Modal show={show} onHide={() => setShow(!show)}>
				<Modal.Header className="application__heading">
					<Modal.Title>Upload Banner</Modal.Title>
					<CgClose onClick={() => setShow(!show)} color="#FFFFFF" size={30} />
				</Modal.Header>
				<form onSubmit={handleSubmit}>
					<Modal.Body>
						<input type="file" onChange={handleChange} hidden ref={inputRef} />
						{imageShow && <PreviewImage file={imageShow} />}
						<div className="mb-5" />

						<span
							className="file__upload d-flex align-items-center justify-content-center"
							onClick={() => inputRef.current.click()}>
							<IoMdCloudUpload color="#1aa659" size={80} />
						</span>
						<p className="text-center mt-2">Click on Icon to Choose Image</p>
						<div className="mt-2">
							<label htmlFor="" className="label__heading mb-1">
								Choose a DataPack Services
							</label>
							<br />
							<select
								name=""
								id=""
								value={dataPackID}
								onChange={(e: any) => {
									setDataPackID(e?.target?.value);
									setDisable(false);
								}}
								className="form-control">
								<option> Choose a Datapack Service </option>
								<option value={filterData[0]?.id ? filterData[0]?.id : ''}> Ncell DataPack </option>
								<option value={filterData[1]?.id ? filterData[1]?.id : ''}> Ntc DataPack </option>
							</select>
						</div>
						<br />
					</Modal.Body>

					<Modal.Footer className="mt-5">
						<button className="btn btn-gradient-success" disabled={disable} type="submit">
							Save
						</button>
						<div onClick={() => setShow(false)} className="btn btn-danger">
							Close
						</div>
					</Modal.Footer>
				</form>
			</Modal>
		</AdminLayout>
	);
};

export default ImageUpload;
