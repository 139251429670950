import { Header } from 'components';
import React, { memo } from 'react';
import DataTable from 'react-data-table-component';
import { AiOutlineEdit } from 'react-icons/ai';
import { BiUserPlus } from 'react-icons/bi';
import { FaUserShield } from 'react-icons/fa';

import Button from 'components/Button/Button';
import Spinner from 'components/Spinner';
import FilterBar from 'components/Table/FilterBar';
import useAdminCoreUtilityApi from 'hooks/useAdminCoreUtilityApi';
import { IdentityUserService } from 'services/utility-core-services';
import AddEditPerrmission from './AddEditPerrmission';
import AddEditUser from './AddEditUser';

const UserManagement = () => {
	const [usersList, setUsersList] = React.useState([]);
	const [usersListWithPermission, setUsersListWithPermission] = React.useState([]);
	const [totalRows, setTotalRows] = React.useState(0);
	const [pageSkip, setPageSkip] = React.useState<any>(0);
	const [pageLimit, setPageLimit] = React.useState(10);
	const [roleArray, setRoleArray] = React.useState([]);
	const [loginTypes, setLoginTypes] = React.useState([]);
	const [userData, setUserData] = React.useState<any>();
	const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);
	const [isPermissionModalOpen, setIsPermissionModalOpen] = React.useState<boolean>(false);
	const [filters, setFilters] = React.useState({
		searchQuery: '',
	});

	const { handleRequest: handleFetchUsers, isLoading: isUserLoading } = useAdminCoreUtilityApi();
	const { handleRequest: handleFetchUser } = useAdminCoreUtilityApi();
	const { handleRequest: handleFetchLoginType } = useAdminCoreUtilityApi();
	const { handleRequest: handleFetchRole } = useAdminCoreUtilityApi();
	const { handleRequest: handleFetchPermission } = useAdminCoreUtilityApi();

	const fetchUsers = async () => {
		let SkipCount = pageSkip;
		let MaxResultCount = pageLimit;
		handleFetchUsers(
			IdentityUserService.getIdentityUser(SkipCount, MaxResultCount, filters.searchQuery)
		).then((res: any) => {
			const calculatedData = res?.items.map((row: any, index: any) => {
				return {
					...row,
					sn: index + 1 + pageSkip, // Calculate the serial number
				};
			});
			setUsersList(calculatedData);
			setTotalRows(res?.totalCount);
		});
	};
	const fetchUsersWithPermission = async () => {
		let SkipCount = pageSkip;
		let MaxResultCount = pageLimit;
		const res: any = await handleFetchPermission(
			IdentityUserService.getIdentityUser2(SkipCount, MaxResultCount)
		);
		setUsersListWithPermission(res?.items);
	};
	const fetchUser = async (user: any) => {
		setUserData(user);
		// const response = await handleFetchUser(IdentityUserService.getIdentityUser3(user.id));
		const respon: any = await handleFetchLoginType(IdentityUserService.getIdentityUser6(user.id));
		const res: any = await handleFetchRole(IdentityUserService.getIdentityUser5(user.id));
		setRoleArray(res?.items);
		setLoginTypes(respon);
	};
	const roleData = roleArray ? roleArray.map((e: any) => e.name) : null;

	//paginations
	const handleOnChangePage = (page: number) => {
		page -= 1;
		setPageSkip(page * pageLimit);
	};
	const handlePerRowsChange = (newPage: number) => setPageLimit(newPage);
	const paginationRowsPerPage = [5, 10];

	React.useEffect(() => {
		fetchUsers();
		// fetchUsersWithPermission();
	}, [pageSkip, pageLimit, filters]);

	//defining column for table
	const columns: any = [
		{
			name: 'S.N.',
			maxWidth: '80px',
			minWidth: '80px',
			selector: (row: any) => row?.sn,
		},
		{
			name: 'Username',
			selector: (row: any) =>
				row?.identityUserDto.userName === null || '' ? '-' : row?.identityUserDto.userName,
		},
		{
			name: 'Full Name',
			selector: (row: any) => row?.identityUserDto.name + ' ' + row?.identityUserDto.surname,
		},
		{
			name: 'Permission',
			selector: (row: any) => row.userPermissionDto.permissions,
		},
		{
			name: 'Email',
			selector: (row: any) =>
				row?.identityUserDto.email === null || '' ? '-' : row.identityUserDto.email,
		},
		{
			name: 'Phone Number',
			selector: (row: any) =>
				row?.identityUserDto.phoneNumber === null || '' ? '-' : row?.identityUserDto.phoneNumber,
		},
		{
			name: 'Actions',
			minWidth: '10%',
			maxWidth: '10%',
			center: false,
			right: true,
			selector: (row: any) => (
				<div className="d-flex align-items-center gap-2">
					<Button
						type="button"
						title="Edit user"
						onClick={() => {
							setIsModalOpen(true);
							fetchUser(row?.identityUserDto);
						}}>
						<AiOutlineEdit size={16} />
					</Button>
					{row?.identityUserDto?.userName !== 'guest' ? (
						<Button
							type="button"
							title="Assign Permission"
							onClick={() => {
								setIsPermissionModalOpen(true);
								fetchUser(row?.identityUserDto);
							}}>
							<FaUserShield size={16} />
						</Button>
					) : null}
				</div>
			),
		},
	];

	//fn to handle modal open and close
	const closeModal = () => {
		setIsModalOpen(false);
		setUserData(null);
		setRoleArray([]);
	};
	const handleOpen = () => {
		setIsModalOpen(true);
	};
	const closePermissionModal = () => {
		setIsPermissionModalOpen(false);
	};

	return (
		<>
			<Header
				title="User Management"
				btnText="Add User"
				btnIcon={<BiUserPlus size={18} />}
				onClick={handleOpen}
			/>
			<div className="px-4">
				<FilterBar
					searchVal={filters.searchQuery}
					onSearchValChange={(val: string) => {
						setFilters({
							...filters,
							searchQuery: val,
						});
					}}
					filters={[]}
					hideActionButton
					hideReportActionButton
				/>
			</div>
			<div className="table__container">
				<DataTable
					dense
					columns={columns}
					data={usersList}
					striped
					pagination
					paginationServer
					paginationTotalRows={totalRows}
					onChangePage={handleOnChangePage}
					onChangeRowsPerPage={handlePerRowsChange}
					progressPending={isUserLoading}
					paginationRowsPerPageOptions={paginationRowsPerPage}
					progressComponent={<Spinner />}
				/>
			</div>

			{isModalOpen && (
				<AddEditUser
					isOpen={isModalOpen}
					onClose={closeModal}
					userData={userData}
					roleData={roleData}
					loginTypes={loginTypes}
					fetchUsers={fetchUsers}
				/>
			)}
			{isPermissionModalOpen && (
				<AddEditPerrmission
					isOpen={isPermissionModalOpen}
					onClose={closePermissionModal}
					userData={userData}
					fetchUsers={fetchUsers}
				/>
			)}
		</>
	);
};

export default memo(UserManagement);
