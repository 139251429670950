import { Header } from 'components';
import Button from 'components/Button/Button';
import Spinner from 'components/Spinner';
import useAdminCoreUtilityApi from 'hooks/useAdminCoreUtilityApi';
import React, { memo, useState } from 'react';
import DataTable from 'react-data-table-component';
import { AiOutlineEdit } from 'react-icons/ai';
import { IdentityRoleService } from 'services/utility-core-services';
import { textUpperCase } from 'utils/textUpperCase';
import Toast from 'utils/toast';
import * as Yup from 'yup';
import AddEditRole from './AddEditRole';

const FORM_VALIDATION = Yup.object().shape({
	name: Yup.string().required('This field is required'),
});

const RoleManagement = () => {
	const [show, setShow] = React.useState(false);
	const [show1, setShow1] = React.useState(false);
	const [roleList, setRoleList] = React.useState<any>([]);
	const [totalRows, setTotalRows] = React.useState<any>(0);
	const [pageSkip, setPageSkip] = React.useState<any>(0);
	const [pageLimit, setPageLimit] = React.useState<any>(10);
	const [roleData, setRoleData] = React.useState<any>([]);
	const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);
	const [refresh, setRefresh] = useState(Math.random());

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);
	const handleClose1 = () => setShow1(false);

	const { handleRequest, isLoading } = useAdminCoreUtilityApi();

	const fetchRoles = async () => {
		const response: any = await handleRequest(
			IdentityRoleService.getIdentityRole2(pageSkip, pageLimit)
		);
		const calculatedData = response?.items.map((row: any, index: any) => {
			return {
				...row,
				sn: index + 1 + pageSkip, // Calculate the serial number
			};
		});
		setRoleList(calculatedData);
		setTotalRows(response?.totalCount);
	};

	const editedPermissionToArray = roleData?.permissions?.split(', ') || '';

	const postRoleRequest = async (val: any) => {
		try {
			const payload: any = {
				role: {
					name: val.name,
					isDefault: true,
					isPublic: true,
				},
				permissions: ['Backend_Users'],
			};
			const res: any = await handleRequest(IdentityRoleService.postIdentityRole(payload));
			if (!res) {
				throw Error('error man');
			}

			Toast('success', 'Role Added Successfully');
			setShow(!show);
			fetchRoles();
		} catch (e) {
			Toast('error', 'Failed to add role ');
		}
	};
	const handleOnChangePage = (page: number) => {
		page -= 1;
		setPageSkip(page * pageLimit);
	};
	const handlePerRowsChange = (newPage: any) => setPageLimit(newPage);
	const paginationRowsPerPage = [5, 10];
	React.useEffect(() => {
		fetchRoles();
	}, [pageSkip, pageLimit, refresh]);

	const columns: any = [
		{
			name: 'S.N',
			maxWidth: '80px',
			minWidth: '80px',
			selector: (row: any) => row?.sn,
		},
		{
			name: 'Name',
			selector: (row: any) => textUpperCase(row.name),
		},

		{
			name: 'Actions',
			minWidth: '10%',
			maxWidth: '10%',
			selector: (row: any) => (
				<Button
					type="button"
					onClick={() => {
						setIsModalOpen(true);
						setRoleData(row);
					}}>
					<AiOutlineEdit size={16} />
				</Button>
			),
		},
	];
	const [roleInfo, setRoleInfo] = React.useState<any>([]);
	const [editRoleInfo, setEditRoleInfo] = React.useState<any>([]);
	//
	// type initialPermissionType = {
	// 	name: string;
	// };

	// type initialValueType = {
	// 	name: string;
	// 	permissions: initialPermissionType[];
	// };
	const toggleHandler = (item: any, values: any, setFieldValue: any) => () => {
		let data: any = values.permissions;
		const roleExist = data.find((role: any) => role === item.name);
		if (roleExist) {
			data = data.filter((role: any) => role !== item.name);
		} else {
			data.push(item.name);
		}
		setFieldValue('permissions', data);
	};

	const toggleRoleHandler = (item: any) => () => {
		setRoleInfo((state: any) => ({
			...state,
			[item.id]: state[item.id]
				? null
				: {
						value: item?.value,
				  },
		}));
	};

	const rolePermission = Object.values(roleInfo);
	const addPermission = rolePermission.map((obj: any) => {
		return obj?.value;
	});

	// const toggleRoleHandler1 = (item: any) => () => {
	// 	setEditRoleInfo((state: any) => ({
	// 		...state,
	// 		[item.id]: state[item.id]
	// 			? null
	// 			: {
	// 					value: item.value,
	// 			  },
	// 	}));
	// };
	// const editRolePermission = Object.values(editRoleInfo);
	// const editedPermission = editRolePermission.map((obj: any) => {
	// 	return obj?.value;
	// });

	// React.useEffect(() => {
	//
	//
	//
	// }, [rolePermission, editRolePermission]);

	//fn to handle modal open and close
	const closeModal = () => {
		setIsModalOpen(false);
	};
	const handleOpen = () => {
		setIsModalOpen(true);
		setRoleData(null);
	};
	return (
		<>
			<Header title="Role Management" btnText="Add Role" onClick={handleOpen} />
			<div className="table__container">
				<DataTable
					dense
					columns={columns}
					data={roleList}
					striped
					pagination
					paginationServer
					paginationTotalRows={totalRows}
					onChangePage={handleOnChangePage}
					onChangeRowsPerPage={handlePerRowsChange}
					progressPending={isLoading}
					paginationRowsPerPageOptions={paginationRowsPerPage}
					progressComponent={<Spinner />}
				/>
			</div>

			<AddEditRole
				roleData={roleData}
				isOpen={isModalOpen}
				onClose={closeModal}
				setRefresh={setRefresh}
			/>
		</>
	);
};

export default memo(RoleManagement);
