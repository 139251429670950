import { axiosConfig } from 'utils/interceptors';
import { themeConstant } from '../constant/theme.constant';

const BASE_URL = window.__RUNTIME_CONFIG__.REACT_APP_UTILITY_API_ENDPOINT;

export const getTheme = () => {
	return async (dispatch: any) => {
		dispatch({
			type: themeConstant.THEME_REQUEST,
		});
		try {
			const payload: any = {};
			const res = await axiosConfig.get(
				`${BASE_URL}/admin-api/api/app/cms-settings/settings-by-type?cmsSettingTypeName=theme-setting`
			);
			const res1 = await axiosConfig.get(
				`${BASE_URL}/admin-api/api/app/cms-settings/settings-by-type?cmsSettingTypeName=image-setting`
			);
			payload['theme'] = res.data;
			payload['image'] = res1.data;
			dispatch({
				type: themeConstant.THEME_SUCCESS,
				payload: payload,
			});
		} catch (err: any) {
			dispatch({
				type: themeConstant.THEME_FAILED,
			});
		}
	};
};
