import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';

import { CustomSelect, TextField } from 'components';
import Button from 'components/Button/Button';

const FORM_VALIDATION = Yup.object().shape({
	name: Yup.string().required('This field is required'),
	label: Yup.string().required('This field is required'),
	group: Yup.string().required('This field is required'),
	settingType: Yup.string().required('This field is required'),
	formType: Yup.string().required('This field is required'),
});

export default function AddSetting() {
	return (
		<Formik
			initialValues={{
				name: '',
				settingType: '',
				group: '',
				label: '',
				formType: '',
			}}
			// validateOnMount={true}
			validationSchema={FORM_VALIDATION}
			onSubmit={(values, { setSubmitting }) => {
				// eslint-disable-next-line no-unused-expressions
			}}
			enableReinitialize={true}>
			{({ values, handleBlur, handleChange, handleSubmit, isSubmitting }) => (
				<Form onSubmit={handleSubmit}>
					<div className="row">
						<div className="col-lg-4">
							<label htmlFor="" className="input__label">
								Setting Type
								<b className="text-danger position-absolute" style={{ marginLeft: '.2rem' }}>
									*
								</b>
							</label>
							<Field
								className="custom-select"
								placeholder=""
								option={[{ label: 'Backend', value: 'backend' }]}
								component={CustomSelect}
								name="settingType"
							/>
						</div>
						<div className="col-lg-4">
							<TextField
								placeholder=""
								type="text"
								label="Name"
								name="name"
								value={''}
								onChange={handleChange}
								onBlur={handleBlur}
							/>
						</div>
						<div className="col-lg-4">
							<TextField
								placeholder=""
								type="text"
								label="Label"
								name="label"
								value={''}
								onChange={handleChange}
								onBlur={handleBlur}
							/>
						</div>
						<div className="col-lg-4">
							<label htmlFor="" className="input__label">
								Input Type
								<b className="text-danger position-absolute" style={{ marginLeft: '.2rem' }}>
									*
								</b>
							</label>
							<Field
								className="custom-select"
								placeholder=""
								option={[{ label: 'Backend', value: 'backend' }]}
								component={CustomSelect}
								name="formType"
							/>
						</div>
						<div className="col-lg-4">
							<label htmlFor="" className="input__label">
								Setting Group
								<b className="text-danger position-absolute" style={{ marginLeft: '.2rem' }}>
									*
								</b>
							</label>
							<Field
								className="custom-select"
								placeholder=""
								component={CustomSelect}
								name="group"
							/>
						</div>
						<div className="col-12 py-3">
							<Button type="submit" loading={isSubmitting}>
								Save
							</Button>
						</div>
					</div>
				</Form>
			)}
		</Formik>
	);
}
