/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Volo_Abp_Application_Dtos_PagedResultDto_1 } from '../models/Volo_Abp_Application_Dtos_PagedResultDto_1';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class AuditLogService {

    /**
     * @param userName 
     * @param roleName 
     * @param module 
     * @param activity 
     * @param executionDate 
     * @returns void 
     * @throws ApiError
     */
    public static getAuditLog(
userName?: string,
module?: string,
activity?: string,
executionDate?: string,
): CancelablePromise<void> {
        return __request({
            method: 'GET',
            path: `/admin-api/api/app/audit-log/auditlog-to-excel`,
            query: {
                'UserName': userName,
                'Module': module,
                'Activity': activity,
                'ExecutionDate': executionDate,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param skip 
     * @param take 
     * @param searchValue 
     * @param userName 
     * @param roleName 
     * @param module 
     * @param activity 
     * @param executionDate 
     * @returns Volo_Abp_Application_Dtos_PagedResultDto_1<Amnil_UtilityServiceBackEnd_AuditLogs_Dtos_UserActivityHistoryDto_Amnil_UtilityServiceBackEnd_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null_> Success
     * @throws ApiError
     */
    public static getAuditLog1(
skip?: number,
take?: number,
executionDate?: string,
userName?: string,
module?: string,
activity?: string,
): CancelablePromise<Volo_Abp_Application_Dtos_PagedResultDto_1> {
        return __request({
            method: 'GET',
            path: `/admin-api/api/app/audit-log/audit-log`,
            query: {
                'Skip': skip,
                'Take': take,
                'ExecutionDate': executionDate,
                'UserName': userName,
                'Module': module,
                'Activity': activity,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

}
