import { TextField } from 'components';
import { FieldArray, Form, Formik } from 'formik';
import React from 'react';
import { Modal } from 'react-bootstrap';

import useAdminCoreUtilityApi from 'hooks/useAdminCoreUtilityApi';
import { IdentityUserService } from 'services/utility-core-services';
import Toast from 'utils/toast';
import Button from 'components/Button/Button';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

const CustomCheckMarkContainer = styled.div`
	input[type='checkbox'] {
		position: relative;
		border: 2px solid ${(props: any) => props.theme.PrimaryColor};
		border-radius: 2px;
		background: none;
		cursor: pointer;
		line-height: 0;
		margin: 0;
		outline: 0;
		padding: 0 !important;
		vertical-align: text-top;
		height: 20px;
		width: 20px;
		-webkit-appearance: none;
		opacity: 0.5;
		&:hover {
			opacity: 1;
		}
		&:checked {
			background-color: ${(props: any) => props.theme.PrimaryColor};
			opacity: 1;
		}
		&:before {
			content: '';
			position: absolute;
			right: 50%;
			top: 50%;
			width: 4px;
			height: 10px;
			border: solid #fff;
			border-width: 0 2px 2px 0;
			margin: -1px 0 0 -1px;
			transform: rotate(45deg) translate(-50%, -50%);
			z-index: 2;
		}
	}
`;

type Props = {
	isOpen: boolean;
	userData: any;
	onClose: () => void;
	fetchUsers: any;
};
const permissionLists = [
	{
		id: 1,
		value: 'Users',
		name: 'BackEnd_Users',
	},
	{
		id: 2,
		value: 'Roles',
		name: 'BackEnd_Roles',
	},
	{
		id: 3,
		value: 'RequestLists',
		name: 'BackEnd_RequestLists',
	},
	{
		id: 4,
		value: 'AuditLogs',
		name: 'BackEnd_AuditLogs',
	},
	{
		id: 5,
		value: 'VisaDirect',
		name: 'BackEnd_VisaDirect',
	},

	{
		id: 6,
		value: 'Services',
		name: 'BackEnd_Services',
	},
	{
		id: 7,
		value: 'Themes',
		name: 'BackEnd_Themes',
	},
	{
		id: 8,
		value: 'Menus',
		name: 'BackEnd_Menus',
	},
];

export default function AddEditPerrmission({ isOpen, onClose, userData, fetchUsers }: Props) {
	const [permissions, setPermissions] = React.useState([]);
	const { handleRequest: handleFetchRole } = useAdminCoreUtilityApi();
	const { handleRequest: handlePostRole } = useAdminCoreUtilityApi();
	const themes = useSelector((state: any) => state?.theme?.theming);
	const source = useSelector((state: any) => state?.app?.data?.source);

	React.useEffect(() => {
		if (userData?.id) {
			handleFetchRole(IdentityUserService.getIdentityUser1(userData.id)).then((res: any) => {
				let per = res?.permissions?.split(', ') || '';

				setPermissions(per);
			});
		}
	}, [userData]);

	const addPermission = async (values: any, setSubmitting: any) => {
		const FinalPay = permissionList
			.filter((item: any) => values.permissions.includes(item.name))
			.map((data: any) => data.value);
		const roleNames: any = FinalPay;

		try {
			const res: any = await handlePostRole(
				IdentityUserService.putIdentityUser(userData.id, roleNames)
			);
			if (res) {
				Toast('success', 'Permission Added Successfully');
				setSubmitting(false);
				onClose();
				fetchUsers();
			}
		} catch (e) {
			Toast('error', 'Failed to Permission role ');
			setSubmitting(false);
		}
	};

	const permissionList = permissionLists.filter((item) =>
		source === 'nabil' ? item.value !== 'Menus' : item
	);

	return (
		<Modal show={isOpen} onHide={onClose}>
			<Modal.Header className="application__heading" closeButton>
				<Modal.Title>Assign Permission</Modal.Title>
			</Modal.Header>
			<Formik
				initialValues={{ name: userData?.name || '', permissions: (permissions as any) || [] }}
				onSubmit={(values, { setSubmitting }) => {
					addPermission(values, setSubmitting);
				}}
				enableReinitialize>
				{({ values, handleBlur, handleChange, isSubmitting }) => (
					<Form>
						<Modal.Body>
							<div>
								<div className="mb-2">
									<TextField
										placeholder=""
										type="text"
										label="Name"
										name="name"
										value={values.name}
										onChange={handleChange}
										onBlur={handleBlur}
										readOnly
									/>
								</div>

								<div className="row">
									<label htmlFor="">Permission</label>
									<div>
										<FieldArray
											name="permissions"
											render={(arrayHelpers) => (
												<div className="row">
													{permissionList.map((tag) => (
														<div key={tag.value} className="col-4">
															<CustomCheckMarkContainer
																theme={themes}
																className="d-flex mb-2 gap-2">
																<input
																	name="permissions"
																	type="checkbox"
																	id="cb"
																	checked={values?.permissions?.includes(tag.name) ? true : false}
																	onChange={(e) => {
																		if (e.target.checked) {
																			arrayHelpers.push(tag.name);
																		} else {
																			const idx = values.permissions.indexOf(tag.name);
																			arrayHelpers.remove(idx);
																		}
																	}}
																/>
																<span>{tag.value}</span>
															</CustomCheckMarkContainer>
														</div>
													))}
												</div>
											)}
										/>
									</div>
								</div>
							</div>
						</Modal.Body>
						<Modal.Footer>
							<Button type="submit" loading={isSubmitting}>
								Save
							</Button>
							<Button onClick={onClose} type="button" outlined>
								Close
							</Button>
						</Modal.Footer>
					</Form>
				)}
			</Formik>
		</Modal>
	);
}
