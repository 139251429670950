import { Form, Formik } from 'formik';
import { Modal } from 'react-bootstrap';
import * as Yup from 'yup';

import { TextField } from 'components';
import Button from 'components/Button/Button';
import useAdminCoreUtilityApi from 'hooks/useAdminCoreUtilityApi';
import { IdentityRoleService } from 'services/utility-core-services';
import Toast from 'utils/toast';
import { fetchRoles } from 'redux/Action/app.action';

type Props = {
	setRefresh: any;
	roleData: any;
	isOpen: boolean;
	onClose: () => void;
};

const FORM_VALIDATION = Yup.object().shape({
	name: Yup.string().required('This field is required'),
});
export default function AddEditRole({ setRefresh, roleData, isOpen, onClose }: Props) {
	const { handleRequest, isLoading } = useAdminCoreUtilityApi();

	const postRoleRequest = async (val: any, setSubmitting: any) => {
		setSubmitting(true);

		const payload: any = {
			role: {
				name: val.name,
				isDefault: true,
				isPublic: true,
			},
			permissions: ['Backend_Users'],
		};
		IdentityRoleService.postIdentityRole(payload)
			.then()
			.then((res) => {
				Toast('success', 'Role Added Successfully');
				setSubmitting(false);
				onClose();
				fetchRoles();
			})
			.catch((err) => {
				Toast('error', err?.body?.error?.message ?? 'Failed to add role');
				setSubmitting(false);
			});
	};

	const permissionList = [
		{
			id: 1,
			value: 'Users',
			name: 'BackEnd_Users',
		},
		{
			id: 2,
			value: 'Roles',
			name: 'BackEnd_Roles',
		},
		{
			id: 3,
			value: 'RequestLists',
			name: 'BackEnd_RequestLists',
		},
		{
			id: 4,
			value: 'AuditLogs',
			name: 'BackEnd_AuditLogs',
		},
		{
			id: 5,
			value: 'VisaDirect',
			name: 'BackEnd_VisaDirect',
		},

		{
			id: 6,
			value: 'Settings',
			name: 'BackEnd_Settings',
		},
		{
			id: 7,
			value: 'Menu Management',
			name: 'BackEnd_Settings',
		},
	];

	const handleEdit = async (val: any, setSubmitting: any) => {
		setSubmitting(true);
		const { concurrencyStamp, id } = roleData;
		// const FinalPay = permissionList
		// 	.filter((item: any) => val.permissions.includes(item.name))
		// 	.map((data: any) => data.value);
		const payload: any = {
			identityRoleUpdateDto: {
				name: val?.name,
				isDefault: true,
				isPublic: true,
				concurrencyStamp: concurrencyStamp,
			},
			roleId: id,
			roleName: val?.name,
			permissionsGranted: ['Backend_Users'],
		};
		await handleRequest(IdentityRoleService.putIdentityRole(id, payload))
			.then((res: any) => {
				setRefresh(Math.random());
				Toast('success', 'Role Edited Successfully');
				setSubmitting(false);
				onClose();
			})
			.catch((err: any) => {
				Toast('error', 'Failed to edit role ');
				setSubmitting(false);
			});
	};

	return (
		<Modal show={isOpen} onHide={onClose}>
			<Modal.Header className="application__heading">
				<Modal.Title>{!roleData?.name ? 'Add' : 'Edit'} Application Role</Modal.Title>
			</Modal.Header>
			<Formik
				initialValues={{ name: roleData?.name || '' }}
				onSubmit={(values, { setSubmitting }) => {
					// eslint-disable-next-line no-unused-expressions
					roleData?.name
						? handleEdit(values, setSubmitting)
						: postRoleRequest(values, setSubmitting);
				}}
				validationSchema={FORM_VALIDATION}
				validateOnMount={true}
				enableReinitialize={true}>
				{({ values, handleBlur, handleChange, setFieldValue, isSubmitting }) => {
					return (
						<Form>
							<Modal.Body>
								<div className="">
									<div className="mb-2">
										<TextField
											placeholder=""
											type="text"
											label=" Name"
											name="name"
											value={values.name}
											onChange={handleChange}
											onBlur={handleBlur}
										/>
									</div>
									{/* 
									<div className="row">
										<label htmlFor="">Permission</label>
										{permissionList.map((item) => {
											return (
												<div
													className="col-6"
													key={item.id}
													style={{
														display: 'flex',
														width: '150px',
													}}>
													<input
														// onChange={toggleRoleHandler(item)}
														// checked={roleInfo[item.id]}
														style={{ margin: '10px' }}
														type="checkbox"
														id="cb"
													/>
													<div className="mt-1 ">{item.value}</div>
												</div>
											);
										})}
									</div> */}
								</div>
							</Modal.Body>
							<Modal.Footer>
								<Button type="submit" loading={isSubmitting}>
									Save
								</Button>
								<Button onClick={onClose} type="button" outlined>
									Close
								</Button>
							</Modal.Footer>
						</Form>
					);
				}}
			</Formik>
		</Modal>
	);
}
