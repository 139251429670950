import { saveAs } from 'file-saver';
import dayjs from 'dayjs';
import React, { memo } from 'react';
import DataTable from 'react-data-table-component';

import { Header } from 'components';
import useAdminCoreUtilityApi from 'hooks/useAdminCoreUtilityApi';
import { AuditLogService } from 'services/utility-core-services';
import { textUpperCase } from 'utils/textUpperCase';
import FilterHeader from 'components/Table/FilterHeader';
import FilterBar from 'components/Table/FilterBar';
import { axiosConfig } from 'utils/interceptors';
import Spinner from 'components/Spinner';

const AuditLog: React.FC = () => {
	const [auditLogsList, setAuditLogsList] = React.useState<any>({});
	const [totalRows, setTotalRows] = React.useState<number>(0);
	const [pageSkip, setPageSkip] = React.useState<number>(0);
	const [pageLimit, setPageLimit] = React.useState<number>(10);
	const [isExportLoading, setExportLoading] = React.useState(false);

	const { handleRequest, isLoading } = useAdminCoreUtilityApi();

	var curr = new Date();
	curr.setDate(curr.getDate());
	var todayDate = curr.toISOString().substr(0, 10);
	const [filters, setFilters] = React.useState<any>({
		UserName: '',
		ExecutionDate: '',
		Activity: '',
		Module: '',
	});
	const columns: any = [
		{
			name: 'S.N.',
			maxWidth: '80px',
			minWidth: '80px',
			selector: (row: any) => row?.sn,
		},
		{
			name: 'User Name',
			selector: (row: any) => textUpperCase(row?.userName),
		},
		{
			name: 'Module',
			selector: (row: any) => row.module,
		},
		{
			name: 'Activity',
			selector: (row: any) => row.activity,
		},

		{
			name: 'Execution Date',
			selector: (row: any) => dayjs(row.executionDate).format('MM/DD/YYYY hh:mm:ss'),
		},
	];
	const fetctAuditList = async () => {
		const { ExecutionDate, UserName, Module, Activity } = filters;
		let take = pageLimit;
		let skip = pageSkip;
		const response: any = await handleRequest(
			AuditLogService.getAuditLog1(skip, take, ExecutionDate, UserName, Module, Activity)
		);
		const calculatedData = response?.items?.map((row: any, index: any) => {
			return {
				...row,
				sn: index + 1 + pageSkip, // Calculate the serial number
			};
		});
		setAuditLogsList(calculatedData);
		setTotalRows(response?.totalCount);
	};

	const handleOnChangePage = (page: number) => {
		page -= 1;
		setPageSkip(page * pageLimit);
	};

	// Table Row Page change
	const handlePerRowsChange = (newPage: any) => setPageLimit(newPage);
	const paginationRowsPerPage = [10, 25, 50, 100];

	const handleExportToExcel = async () => {
		setExportLoading(true);
		const { ExecutionDate, UserName, Module, Activity } = filters;
		const { data } = await axiosConfig.get(
			`/admin-api/api/app/audit-log/auditlog-to-excel?UserName=${UserName}&Module=${Module}&Activity=${Activity}&ExecutionDate=${ExecutionDate}`,
			{
				responseType: 'arraybuffer',
			}
		);
		let blob = new Blob([data], {
			type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
		});
		saveAs(blob, `AuditLog-${todayDate}-${new Date().toLocaleTimeString()}.xlsx`);
		setExportLoading(false);
	};

	React.useEffect(() => {
		fetctAuditList();
	}, [pageLimit, pageSkip]);

	const filtersList = [
		{
			label: 'Execution Date',
			defaultOption: filters.ExecutionDate || '',
			inputType: 'date',
			selectedOption: filters.ExecutionDate,
			onChange: (val: string) =>
				setFilters({
					...filters,
					ExecutionDate: val,
				}),
		},

		{
			label: 'Module',
			defaultOption: filters.Module || 'All',
			inputType: 'input',
			selectedOption: filters.Module,
			onChange: (val: string) =>
				setFilters({
					...filters,
					Module: val,
				}),
		},
		{
			label: 'User Name',
			defaultOption: filters.UserName || 'All',
			inputType: 'input',
			selectedOption: filters.UserName,
			onChange: (val: string) =>
				setFilters({
					...filters,
					UserName: val,
				}),
		},
		{
			label: 'Activity',
			defaultOption: filters.Activity || 'All',
			inputType: 'input',
			selectedOption: filters.Activity,
			onChange: (val: string) =>
				setFilters({
					...filters,
					Activity: val,
				}),
		},
		{
			label: '',
			defaultOption: 'All',
			inputType: 'button',
			onFetch: fetctAuditList,
		},
	];

	return (
		<>
			<Header
				title="Audit Log"
				// extraButton={
				// 	<FilterHeader
				// 		handleExportToExcel={handleExportToExcel}
				// 		isExportLoading={isExportLoading}
				// 	/>
				// }
			/>
			<div className="table__filter ">
				<FilterBar hideSearchBox filters={filtersList} hideActionButton hideReportActionButton />
			</div>

			<div className="table__container">
				<DataTable
					dense
					columns={columns}
					data={auditLogsList}
					striped
					pagination
					paginationServer
					paginationTotalRows={totalRows}
					onChangePage={handleOnChangePage}
					onChangeRowsPerPage={handlePerRowsChange}
					progressPending={isLoading}
					paginationRowsPerPageOptions={paginationRowsPerPage}
					progressComponent={<Spinner />}
				/>
			</div>
		</>
	);
};

export default memo(AuditLog);
