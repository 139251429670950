import { authConstant, LogoutConstant, userConstant } from '../constant/auth.constant';

const initState = {
	token: null,
	authenticate: false,
	authenticating: false,
	error: null,
	userData: null,
	userProfile: false,
	isLoading: false,
	isUserLoading: false,
};

export const authReducer = (state = initState, { type, payload }: any) => {
	switch (type) {
		case authConstant.LOGIN_REQUSET:
			state = {
				...state,
				error: null,
				isLoading: true,
			};
			break;
		case authConstant.LOGIN_SUCCESS:
			state = {
				...state,
				token: payload,
				authenticate: true,
				isLoading: false,
			};
			break;
		case authConstant.LOGIN_FAILED:
			state = {
				...state,
				authenticate: false,
				error: payload,
				isLoading: false,
			};
			break;
		case LogoutConstant:
			state = {
				...initState,
				userProfile: false,
				userData: null,
				token: null,
				authenticate: false,
			};
			break;
		case userConstant.USER_REQUSET:
			state = {
				...state,
				isUserLoading: true,
			};
			break;
		case userConstant.USER_SUCCESS:
			state = {
				...state,
				isUserLoading: false,
				userData: payload,
			};
			break;
		case userConstant.USER_FAILED:
			state = {
				...state,
				authenticate: false,
				isUserLoading: false,
				error: payload,
			};
			break;
	}
	return state;
};
